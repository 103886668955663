import React, { FC } from 'react';

// Atoms:
import { ATableRow } from 'atoms';

// ===================================================================
interface Props {
  count: Number;
  pose: 'exit' | 'enter';
  color?: 'white' | 'black';
}

// ===================================================================
export const TableSpacer: FC<Props> = ({ count, pose }) => {
  let items = new Array(count);
  for (let i = 0; i < count; i++) items.push(i);

  return (
    <ATableRow pose={pose} isSpacer>
      {items.map((index: any) => (
        <td key={'Spacer-' + index} />
      ))}
    </ATableRow>
  );
};
