import React, { FC } from 'react';

// Atoms
import { Icon } from 'atoms';
import { IconType } from 'atoms/Icon';

interface Props {
  disabled: boolean;
  disabledAdd?: boolean;
  icon: IconType;
  text: string;
  onClick: () => void;
  iconOnClick?: () => void;
  style?: React.CSSProperties;
}

export const MenuTile: FC<Props> = ({ disabled, disabledAdd = false, icon, iconOnClick, onClick, style, text }) => {
  return (
    <div
      className={disabled ? 'Menu-Tile-Disabled' : 'Menu-Tile'}
      onClick={() => {
        if (!disabled) onClick();
      }}
      style={style}
    >
      <Icon color="medium" size="40px" type={icon} />
      <div className="Menu-Tile-Content">
        {text}
        <button
          className={`Menu-Tile-Content-Add${disabled || disabledAdd ? '-disabled' : ''}`}
          onClick={(event: any) => {
            if (iconOnClick) {
              event.stopPropagation();
              if (!disabled && !disabledAdd) {
                iconOnClick();
              }
            }
          }}
        >
          <Icon size="24px" type={'plus'} style={{ cursor: 'pointer' }} />
        </button>
      </div>
    </div>
  );
};
