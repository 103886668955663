import { useContext } from 'react';
import { EditorContext, LangContext } from 'context';
import FreeItem from '../../../../configuration/FreeItem';
import { Checkbox } from 'atoms';
import Localizations from 'languages';
import { useSpacecombiSubtypes } from '../../../Menus/SpaceCombi/hooks/useSpacecombiSubtypes';

export const SpaceCombiOptions = () => {
  const { selection } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const { canHaveFlexiCombiAir, canHaveFlexiCombiDoor, canHaveMagicHood, toggleSelection, usedDevices } = useSpacecombiSubtypes();

  return (
    <div className="mb-1">
      {canHaveFlexiCombiAir && (
        <Checkbox
          boxSize="1rem"
          centered={false}
          id={`flexiCombiAir-checkbox`}
          label={Localizations['flexiCombiAir'][lang]}
          labelFontSize=".85rem"
          labelColor="black"
          status={usedDevices.flexiCombiAir}
          setStatus={() => {
            toggleSelection('flexiCombiAir');
          }}
        />
      )}
      {canHaveMagicHood && (
        <div className="mt-1">
          <Checkbox
            boxSize="1rem"
            centered={false}
            id={`magicHood-checkbox-`}
            label={Localizations['magicHood'][lang]}
            labelFontSize=".85rem"
            labelColor="black"
            status={usedDevices.magicHood}
            setStatus={() => {
              toggleSelection('magicHood');
            }}
          />
        </div>
      )}
      {canHaveFlexiCombiDoor && (
        <div className="mt-1">
          <Checkbox
            boxSize="1rem"
            centered={false}
            id={`flexiCombiDoor-checkbox`}
            label={Localizations['flexiCombiDoor'][lang]}
            labelFontSize=".85rem"
            labelColor="black"
            status={usedDevices.flexiCombiDoor}
            setStatus={() => {
              toggleSelection('flexiCombiDoor');
            }}
          />
        </div>
      )}
    </div>
  );
};
