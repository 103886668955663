import React, { CSSProperties, useEffect, useState } from 'react';
import './style.scss';

type Props = {
  className?: string;
  left?: string;
  style?: CSSProperties;
  isVisible?: boolean;
};

export const AEditorMenuItem: React.FC<Props> = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const [className, setClassName] = useState('Editor-Menu-Item');

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      setClassName('Editor-Menu-Item-Mounted');
    }
  }, [mounted]);
  return (
    <div className="Editor-Menu-Wrapper">
      <div className={className}>{children}</div>
    </div>
  );
};
