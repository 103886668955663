import { FC, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';

// Style:
import './style.scss';

// Context:
import { AuthContext, LangContext } from 'context';

// Atoms:
import { Button, Input, Logo } from 'atoms';

// Components:
import { LoadingSpinner } from 'components';

// Utils:
import { post } from 'utils/AJAX';

// Languages:
import Localizations from 'languages';

import { ConfirmPasswordDialog } from './ConfirmPasswordDialog';
import { ResetPasswordDialog } from './ResetPasswordDialog';
import { useLoginDialog } from 'page/Login/useLoginDialog';
import { RegisterDialog } from 'page/Login/RegisterDialog';

// Consts:
const DURATION = 1000;

// ===================================================================
const Login: FC = () => {
  const { handleLog, setUserEmail } = useContext(AuthContext);
  const { handleTogglePasswordDialog, handleToggleResetDialog, handleToggleRegisterDialog, showPasswordDialog, showResetDialog, showRegisterDialog } =
    useLoginDialog();
  const { lang } = useContext(LangContext);

  const [loginError, setLoginError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const [user, setUser] = useState<string>('');

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      data: { username: user, password }
    });

    if (data) {
      if (user) {
        window.localStorage.setItem('user_email_configurator', user);
      }
      if (data.key) window.localStorage.setItem('jwt', data.key);

      setTimeout(() => {
        setLoading(false);
        handleLog(data.loggedIn);
        setUserEmail(user);
      }, DURATION);
    }
    if (error) {
      setTimeout(() => {
        setLoading(false);
        setLoginError(true);
      }, DURATION);
    }
  };

  return (
    <>
      <div className="LoginPage">
        <div className="LoginPage-Logo">
          <Logo />
        </div>

        <div className="LoginPage-InputArea">
          <span className="LoginPage-InputArea-Headline">{Localizations['login'][lang]}</span>

          <form
            className="LoginPage-Form"
            onSubmit={e => {
              handleSubmit(e);
            }}
          >
            <div className="LoginPage-InputWrapper">
              <Input
                autoComplete="email"
                // @ts-ignore
                onChange={event => setUser(event.target.value)}
                placeholder="NAME"
                type="email"
                value={user}
              />
              <Input
                autoComplete="current-password"
                className="LoginPage-InputField"
                //@ts-ignore
                onChange={event => setPassword(event.target.value)}
                placeholder={Localizations['password'][lang].toUpperCase()}
                type="password"
                value={password}
                margin="0 0 0.5rem"
              />
              <div className="LoginPage-Suboptions">
                {/* <p className="LoginPage-Text" onClick={handleToggleRegisterDialog}>
                  {Localizations['register'][lang]}
                </p> */}
                <p className="LoginPage-Text" onClick={handleTogglePasswordDialog}>
                  {Localizations['passwordforgotten'][lang]}
                </p>
              </div>
              <div
                className="text-sm bold flex justify-center align-center w-100"
                style={{
                  color: 'red',
                  height: '30px'
                }}
              >
                {loginError && `${Localizations['loginError'][lang]}.`}
              </div>
            </div>
            <Button btnType="third" padding=".6rem" maxWidth={999}>
              {loading ? <LoadingSpinner size="1rem" borderWidth={2} /> : Localizations['login'][lang].toUpperCase()}
            </Button>
          </form>
        </div>
      </div>
      {showPasswordDialog && <ResetPasswordDialog setShowPasswordDialog={handleTogglePasswordDialog} />}
      {showResetDialog && <ConfirmPasswordDialog setShowPasswordDialog={handleToggleResetDialog} />}
      {showRegisterDialog && <RegisterDialog toggleDialog={handleToggleRegisterDialog} />}
    </>
  );
};

export default withRouter(Login);
