import React, { FC, useContext, useState } from 'react';

import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Icon } from 'atoms';

// Context:
import { EditorContext, LangContext } from 'context';

// Components:
import { LoadingSpinner } from 'components';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';
import { colors } from 'styles/theme';

// ===================================================================
interface Props {}

// ===================================================================
export const SavingErrorHint: FC<Props> = () => {
  const { setSavingError } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [loading] = useState<boolean>(false);

  /* Methods */
  const close = () => {
    setSavingError(false);
  };

  /* Render */
  return (
    <Portal>
      {/* Background */}
      <ASimple key="SavingError-Background" className="SavingError-Background" style={{ height: window.innerHeight }} onClick={close} />

      {/* Content */}
      <ADialog key="SavingError-Content" className="SavingError-Content" topExit="0" topEnter="50%">
        {/* Close Icon */}
        <div className="SavingError-Content-Icon">
          <Icon type="close" size="1.25rem" color="grey" onClick={close} />
        </div>

        {/* Main */}
        <div className="SavingError-Content-Main">
          <Heading level="1" color="dark">
            {Localizations['saveFailed'][lang]}
          </Heading>

          <p style={{ color: colors.medium, flex: 1, fontSize: '.9rem', letterSpacing: '0.3px', lineHeight: 1.5 }}>{Localizations['saveFailedDesc'][lang]}</p>

          <Button btnType="third" maxWidth={400} onClick={close} margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
            {loading ? <LoadingSpinner size="1rem" /> : Localizations['close'][lang]}
          </Button>
        </div>
      </ADialog>
    </Portal>
  );
};
