import React, { FC } from 'react';

// Styles:
import { colors } from 'styles/theme';

// ===================================================================
interface Props {
  type?: 'first' | 'hollow';
  status: boolean;
  toggleStatus: any;
  disabled?: boolean;
  size?: number;
  shadow?: boolean;
  margin?: string;
  inactiveColor?: string;
}

// ===================================================================
export const Switch: FC<Props> = ({
  type = 'first',
  status,
  disabled = false,
  toggleStatus,
  size = 40,
  shadow = false,
  margin = 'auto',
  inactiveColor = null
}) => (
  <div
    onClick={() => {
      if (!disabled) toggleStatus();
    }}
    style={{
      position: 'relative',

      width: size,
      height: size / 3,
      margin: margin,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      background: 'transparent',
      cursor: disabled ? 'not-allowed' : 'pointer'
    }}
  >
    {/* Background */}
    <div
      style={{
        height: size / 3,
        width: size,

        borderRadius: size / 2,
        border:
          '2px solid ' +
          (disabled
            ? colors.disabledGrey
            : type === 'first'
            ? status
              ? colors.lightBlue
              : inactiveColor
              ? inactiveColor
              : colors.disabled
            : status
            ? colors.white
            : inactiveColor
            ? inactiveColor
            : colors.disabledGrey),
        background: disabled ? colors.disabledGrey : type === 'first' ? (status ? colors.lightBlue : colors.disabled) : 'transparent',

        transition: 'all 0.25s ease 0.15s'
      }}
    />

    {/* Circle */}
    <div
      onDrag={() => {
        if (!disabled) toggleStatus();
      }}
      style={{
        position: 'absolute',
        left: status ? size / 2 : 0,
        zIndex: 10,

        height: size / 2,
        width: size / 2,
        margin: 'auto 0',

        borderRadius: size / 2,
        background: disabled
          ? colors.disabledGrey
          : type === 'hollow'
          ? status
            ? colors.white
            : inactiveColor
            ? inactiveColor
            : colors.disabledGrey
          : colors.white,

        boxShadow: shadow ? '0 4px 4px 0 rgba(0,0,0,0.15)' : 'none',

        transition: 'all 0.25s ease'
      }}
    />
  </div>
);
