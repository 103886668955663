import { Logo } from 'atoms';
import { Controls, LoadingErrorHint, LoadingSpinner } from 'components';
import LoaderUtils from 'components/babylon/util/LoaderUtils';
import { EditorContext } from 'context';
import Configuration from 'page/Editor/configuration/Configuration';
import Scene from 'page/Editor/Scene';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { get } from 'utils';
// Style:
import './style.scss';

const View: FC = () => {
  const { setScene, fullscreen, scene, setShowLoadingError, setConfiguration, setOwner, setSavedConfiguration, showLoadingError } = useContext(EditorContext);
  const sceneRef = useRef<Scene>(null);
  const [sceneIsLoading, setSceneIsLoading] = useState(true);
  const [id, setId] = useState('');

  //const [is3D, setIs3D] = useState(false);

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    setId(urlParams.get('id'));
  }, [window.location]);

  useEffect(() => {
    if (sceneIsLoading) {
      const interval = setInterval(() => {
        if (!LoaderUtils.isLoading()) {
          setSceneIsLoading(false);
          clearInterval(interval);
        }
      }, 250);
    }
  }, [sceneIsLoading]);

  // Effects
  useEffect(() => {
    const getConfig = async (id: string) => {
      const { data, error } = await get(`${process.env.REACT_APP_API_URL}/configuration/get/${id}`, {});

      if (data) {
        setSavedConfiguration({
          ...data
        });
        setOwner(false);

        Configuration.importJSON(data.json)
          .then((res: Configuration) => {
            setConfiguration(res);
          })
          .catch(rej => console.log('rejected: ', rej));
      }

      if (error) {
        console.log('Error loading Config:', error);
        setShowLoadingError(true);
      }
    };

    if (id) {
      getConfig(id);
    }
  }, [id]);

  useEffect(() => {
    if (!scene && sceneRef.current) setScene(sceneRef.current);
  }, [scene]);

  return (
    <>
      <div className="View">
        <div className="View-Logo">
          <Logo />
        </div>
        <div className="View-Scene" style={{ width: `100%` }}>
          {sceneIsLoading && (
            <div className="View-Scene-LoadingIndicator">
              <LoadingSpinner color="medium" />
            </div>
          )}
          <Scene viewOnly ref={sceneRef} />
          <Controls scene={sceneRef} fullscreen={fullscreen} />
          {/* {is3D  && <TDControls />} */}
        </div>
      </div>
      {showLoadingError && <LoadingErrorHint />}
    </>
  );
};

export default View;
