import React, { FC } from 'react';
import { Property } from 'csstype';

// Styles:
import './style.scss';

// ===================================================================
interface Props extends React.HTMLProps<HTMLInputElement> {
  width?: string;
  maxWidth?: string;
  margin?: string;
  padding?: string;
  color?: 'light' | 'dark' | 'darkBlue';
  fontSize?: string;
  placeholderSize?: 'small' | 'large';
  fontWeight?: Property.FontWeight;
  textAlign?: Property.TextAlign;
  background?: string;
  fontColor?: Property.Color;
}

// ===================================================================
export const Input: FC<Props> = ({
  width = '100%',
  maxWidth = '100%',
  margin = '0 0 1.5rem',
  padding = '0.4rem 0',
  color = 'light',
  fontSize = '1rem',
  placeholderSize = 'small',
  fontWeight = 'bold',
  background = 'transparent',
  textAlign = 'left',
  onChange,
  value,
  autoComplete,
  type,
  placeholder,
  maxLength,
  max,
  fontColor = 'black'
}) => (
  <input
    className={'input input-' + color + ' input-placeholder-' + placeholderSize}
    autoComplete={autoComplete}
    type={type}
    placeholder={placeholder}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
    }}
    value={value}
    maxLength={maxLength}
    max={max}
    style={{
      width: width,
      maxWidth: maxWidth,

      margin: margin,
      padding: padding,

      color: fontColor,
      fontSize: fontSize,
      fontWeight: fontWeight,
      textAlign: textAlign,

      background: background
    }}
  />
);
