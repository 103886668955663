import React, { FC, Fragment, useState, CSSProperties, useContext, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Atoms:
import { ASimpleWithHeight, ASimple, Button, Heading, Input } from 'atoms';

// Context
import { EditorContext } from 'context';

// Languages:
import Localizations from 'languages';

// Utils
import { checkForUrl, get } from 'utils';
import { useUser } from '../../../context/auth-context';

// Consts:
const style: CSSProperties = {
  width: '100%',
  maxWidth: 400,
  alignItems: 'center',
  textAlign: 'center'
};
const DURATION = 300;
const CHILD_DURATION = 100;

// ===================================================================
interface Props extends RouteComponentProps {
  mode: null | 'code' | 'new';
  setMode: any;
  lang: string;
}

// ===================================================================
const Formular: FC<Props> = ({ mode, history, setMode, lang }) => {
  const [id, setID] = useState<string>('');
  const {
    discountModular,
    discountMasterline,
    discountSpaceCombi,
    setDiscountModular,
    setDiscountMasterline,
    setDiscountSpaceCombi,
    setOwner,
    setShowLoadingError,
    surchargeMasterline,
    setSurchargeMasterline
  } = useContext(EditorContext);
  const { hasPriceAccess, hasSurchargeMasterlineAccess } = useUser();
  const [customer, setCustomer] = useState<string>('');
  const [object, setObject] = useState<string>('');
  const [location, setLocation] = useState('');
  const [configNotFound, setConfigNotFound] = useState(false);

  const getConfiguration = async () => {
    const { data, error } = await get(`${process.env.REACT_APP_API_URL}/configuration/get/${id}`, {});
    if (data) {
      if (data.owner) {
        setOwner(true);
      } else {
        setOwner(false);
      }
      return true;
    }
    if (error) {
      setShowLoadingError(true);
      return false;
    } else return false;
  };

  const toEditor = async () => {
    if (mode === 'code') {
      if (id && id.length > 9) {
        const configExists = await getConfiguration();

        if (configExists) {
          history.push(`/editor?id=${id}`);
        } else {
          setConfigNotFound(true);
        }
      } else {
        setConfigNotFound(true);
      }
    } else {
      if (!checkForUrl(customer) && !checkForUrl(object) && !checkForUrl(location)) {
        history.push(`/editor?customer=${customer}&object=${object}&location=${location}`);
        setOwner(true);
      }
    }
  };

  useEffect(() => {
    if (configNotFound) {
      setConfigNotFound(false);
    }
    if (mode !== 'code' && configNotFound) {
      setConfigNotFound(false);
    }
  }, [id, mode]);

  return (
    <Fragment>
      {mode === 'code' && (
        <ASimpleWithHeight key="Formular-Code" duration={DURATION} style={style}>
          <ASimple duration={CHILD_DURATION} style={style}>
            <Heading color="white" level="2" fontSize="1rem" fontWeight="bold">
              {Localizations['enterCode'][lang].toUpperCase()}
            </Heading>
            <Input
              color="dark"
              maxWidth="400px"
              textAlign="center"
              fontWeight="normal"
              fontSize="1.25rem"
              placeholderSize="large"
              placeholder="Code"
              type="text"
              value={id}
              onChange={(event: any) => setID(event.target.value)}
            />
          </ASimple>
        </ASimpleWithHeight>
      )}

      {mode === 'new' && (
        <ASimpleWithHeight key="Formular-newConfiguration" duration={DURATION} style={style}>
          <ASimple duration={CHILD_DURATION} style={style}>
            <Heading color="white" level="2" fontSize="1rem" fontWeight="bold">
              {Localizations['newConfig'][lang].toUpperCase()}
            </Heading>
            <Input
              color="dark"
              maxWidth="400px"
              textAlign="center"
              fontWeight="normal"
              fontSize="1.25rem"
              placeholderSize="large"
              placeholder={Localizations['customer'][lang]}
              type="text"
              value={customer}
              onChange={(event: any) => {
                setCustomer(event.target.value);
              }}
            />
            <Input
              color="dark"
              maxWidth="400px"
              textAlign="center"
              fontWeight="normal"
              fontSize="1.25rem"
              placeholderSize="large"
              placeholder={Localizations['object'][lang]}
              type="text"
              value={object}
              onChange={(event: any) => {
                setObject(event.target.value);
              }}
            />
            <Input
              color="dark"
              maxWidth="400px"
              textAlign="center"
              fontWeight="normal"
              fontSize="1.25rem"
              placeholderSize="large"
              placeholder={Localizations['location'][lang]}
              type="text"
              value={location}
              onChange={(event: any) => {
                setLocation(event.target.value);
              }}
            />

            {hasPriceAccess && (
              <>
                <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                  <label className="discount-label">
                    <input
                      className="discount-input"
                      min={0}
                      max={100}
                      onBlur={e => {
                        const val = parseFloat(e.target.value);
                        if (val > 100) setDiscountMasterline(100);
                        if (val < 0) setDiscountMasterline(0);
                      }}
                      onChange={e => {
                        setDiscountMasterline(parseFloat(e.target.value));
                      }}
                      step={0.1}
                      type="number"
                      value={discountMasterline}
                    />
                    {Localizations['discountMasterline'][lang]} (%)
                  </label>
                </div>
                <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                  <label className="discount-label">
                    <input
                      className="discount-input"
                      min={0}
                      max={100}
                      onBlur={e => {
                        const val = parseFloat(e.target.value);
                        if (val > 100) setDiscountModular(100);
                        if (val < 0) setDiscountModular(0);
                      }}
                      onChange={e => {
                        setDiscountModular(parseFloat(e.target.value));
                      }}
                      step={0.1}
                      type="number"
                      value={discountModular}
                    />
                    {Localizations['discountModular'][lang]} (%)
                  </label>
                </div>
                <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                  <label className="discount-label">
                    <input
                      className="discount-input"
                      min={0}
                      max={100}
                      onBlur={e => {
                        const val = parseFloat(e.target.value);
                        if (val > 100) setDiscountSpaceCombi(100);
                        if (val < 0) setDiscountSpaceCombi(0);
                      }}
                      onChange={e => {
                        setDiscountSpaceCombi(parseFloat(e.target.value));
                      }}
                      step={0.1}
                      type="number"
                      value={discountSpaceCombi}
                    />
                    {Localizations['discountCombi'][lang]} (%)
                  </label>
                </div>
              </>
            )}
            {hasSurchargeMasterlineAccess && (
              <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                <label className="discount-label">
                  <input
                    className="discount-input"
                    min={0}
                    onBlur={e => {
                      const val = parseFloat(e.target.value);
                      if (val < 0) setSurchargeMasterline(0);
                    }}
                    onChange={e => {
                      setSurchargeMasterline(parseFloat(e.target.value));
                    }}
                    step={0.1}
                    type="number"
                    value={surchargeMasterline}
                  />
                  {Localizations['surcharge'][lang]} {Localizations['masterline'][lang]} (%)
                </label>
              </div>
            )}
          </ASimple>
        </ASimpleWithHeight>
      )}

      <div className="text-center" style={{ width: '300px' }}>
        {configNotFound && (
          <span>{id.length > 0 ? `${Localizations['configuration'][lang]} "${id}" ${Localizations['notFound'][lang]}.` : Localizations['noCode'][lang]}</span>
        )}

        <Button
          disabled={mode === 'new' && (!customer || !object || !location)}
          margin={`${id != null ? '2' : '4'}rem 0 0.5rem`}
          onClick={() => {
            if (mode == null) setMode('code');
            else toEditor();
          }}
          padding=".75rem"
          style={{ flexShrink: 0 }}
        >
          {mode ? (mode === 'code' ? Localizations['open'][lang] : Localizations['start'][lang]) : Localizations['enterCode'][lang]}
        </Button>

        <Button btnType={mode == null ? 'first' : 'hollow'} onClick={() => setMode(mode != null ? null : 'new')} padding=".75rem" style={{ flexShrink: 0 }}>
          {mode == null ? Localizations['newConfig'][lang] : Localizations['cancel'][lang]}
        </Button>
      </div>
    </Fragment>
  );
};

export default withRouter(Formular);
