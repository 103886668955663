export const Static = [
  {
    de: 'Die fugenlose, durchgehende Hygiene-Abdeckung der MKN MasterLine ist aus 3 mm CrNi-Stahl (1.4301) gefertigt. Alle Zubereitungsgeräte sind langlebig, fettdicht eingeschweißt.',
    en: 'The seamless, continuous hygienic top plate of the MKN MasterLine is made of 3 mm s/s (1.4301). All functional appliances are seamlessly welded into the top plate to protect against grease leaks.',
    cz: 'Průběžný hygienický kryt MKN MasterLine bez spár je vyroben z 3 mm CrNi oceli (1.4301). Veškeré kuchyňské náčiní je odolné a zabalené ve smršťovací fólii, aby bylo odolné proti mastnotě.',
    fr: 'Le dessus unique MKN MasterLine est fabriquée en acier inoxydable de 3 mm (1.4301). Tous les appareils fonctionnels sont soudés dans la plaque supérieure afin de protéger contre les fuites de graisse.'
  },
  {
    de: 'Alle abgekanteten Deckplattenüberstände sind mit einer Abtropfkante versehen.',
    en: 'All top plate overstands come with a drip edge.',
    cz: 'Všechny zkosené výstupky krycí desky jsou opatřeny odkapávací hranou.',
    fr: "Toutes les bords du dessus unique sont conçus avec un bord d'égouttement."
  },
  {
    de: 'Die Bedien- und Regelelemente, elektrische Verdrahtung usw. sind in schwerer, großküchengeeigneter Qualität ausgeführt.',
    en: 'The operating and control elements, electrical wiring, etc. are made of heavy duty quality for commercial kitchens.',
    cz: 'Ovládací a řídicí prvky, elektrické rozvody atd. jsou kvalitní a odolné, vhodné pro komerční kuchyně.',
    fr: 'Les éléments de commande et de contrôle, le câblage électrique, etc. sont fabriqués dans une qualité très résistante pour les cuisines commerciales.'
  },
  {
    de: 'Jedes Zubereitungsgerät mit separatem Elektro- bzw. Gasanschluss und ggf. separaten Sanitäranschlüssen.',
    en: 'Each functional appliance with separate electric or gas connection and, if necessary, separate sanitary connections.',
    cz: 'Každá kuchyňská náčiní má samostatnou elektrickou nebo plynovou přípojku a v případě potřeby i samostatné sanitární přípojky.',
    fr: "Chaque appareil fonctionnel dispose d'un raccordement électrique ou gaz séparés et, si nécessaire, de raccordements sanitaires séparés."
  },
  {
    de: 'Die Konstruktion und die Lieferung erfolgen einteilig. Der Oberbau und der Unterbau sind durchgehend konstruiert und somit horizontal nicht teilbar.',
    en: 'The cook suite will be delivered in one piece. The upper structure and the substructure cannot be divided horizontally.',
    cz: 'Stavba a dodávka probíhají v jednom kuse. Nosná konstrukce a spodní stavba jsou konstruovány spojitě, a proto je nelze horizontálně rozdělit.',
    fr: "L'ensemble sera livré en une seule pièce. La structure supérieure et la sous-structure ne peuvent pas être divisées horizontalement."
  },
  {
    de: 'Die MKN MasterLine ist zur Aufstellung auf einen bauseitigen Sockel vorbereitet.',
    en: 'The MKN MasterLine is prepared for installation on a concrete plinth with tiles on the site provided by the customer.',
    cz: 'MKN MasterLine je připraven k instalaci na podstavec dodaný zákazníkem.',
    fr: 'Le MKN MasterLine est préparé pour être installé sur un socle en béton fourni par le client.'
  }
];

export const Break = {
  de: 'Erdgas, 20 mbar',
  en: 'Natural gas, 20 mbar',
  cz: 'Zemní plyn, 20 mbar',
  fr: 'Gaz naturel 20 mbar'
};
