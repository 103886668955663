import { BordSettings } from 'page/Editor/Menu/Menus/Masterline/Partials';
import { useContext, useEffect } from 'react';
import { EditorContext } from 'context';
import { Device } from 'types';

export const useHandleShelfSettings = (
  shelfInitialized: boolean,
  boardSettings: BordSettings,
  isMediaBoard: boolean,
  setIsMediaBoard: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFreeBoard: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>,
  shelfMixingFaucetDevice: Device,
  mixingFaucetDevice: Device,
  usedMixingFaucet: Device,
  updateTimeout: number
) => {
  const { selectedMasterline } = useContext(EditorContext);

  useEffect(() => {
    if (shelfInitialized) {
      // Update Board
      const board = selectedMasterline.getBoard();

      setIsMediaBoard(!!(board?.getDeviceObject()?.style === 'Tray'));
      setIsFreeBoard(board && !board.getDeviceObject());

      if (board) {
        if (board.getMarginLeft() !== boardSettings.marginLeft) {
          board.setMarginLeft(boardSettings.marginLeft);
        }
        if (board.getMarginRight() !== boardSettings.marginRight) {
          board.setMarginRight(boardSettings.marginRight);
        }
        if (!!board.isSalamanderLeft() !== boardSettings.salamanderLeft) {
          board.setSalamanderLeft(boardSettings.salamanderLeft);
          if (boardSettings.salamanderLeft) {
            board.setSalamanderRight(false);
          }
        }
        if (!!board.isSalamanderRight() !== boardSettings.salamanderRight) {
          board.setSalamanderRight(boardSettings.salamanderRight);
          if (boardSettings.salamanderRight) {
            board.setSalamanderLeft(false);
          }
        }

        const ld = board.getLeft(usedMixingFaucet);
        const leftDevice = Array.isArray(ld) ? ld[0]?.getDeviceObject() : ld?.getDeviceObject();

        if ((!leftDevice && boardSettings.mixingFaucetLeft.length > 0) || (leftDevice && boardSettings.mixingFaucetLeft.length < 1)) {
          if (boardSettings.mixingFaucetLeft.length > 0 && board?.getDeviceObject()?.style !== 'Tray600') {
            const removing = !isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice;
            const adding = usedMixingFaucet;

            if (removing.id !== adding.id) {
              board.removeLeft(!isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice);
              board.addLeft(usedMixingFaucet);
            }
          } else {
            if (ld) {
              board.removeLeft(usedMixingFaucet);
            }
          }
        }

        const rd = board.getRight(usedMixingFaucet);
        const rightDevice = Array.isArray(rd) ? rd[0]?.getDeviceObject() : rd?.getDeviceObject();

        if ((!rightDevice && boardSettings.mixingFaucetRight.length > 0) || (rightDevice && boardSettings.mixingFaucetRight.length < 1)) {
          if (boardSettings.mixingFaucetRight.length > 0 && board?.getDeviceObject()?.style !== 'Tray600') {
            board.removeRight(!isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice);
            board.addRight(usedMixingFaucet);
          } else {
            if (rd) {
              board.removeRight(usedMixingFaucet);
            }
          }
        }

        if (board.getRight() && board.getRight()[0] && board.getRight()[0].margin !== boardSettings.marginMixingFaucetRight) {
          board.getRight()[0].setMargin(boardSettings.marginMixingFaucetRight);
          board.rerender();
        }

        if (board.getLeft() && board.getLeft()[0] && board.getLeft()[0].margin !== boardSettings.marginMixingFaucetLeft) {
          board.getLeft()[0].setMargin(boardSettings.marginMixingFaucetLeft);
          board.rerender();
        }
      }
      setTimeout(() => {
        setUpdating(false);
      }, updateTimeout);
    }
  }, [
    shelfInitialized,
    boardSettings,
    isMediaBoard,
    setIsMediaBoard,
    setIsFreeBoard,
    setUpdating,
    shelfMixingFaucetDevice,
    mixingFaucetDevice,
    usedMixingFaucet,
    updateTimeout
  ]);
};
