import { EditorContext, LangContext } from 'context';
import { useContext } from 'react';
import Localizations from 'languages';

import styles from './styles.module.scss';

export const MarineMeisterHome: React.FC = () => {
  const { canAdvanceMarineMeister, setMarineMeisterView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  return (
    <div className="flex-col h-100 justify-between">
      <div className={styles.Nav}>
        <MenuItem onClick={() => setMarineMeisterView('structure')} title={`01 ${Localizations['structure'][lang]}`} />
        <MenuItem
          isDisabled={!canAdvanceMarineMeister}
          onClick={() => setMarineMeisterView('functional')}
          title={`02 ${Localizations['functionalDevices'][lang]}`}
        />
        <MenuItem
          isDisabled={!canAdvanceMarineMeister}
          onClick={() => setMarineMeisterView('upperStructures')}
          title={`03 ${Localizations['upperStructures'][lang]}`}
        />
        <MenuItem isDisabled={!canAdvanceMarineMeister} onClick={() => setMarineMeisterView('global')} title={`04 ${Localizations['globalSettings'][lang]}`} />
      </div>
    </div>
  );
};

type MenuItemProps = { isDisabled?: boolean; onClick: () => void; title: string };

const MenuItem: React.FC<MenuItemProps> = ({ isDisabled = false, onClick, title }) => {
  return (
    <div className={styles.NavItem}>
      <button disabled={isDisabled} onClick={onClick}>
        {title}
      </button>
    </div>
  );
};
