import * as BABYLON from 'babylonjs';
import Scene from 'page/Editor/Scene';
import LineUtils from './LineUtils';

export enum Orientation {
  Top,
  Bottom,
  Left,
  Right
}

export type DrawLabelOptions = {
  depth: number;
  lineMaterial: BABYLON.StandardMaterial;
};

export default class LabelUtils {
  private static labelTextureScale = 0.5;

  private static offsetY = 0.2;

  private static materialCache = new Map<string, BABYLON.Material>();
  private static textureCache = new Map<string, BABYLON.DynamicTexture>();

  public static labelWidth = 40;
  public static lineMaterial: BABYLON.StandardMaterial;
  public static lineMaterialBlue: BABYLON.StandardMaterial;

  public static scissorMaterial: BABYLON.StandardMaterial;

  public static initialize(): void {
    LabelUtils.materialCache.clear();
    LabelUtils.textureCache.clear();

    LabelUtils.lineMaterial = new BABYLON.StandardMaterial('label:line', Scene.CURRENT_SCENE);
    LabelUtils.lineMaterial.diffuseColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterial.specularColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterial.emissiveColor = new BABYLON.Color3(0.4, 0.4, 0.4);
    LabelUtils.lineMaterial.disableLighting = true;
    // LabelUtils.lineMaterial.wireframe = true;
    LabelUtils.lineMaterial.freeze();

    LabelUtils.lineMaterialBlue = new BABYLON.StandardMaterial('label:line:blue', Scene.CURRENT_SCENE);
    LabelUtils.lineMaterialBlue.diffuseColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterialBlue.specularColor = BABYLON.Color3.Black();
    LabelUtils.lineMaterialBlue.emissiveColor = new BABYLON.Color3(37 / 255, 177 / 255, 255 / 255);
    LabelUtils.lineMaterialBlue.disableLighting = true;
    LabelUtils.lineMaterialBlue.freeze();

    LabelUtils.scissorMaterial = new BABYLON.StandardMaterial('_scissor', Scene.CURRENT_SCENE);
    LabelUtils.scissorMaterial.diffuseTexture = new BABYLON.Texture('texture/cut.png', Scene.CURRENT_SCENE);
    LabelUtils.scissorMaterial.diffuseTexture.hasAlpha = true;
    LabelUtils.scissorMaterial.disableLighting = true;
    LabelUtils.scissorMaterial.freeze();
  }

  public static drawText(label: string): BABYLON.DynamicTexture {
    let texture = this.textureCache.get(label);
    if (!texture) {
      texture = new BABYLON.DynamicTexture('label:text:' + label, 256 * LabelUtils.labelTextureScale, Scene.CURRENT_SCENE, false);
      const textY = (256 * LabelUtils.labelTextureScale) / 2 + (65 * LabelUtils.labelTextureScale) / 2 - 65 * LabelUtils.labelTextureScale * 0.15;
      texture.drawText(label, null, textY, 'bold ' + (65 * LabelUtils.labelTextureScale).toFixed(0) + 'px Segoe UI, sans-serif', 'black', 'transparent', true);
    }
    return texture;
  }

  public static drawLabel(
    label: string | BABYLON.Material,
    size: number,
    orientation: Orientation,
    options: DrawLabelOptions = {
      depth: 40,
      lineMaterial: LabelUtils.lineMaterial
    }
  ): BABYLON.TransformNode {
    const container = new BABYLON.TransformNode('label', Scene.CURRENT_SCENE);

    const textPlane = BABYLON.Mesh.CreateGround('text', LabelUtils.labelWidth, LabelUtils.labelWidth, 1, Scene.CURRENT_SCENE);

    if (label instanceof BABYLON.Material) {
      textPlane.material = label;
    } else {
      const labelName = label + '::' + options.lineMaterial.name;
      if (LabelUtils.materialCache.has(labelName)) {
        textPlane.material = LabelUtils.materialCache.get(labelName);
      } else {
        const dynamicTexture = LabelUtils.drawText(label);

        const material = new BABYLON.StandardMaterial('label:text:' + label, Scene.CURRENT_SCENE);
        material.opacityTexture = dynamicTexture;
        // material.diffuseTexture = dynamicTexture;
        // material.diffuseTexture.hasAlpha = true;
        material.diffuseColor = BABYLON.Color3.Black();
        material.specularColor = BABYLON.Color3.Black();
        material.emissiveColor = options.lineMaterial.emissiveColor;
        material.disableLighting = true;
        material.backFaceCulling = false;
        material.freeze();
        LabelUtils.materialCache.set(labelName, material);

        textPlane.material = material;
      }
    }
    textPlane.isPickable = false;

    switch (orientation) {
      case Orientation.Top:
      case Orientation.Bottom:
        {
          const invertZ = orientation === Orientation.Bottom;
          let w = (size - LabelUtils.labelWidth / 2) / 2;
          const depthMultiplier = invertZ ? -1 : 1;
          const lineDepth = options.depth * depthMultiplier;

          if (w <= 0) w = 0;

          textPlane.position = new BABYLON.Vector3(size / 2, LabelUtils.offsetY, lineDepth);
          textPlane.parent = container;

          if (label instanceof BABYLON.Material) {
            // ???
          } else {
            if (label.length > 3) {
              const overflow = label.length - 3;
              w -= overflow * 3;
            }
          }

          if (w <= 0) w = 1;

          const meshes = [];

          const leftLine = LineUtils.drawLine(
            null,
            {
              path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, lineDepth), new BABYLON.Vector3(w, 0, lineDepth)],
              width: 1,
              material: options.lineMaterial
            },
            Scene.CURRENT_SCENE
          );
          leftLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          leftLine.parent = container;
          meshes.push(leftLine);

          const rightLine = LineUtils.drawLine(
            null,
            {
              path: [new BABYLON.Vector3(size - w, 0, lineDepth), new BABYLON.Vector3(size, 0, lineDepth), new BABYLON.Vector3(size, 0, 0)],
              width: 1,
              material: options.lineMaterial
            },
            Scene.CURRENT_SCENE
          );
          rightLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          rightLine.parent = container;
          meshes.push(rightLine);

          const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
          finalMesh.name = 'line';
          finalMesh.parent = container;
        }
        break;
      case Orientation.Left:
      case Orientation.Right:
        {
          const invertX = orientation === Orientation.Left;
          let w = (size - LabelUtils.labelWidth / 2) / 2;
          const widthMultiplier = invertX ? -1 : 1;
          const lineWidth = options.depth * widthMultiplier;

          if (w <= 0) w = 0;

          textPlane.position = new BABYLON.Vector3(lineWidth, LabelUtils.offsetY, size / 2);
          textPlane.parent = container;

          if (w <= 0) w = 1;

          const meshes = [];

          const leftLine = LineUtils.drawLine(
            null,
            {
              path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(lineWidth, 0, 0), new BABYLON.Vector3(lineWidth, 0, w)],
              width: 1,
              material: options.lineMaterial
            },
            Scene.CURRENT_SCENE
          );
          leftLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          leftLine.parent = container;
          meshes.push(leftLine);

          const rightLine = LineUtils.drawLine(
            null,
            {
              path: [new BABYLON.Vector3(lineWidth, 0, size - w), new BABYLON.Vector3(lineWidth, 0, size), new BABYLON.Vector3(0, 0, size)],
              width: 1,
              material: options.lineMaterial
            },
            Scene.CURRENT_SCENE
          );
          rightLine.position = new BABYLON.Vector3(0, LabelUtils.offsetY, 0);
          rightLine.parent = container;
          meshes.push(rightLine);

          const finalMesh = BABYLON.Mesh.MergeMeshes(meshes);
          finalMesh.name = 'line';
          finalMesh.parent = container;
          finalMesh.isPickable = false;
        }
        break;

      default:
        break;
    }

    return container;
  }

  public static drawCutLabel(
    label: string,
    orientation: Orientation,
    options: DrawLabelOptions = {
      depth: 40,
      lineMaterial: LabelUtils.lineMaterial
    }
  ): BABYLON.TransformNode {
    const container = new BABYLON.TransformNode(label, Scene.CURRENT_SCENE);

    switch (orientation) {
      case Orientation.Bottom:
        {
          const line = LineUtils.drawLine(
            null,
            { width: 3, path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, 30)], material: options.lineMaterial },
            Scene.CURRENT_SCENE
          );
          line.position.z = -30;
          line.position.y = 1;
          line.parent = container;

          const triangle = new BABYLON.Mesh('head', Scene.CURRENT_SCENE);
          const positions = [-6, 0, 0, 0, 0, 6, 6, 0, 0];
          const indices = [0, 1, 2];
          const normals = [] as BABYLON.FloatArray;
          const vertex = new BABYLON.VertexData();
          BABYLON.VertexData.ComputeNormals(positions, indices, normals);

          vertex.positions = positions;
          vertex.indices = indices;
          vertex.normals = normals;
          vertex.applyToMesh(triangle);

          triangle.material = LabelUtils.lineMaterial;

          triangle.rotation = new BABYLON.Vector3(0, 0, Math.PI);
          triangle.position.z = 0;
          triangle.position.y = 1;
          triangle.parent = container;

          const scissor = BABYLON.Mesh.CreateGround('scissor', 30, 30, 1, Scene.CURRENT_SCENE);
          scissor.material = LabelUtils.scissorMaterial;

          scissor.rotation = new BABYLON.Vector3(0, 90 * (Math.PI / 180), 0);
          scissor.position.z = -50;
          scissor.position.y = 1;
          scissor.parent = container;
        }
        break;
      case Orientation.Top:
        {
          const line = LineUtils.drawLine(
            null,
            { width: 3, path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, 30)], material: options.lineMaterial },
            Scene.CURRENT_SCENE
          );
          line.position.z = 0;
          line.position.y = 1;
          line.parent = container;

          const triangle = new BABYLON.Mesh('head', Scene.CURRENT_SCENE);
          const positions = [-6, 0, 0, 0, 0, -6, 6, 0, 0];
          const indices = [0, 1, 2];
          const normals = [] as BABYLON.FloatArray;
          const vertex = new BABYLON.VertexData();
          BABYLON.VertexData.ComputeNormals(positions, indices, normals);

          vertex.positions = positions;
          vertex.indices = indices;
          vertex.normals = normals;
          vertex.applyToMesh(triangle);

          triangle.material = LabelUtils.lineMaterial;

          triangle.position.z = 0;
          triangle.position.y = 1;
          triangle.parent = container;

          const scissor = BABYLON.Mesh.CreateGround('scissor', 30, 30, 1, Scene.CURRENT_SCENE);
          scissor.material = LabelUtils.scissorMaterial;

          scissor.rotation = new BABYLON.Vector3(0, 270 * (Math.PI / 180), 0);
          scissor.position.z = 50;
          scissor.position.y = 1;
          scissor.parent = container;
        }
        break;

      case Orientation.Left:
        {
          const line = LineUtils.drawLine(
            null,
            { width: 3, path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(30, 0, 0)], material: options.lineMaterial },
            Scene.CURRENT_SCENE
          );
          line.position.x = -30;
          line.position.y = 1;
          line.parent = container;

          const triangle = new BABYLON.Mesh('head', Scene.CURRENT_SCENE);
          const positions = [0, 0, 6, 6, 0, 0, 0, 0, -6];
          const indices = [0, 1, 2];
          const normals = [] as BABYLON.FloatArray;
          const vertex = new BABYLON.VertexData();
          BABYLON.VertexData.ComputeNormals(positions, indices, normals);

          vertex.positions = positions;
          vertex.indices = indices;
          vertex.normals = normals;
          vertex.applyToMesh(triangle);

          triangle.material = LabelUtils.lineMaterial;

          triangle.rotation = new BABYLON.Vector3(Math.PI, 0, 0);
          triangle.position.x = 0;
          triangle.position.y = 1;
          triangle.parent = container;

          const scissor = BABYLON.Mesh.CreateGround('scissor', 30, 30, 1, Scene.CURRENT_SCENE);
          scissor.material = LabelUtils.scissorMaterial;

          scissor.rotation = new BABYLON.Vector3(0, Math.PI, 0);
          scissor.position.x = -50;
          scissor.position.y = 1;
          scissor.parent = container;
        }
        break;
      case Orientation.Right:
        {
          const line = LineUtils.drawLine(
            null,
            { width: 3, path: [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(30, 0, 0)], material: options.lineMaterial },
            Scene.CURRENT_SCENE
          );
          line.position.x = 0;
          line.position.y = 1;
          line.parent = container;

          const triangle = new BABYLON.Mesh('head', Scene.CURRENT_SCENE);
          const positions = [0, 0, 6, -6, 0, 0, 0, 0, -6];
          const indices = [0, 1, 2];
          const normals = [] as BABYLON.FloatArray;
          const vertex = new BABYLON.VertexData();
          BABYLON.VertexData.ComputeNormals(positions, indices, normals);

          vertex.positions = positions;
          vertex.indices = indices;
          vertex.normals = normals;
          vertex.applyToMesh(triangle);

          triangle.material = LabelUtils.lineMaterial;

          triangle.position.x = 0;
          triangle.position.y = 1;
          triangle.parent = container;

          const scissor = BABYLON.Mesh.CreateGround('scissor', 30, 30, 1, Scene.CURRENT_SCENE);
          scissor.material = LabelUtils.scissorMaterial;

          scissor.position.x = 50;
          scissor.position.y = 1;
          scissor.parent = container;
        }
        break;
      default:
        break;
    }
    return container;
  }
}
