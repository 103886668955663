import Expand from 'assets/svg/Icon_Size_Fullscreen.svg';
// Icons:
import Collapse from 'assets/svg/Icon_View_Collapse.svg';
// Components:
import { ASlider, Icon } from 'atoms';
// Context:
import { EditorContext } from 'context';
import Scene from 'page/Editor/Scene';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { FullscreenView } from 'types';
// Styles:
import './style.scss';

// ===================================================================
interface Props {
  scene: React.RefObject<Scene>;
  functions?: Array<() => void>;
  labelsOnly?: boolean;
  moreOptions?: boolean;
  fullscreen: FullscreenView;
  set3d?: (is: boolean) => void;
}

// ===================================================================
export const Controls: FC<Props> = ({ scene, functions, labelsOnly, moreOptions = false, fullscreen, set3d }) => {
  const { containsMerges, configuration, hasMasterline, selection } = useContext(EditorContext);
  const [connectionsShown, setConnectionsShown] = useState<boolean>(configuration ? configuration.isShowConnections() : false);
  const [cutsShown, setCutsShown] = useState(configuration ? configuration.isShowCutLabels() : false);
  const [labels, setLabels] = useState<boolean>(configuration ? configuration.isShowLabels() : false);
  const [viewMode, setViewMode] = useState<'left' | 'right'>('right');

  // Effect:
  useEffect(() => {
    if (set3d) {
      if (scene && scene.current) scene.current.setView(viewMode === 'left' ? 'Free' : 'Top');
      set3d(viewMode === 'left');
    }
  }, [viewMode]);

  useEffect(() => {
    setConnectionsShown(configuration ? configuration.isShowConnections() : false);
  }, [configuration, containsMerges, selection]);

  // Render:
  return (
    <div className="Control">
      {/* Zoom Controls */}
      <div className="Control-Zoom">
        <Icon type="minus" size="0.8rem" color="medium" growBtn onClick={() => scene.current.zoomOut()} />
        <div className="Control-Zoom-Middle">
          <Icon type="dotCircle" size="1rem" color="medium" stroke={2} growBtn onClick={() => scene.current.zoomAuto()} />
        </div>
        <Icon type="plus" size="0.8rem" color="medium" growBtn onClick={() => scene.current.zoomIn()} />
      </div>

      {/* Camera Controls */}
      {set3d && (
        <div className="Control-ViewMode">
          <ASlider
            className="Control-ViewMode-Slider"
            pose={viewMode}
            onClick={() => {
              setViewMode(viewMode === 'left' ? 'right' : 'left');
            }}
          >
            <span>{viewMode === 'left' ? '3D-VIEW' : 'PLAN-VIEW'}</span>
          </ASlider>
          <div className="Control-ViewMode-Item" onClick={() => setViewMode('left')}>
            <span style={{ opacity: viewMode === 'right' ? 1 : 0 }}>3D-VIEW</span>
          </div>
          <div className="Control-ViewMode-Item" onClick={() => setViewMode('right')}>
            <span style={{ opacity: viewMode === 'left' ? 1 : 0 }}>PLAN-VIEW</span>
          </div>
        </div>
      )}

      {/* General Manipulation Controls */}
      <div className="Control-Generals">
        {/* More Controls */}
        {moreOptions && (
          <Fragment>
            {/* <div className="Control-Generals-Item">
              <Icon type="undo" size="1rem" stroke={3} growBtn color="medium" onClick={() => {}} />
            </div> */}

            {hasMasterline && !labelsOnly && (
              <div className="Control-Generals-Item">
                <Icon
                  type={cutsShown ? 'scissorsActive' : 'scissors'}
                  size="1.1rem"
                  growBtn
                  stroke={1}
                  margin="2px 0 0 0"
                  color={cutsShown ? 'lightBlue' : 'medium'}
                  onClick={() => {
                    configuration.setShowCutLabels(!cutsShown);
                    setCutsShown(!cutsShown);
                  }}
                />
              </div>
            )}
            {containsMerges && !labelsOnly && (
              <div className="Control-Generals-Item">
                <Icon
                  type="connect"
                  size="1.1rem"
                  stroke={3}
                  growBtn
                  margin="2px 0 0 0"
                  color={connectionsShown ? 'lightBlue' : 'medium'}
                  onClick={() => {
                    //scene.current.setShowConnections(!connectionsShown);
                    configuration.setShowConnections(!connectionsShown);
                    setConnectionsShown(!connectionsShown);
                  }}
                />
              </div>
            )}
            <div className="Control-Generals-Item">
              <Icon
                type="ruler"
                size="1.2rem"
                stroke={2.5}
                color={labels ? 'lightBlue' : 'medium'}
                onClick={() => {
                  //scene.current.setShowLabels(!labels);
                  configuration.setShowLabels(!labels);
                  setLabels(!labels);
                }}
              />
            </div>
          </Fragment>
        )}

        {/* Fullscreen Control */}
        {Array.isArray(functions) && functions[0] && (
          <div className="Control-Generals-Item">
            <img
              alt=""
              onClick={functions[0]}
              src={fullscreen ? Collapse : Expand}
              style={{ cursor: 'pointer', height: fullscreen ? '35px' : '17px', width: fullscreen ? '35px' : '17px' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
