import { useContext, useEffect, useState } from 'react';
import { EditorContext, LangContext } from 'context';
import { HandleType } from '../../../../../../configuration/Block';
import { Device } from 'types';
import Localizations from 'languages';

const OPTIONS_FULL: HandleType[] = ['Top', 'Bottom', 'Both', 'Full'];
const OPTIONS: HandleType[] = ['Top', 'Bottom', 'Both'];

export const useHandleType = (didMount: boolean, handle: Device | null) => {
  const { selectedMasterline } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const [handleType, setHandleType] = useState<HandleType | ''>('');
  const canHaveFullHandleType = handle && handle?.style?.includes('HandleRound');
  const handleTypeOptions: HandleType[] = canHaveFullHandleType ? OPTIONS_FULL : OPTIONS;

  const handleTypeOptionsLabels = handleTypeOptions.map(o => {
    return { value: o, label: Localizations[o][lang] };
  });

  useEffect(() => {
    const currentHandle = selectedMasterline.getHandleType();
    if (!didMount) {
      setHandleType(currentHandle);
    } else {
      if (handleType && handleType !== currentHandle) {
        selectedMasterline.setHandleType(handleType);
      }
    }
  }, [handleType, setHandleType, selectedMasterline, didMount]);

  useEffect(() => {
    if (!canHaveFullHandleType && handleType === 'Full') {
      selectedMasterline.setHandleType('Both');
      setHandleType('Both');
    }
  }, [handleType, canHaveFullHandleType, setHandleType]);

  return {
    canHaveFullHandleType,
    handleType,
    handleTypeOptionsLabels,
    handleTypeOptions,
    setHandleType
  };
};
