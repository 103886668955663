import { Button, Heading, Icon } from 'atoms';
// Components:
import { ADialog, ASimple } from 'atoms/Animations';
import { LoadingSpinner } from 'components';
// Context:
import { EditorContext, LangContext, useUser } from 'context';
// Languages:
import Localizations from 'languages';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Portal } from 'react-portal';
// Utility:
import { post } from 'utils';
// Styles:
import './style.scss';

// Consts:

// ===================================================================
interface Props {
  open: boolean;
  close: () => void;
}

// ===================================================================
export const SettingsDialog: FC<Props> = ({ open, close }) => {
  const { lang } = useContext(LangContext);
  const {
    configuration,
    discountModular,
    discountMasterline,
    discountSpaceCombi,
    setDiscountModular,
    setDiscountMasterline,
    setDiscountSpaceCombi,
    setSavingError,
    savedConfiguration,
    surchargeMasterline,
    setSurchargeMasterline
  } = useContext(EditorContext);
  const { hasPriceAccess, hasSurchargeMasterlineAccess } = useUser();

  const [loading, setLoading] = useState<boolean>(false);

  const saveConfiguration = async () => {
    if (savedConfiguration && savedConfiguration.id !== '') {
      const { error } = await post(`${process.env.REACT_APP_API_URL}/configuration/save`, {
        data: {
          id: savedConfiguration.id ? savedConfiguration.id : null,
          customer: savedConfiguration.customer,
          location: savedConfiguration.location,
          name: savedConfiguration.name,
          public: savedConfiguration.public,
          json: configuration ? configuration.exportJSON() : ''
        }
      });

      if (error) {
        setSavingError(true);
        console.log(error);
      }
      setLoading(false);
    }
  };

  const handleSave = () => {
    setLoading(true);
    // configuration.setDiscountMasterline(discountModular);
    // configuration.setDiscountModular(discountModular);
    // configuration.setDiscountSpaceCombi(discountSpaceCombi);
    // configuration.setSurchargeMasterline(surchargeMasterline);
    saveConfiguration();
    //close();
  };

  /* Render */
  return (
    <Portal>
      {/* Background */}
      {open && <ASimple key="SettingsDialog-Background" className="SettingsDialog-Background" style={{ height: window.innerHeight }} onClick={close} />}

      {/* Content */}
      {open && (
        <ADialog key="SettingsDialog-Content" className="SettingsDialog-Content" topExit="0" topEnter="50%">
          {/* Close Icon */}
          <div className="SettingsDialog-Content-Icon">
            <Icon type="close" size="1.25rem" color="grey" onClick={close} />
          </div>

          {/* Main */}
          <div className="SettingsDialog-Content-Main">
            <Heading level="1" color="dark">
              {Localizations['discounts'][lang]}
            </Heading>
            <div className="Settings-Dialog-Content-Main-Inputs">
              {hasPriceAccess && (
                <>
                  <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                    <label className="discount-label">
                      <input
                        className="discount-input"
                        min={0}
                        max={100}
                        onChange={e => {
                          setDiscountMasterline(parseFloat(e.target.value));
                        }}
                        onBlur={e => {
                          const val = parseFloat(e.target.value);
                          if (val > 100) setDiscountMasterline(100);
                          if (val < 0) setDiscountMasterline(0);
                        }}
                        step={0.1}
                        type="number"
                        value={discountMasterline}
                      />
                      {`${Localizations['discountMasterline'][lang]} (%)`}
                    </label>
                  </div>
                  <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                    <label className="discount-label">
                      <input
                        className="discount-input"
                        min={0}
                        max={100}
                        onChange={e => {
                          setDiscountModular(parseFloat(e.target.value));
                        }}
                        onBlur={e => {
                          const val = parseFloat(e.target.value);
                          if (val > 100) setDiscountModular(100);
                          if (val < 0) setDiscountModular(0);
                        }}
                        step={0.1}
                        type="number"
                        value={discountModular}
                      />
                      {`${Localizations['discountModular'][lang]} (%)`}
                    </label>
                  </div>
                  <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                    <label className="discount-label">
                      <input
                        className="discount-input"
                        min={0}
                        max={100}
                        onBlur={e => {
                          const val = parseFloat(e.target.value);
                          if (val > 100) setDiscountSpaceCombi(100);
                          if (val < 0) setDiscountSpaceCombi(0);
                        }}
                        onChange={e => {
                          setDiscountSpaceCombi(parseFloat(e.target.value));
                        }}
                        step={0.1}
                        type="number"
                        value={discountSpaceCombi}
                      />
                      {Localizations['discountCombi'][lang]} (%)
                    </label>
                  </div>
                </>
              )}

              {hasSurchargeMasterlineAccess && (
                <div style={{ textAlign: 'left', marginBottom: '.5rem' }}>
                  <label className="discount-label">
                    <input
                      className="discount-input"
                      min={0}
                      onBlur={e => {
                        const val = parseFloat(e.target.value);
                        if (val < 0) setSurchargeMasterline(0);
                      }}
                      onChange={e => {
                        setSurchargeMasterline(parseFloat(e.target.value));
                      }}
                      step={0.1}
                      type="number"
                      value={surchargeMasterline}
                    />
                    {Localizations['surcharge'][lang]} {Localizations['masterline'][lang]} (%)
                  </label>
                </div>
              )}
            </div>

            <Button btnType="third" onClick={handleSave} margin="2rem 0 0 0" padding=".65rem 2rem" fontSize=".8rem" width="100%">
              {loading ? <LoadingSpinner size="1rem" /> : Localizations['save'][lang]}
            </Button>
            <Button btnType="second" onClick={close} margin="2rem 0 0 0" padding=".65rem 2rem" fontSize=".8rem" width="100%">
              {loading ? <LoadingSpinner size="1rem" /> : Localizations['cancel'][lang]}
            </Button>
          </div>
        </ADialog>
      )}
    </Portal>
  );
};
