import React, { FC } from 'react';
import { Property } from 'csstype';

// Styles:
import { colors } from 'styles/theme';

// ===================================================================
interface Props {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;

  height?: string;
  width?: string;
  margin?: string;
  padding?: string;

  border?: string;

  color?: string;
  fontSize?: Property.FontSize<string>;
}

// ===================================================================
export const TextArea: FC<Props> = ({
  value,
  setValue,
  placeholder = '',
  height = 75,
  width = '100%',
  margin = '0',
  padding = '0.5rem',
  border = 'solid 2px ' + colors.grey,
  color = colors.medium,
  fontSize = '0.9rem'
}) => {
  return (
    <textarea
      value={value}
      onChange={(event: any) => setValue(event.target.value)}
      placeholder={placeholder}
      style={{
        height: height,
        width: width,

        margin: margin,
        padding: padding,

        color: color,
        font: 'inherit',
        fontSize: fontSize,

        border: border,

        outline: 'none',
        resize: 'none'
      }}
    />
  );
};
