// Components:
import { DeleteConfirm, LoadingSpinner } from 'components';
import React, { CSSProperties, FC, Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// Types
import { SavedConfiguration } from 'types';
// Utils:
import { post } from 'utils';
// Partials:
import { TableHeader, TableRow } from './Partials';
import { useTableData } from './Hooks/useTableData';
import { Button } from 'atoms';

// Consts:
// 1/12, 1/6, 1/4
const widths = ['8.33%', '14.58%', '41.66%', '33.33%', '22.91%'];
const style: CSSProperties = {
  fontSize: '.85rem',
  width: widths[1],
  textAlign: 'start',
  letterSpacing: '0.5px',
  lineHeight: '15px'
};

// ===================================================================
interface Props extends RouteComponentProps {
  width?: string;
  height?: string;
  filter?: string;
  archive?: boolean;
}

export type SortCriteria = 'created' | 'id' | 'name' | 'customer' | 'location' | 'email';

// ===================================================================
const Table: FC<Props> = ({ width = '76%', height = 'auto', filter = '', archive = false }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { sortBy, direction, setDirection, setSortBy, configs, setConfigs, loading, hasMore, onLoadMore } = useTableData(archive, filter);

  const closeDelete = () => {
    setShowDeleteConfirm(false);
    setSelectedIndex(null);
  };

  // Methods

  const deleteRow = async (index: number) => {
    var newConfigs = Array.from(configs);
    newConfigs.splice(index, 1);

    const { error } = await post(`${process.env.REACT_APP_API_URL}/configuration/delete`, {
      data: { id: configs[index].id }
    });

    if (error) console.log('Deleting Configuration failed:', error);

    setConfigs(newConfigs);
  };

  // Render
  return (
    <Fragment>
      {configs && (
        <table
          style={{
            height: height,
            width: width,
            margin: '4rem auto',
            borderCollapse: 'collapse'
          }}
        >
          {/* Header */}
          <TableHeader
            style={style}
            widths={widths}
            setSortBy={setSortBy}
            sortBy={sortBy}
            direction={direction}
            setDirection={setDirection}
            archive={archive}
          />

          {/* Rows */}
          <tbody>
            {configs.length > 0 &&
              configs.map((config: SavedConfiguration, index: number) => (
                <TableRow
                  key={'Row-' + index}
                  widths={widths}
                  style={style}
                  configuration={config}
                  index={index}
                  archive={archive}
                  deleteRow={() => {
                    setSelectedIndex(index);
                    setShowDeleteConfirm(true);
                  }}
                />
              ))}
          </tbody>
        </table>
      )}
      {hasMore && (
        <div className="w-100 flex justify-center py-4">
          <Button onClick={onLoadMore}>Mehr laden</Button>
        </div>
      )}

      {loading && (
        <div style={{ width: '60%', margin: '4rem auto' }}>
          <LoadingSpinner />
        </div>
      )}
      {showDeleteConfirm && (
        <DeleteConfirm
          confirm={() => {
            deleteRow(selectedIndex);
            closeDelete();
          }}
          abort={closeDelete}
        />
      )}
    </Fragment>
  );
};

export default withRouter(Table);
