import React, { FC, useContext, useState } from 'react';

// Components
import { Icon } from 'atoms';
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';
import Dimensions from '../../../../../../utils/Dimensions';

// Context
interface Props {}

export const GroundPlan: FC<Props> = () => {
  const { lang } = useContext(LangContext);
  const { configuration, setErrorAlert, setRoomView } = useContext(EditorContext);

  const room = configuration.getRoom();

  const [groundPlan, setGroundPlan] = useState({ depth: room ? room.getDepth() : 0, width: room ? room.getWidth() : 0 });

  return (
    <div key="room-groundplan-menu">
      <ContextMenu mode="back" onClick={() => setRoomView('home')} />
      <div className="Submenu-Headline"> {Localizations['groundPlan'][lang]}</div>
      {room ? (
        <div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setGroundPlan({ ...groundPlan, width: val });
                    room.setWidth(val);
                  }
                }}
                max={Dimensions.GRID_MAX * 20}
                step={100}
                type="number"
                value={groundPlan.width}
              />
              {Localizations['width'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setGroundPlan({ ...groundPlan, depth: val });
                    room.setDepth(val);
                  }
                }}
                step={100}
                type="number"
                max={Dimensions.GRID_MAX * 20}
                value={groundPlan.depth}
              />
              {Localizations['length'][lang]} (mm)
            </label>
          </div>
          <div className="DeviceDetails-Small-Bottom">
            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        </div>
      ) : (
        <div>Add a room first</div>
      )}
    </div>
  );
};
