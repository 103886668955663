import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import { withRouter } from 'react-router-dom';

// Components:
import { AEditorMenu } from 'atoms';
import { Controls, LoadingSpinner, LoadingErrorHint, TDControls } from 'components';
import Configuration from './configuration/Configuration';

// Context:
import { EditorContext } from 'context';

// Partials:
import { Menu } from './Menu';
import Scene from './Scene';

// Styles:
import './style.scss';

// Types
import { SavedConfiguration } from 'types';

// Utility:
import { get } from 'utils';
import LoaderUtils from 'components/babylon/util/LoaderUtils';

// ===================================================================
const Editor: FC = () => {
  const {
    discountMasterline,
    discountModular,
    discountSpaceCombi,
    setDiscountMasterline,
    setDiscountModular,
    setDiscountSpaceCombi,
    setConfiguration,
    fullscreen,
    setFullscreen,
    setOwner,
    savedConfiguration,
    setSavedConfiguration,
    showLoadingError,
    setCanAdvanceModular,
    setCanAdvanceMasterline,
    setShowLoadingError,
    setMasterlineView,
    setMenuView,
    setModularView,
    setSelection,
    scene,
    setScene,
    surchargeMasterline,
    setSurchargeMasterline
  } = useContext(EditorContext);
  const sceneRef = useRef<Scene>(null);
  const [sceneIsLoading, setSceneIsLoading] = useState(true);
  const [is3D, setIs3D] = useState(false);

  const [id, setId] = useState('');

  useEffect(() => {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('id')) {
      setId(urlParams.get('id'));
    } else {
      setId('new');
    }
  }, [window.location.search]);

  useEffect(() => {
    if (sceneIsLoading) {
      const interval = setInterval(() => {
        if (!LoaderUtils.isLoading()) {
          setSceneIsLoading(false);
          clearInterval(interval);
        }
      }, 250);
    }
  }, [sceneIsLoading]);

  // Effects
  useEffect(() => {
    const getContext = async (id: string) => {
      const { data, error } = await get(`${process.env.REACT_APP_API_URL}/configuration/get/${id}`, {});

      if (data) {
        setSavedConfiguration({
          ...data
        });
        if (!data.owner) {
          setOwner(false);
        } else {
          setOwner(true);
        }
        Configuration.importJSON(data.json)
          .then((res: Configuration) => {
            setDiscountModular(res.getDiscountModular());
            setDiscountMasterline(res.getDiscountMasterline());
            setDiscountSpaceCombi(res.getDiscountSpaceCombi());
            setSurchargeMasterline(res.getSurchargeMasterline());
            setConfiguration(res);
          })
          .catch(rej => console.log('rejected: ', rej));
      }

      if (error) {
        console.log('Error loading Config:', error);
        setShowLoadingError(true);
      }
    };

    var urlParams = new URLSearchParams(window.location.search);

    if (id && id !== 'new' && id !== savedConfiguration?.id) {
      // Load configuration
      getContext(id);
    } else if (urlParams.has('customer') && urlParams.has('object') && id !== null && id !== '') {
      const newContext: SavedConfiguration = {
        id: null,
        customer: urlParams.get('customer'),
        name: urlParams.get('object'),
        public: false,
        created: '',
        json: '',
        email: '',
        location: urlParams.get('location') || ''
      };

      if (sceneRef.current) {
        const createConfiguration = () => {
          if (sceneRef.current.isInitialized()) {
            const config = new Configuration();
            config.setDiscountMasterline(discountMasterline);
            config.setDiscountModular(discountModular);
            config.setDiscountSpaceCombi(discountSpaceCombi);
            config.setSurchargeMasterline(surchargeMasterline);

            setConfiguration(config);
            setModularView('home');
            setMasterlineView('home');
            setMenuView('room');
            setCanAdvanceModular(false);
            setCanAdvanceMasterline(false);
          } else {
            window['createConfig'] = setTimeout(() => {
              createConfiguration();
            }, 100);
          }
        };
        clearTimeout(window['createConfig']);
        createConfiguration();
      }
      setSavedConfiguration(newContext);
    }

    return () => {
      setMasterlineView('home');
      setSelection(null);
    };
  }, [id]);

  useEffect(() => {
    if (!scene && sceneRef.current) setScene(sceneRef.current);
  }, [scene]);

  useEffect(() => {
    if (sceneRef.current) sceneRef.current.resize();
  }, [fullscreen]);

  // Consts:
  const controlFunctions = [() => setFullscreen(fullscreen != null ? null : 'scene')];

  // Render
  return (
    <>
      <div className="Editor">
        <div className="Editor-Scene" style={{ width: `calc(100% - ${fullscreen === 'scene' ? 0 : 320}px)` }}>
          {sceneIsLoading && (
            <div className="Editor-Scene-LoadingIndicator">
              <LoadingSpinner color="medium" />
            </div>
          )}
          <Scene ref={sceneRef} />
          <Controls scene={sceneRef} set3d={setIs3D} functions={controlFunctions} moreOptions={true} fullscreen={fullscreen} />
          {is3D /* && fullscreen === 'scene' */ && <TDControls />}
        </div>
        <AEditorMenu key="Menu" pose={fullscreen === 'menu' ? 'expanded' : fullscreen === 'scene' ? 'exit' : 'enter'}>
          <Menu />
        </AEditorMenu>
      </div>
      {showLoadingError && <LoadingErrorHint />}
    </>
  );
};

export default withRouter(Editor);
