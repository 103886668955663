// Partials:
import LDataProtection from './Partials/dataProtection';
import LHome from './Partials/home';
import LLanguages from './Partials/languages';
import LLogin from './Partials/login';
import LNavigation from './Partials/navigation';
import LMenu from './Partials/menu';
import LHeader from './Partials/header';
import LImprint from './Partials/imprint';
import LAgb from './Partials/agb';

// ==============================================================
const Localizations = {
  ...LDataProtection,
  ...LLogin,
  ...LHome,
  ...LNavigation,
  ...LLanguages,
  ...LMenu,
  ...LHeader,
  ...LDataProtection,
  ...LAgb,
  ...LImprint
};

export * from './Partials/PdfStatic';

export default Localizations;
