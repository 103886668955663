import { useEffect, useState } from 'react';
import { Device } from 'types';
import { post } from 'utils';

export const useBorderDevices = () => {
  const [borders, setBorders] = useState<Device[]>([]);
  const [borderNames, setBorderNames] = useState<string[]>([]);

  useEffect(() => {
    const borderKey = `glbl-brdr-dvcs-mm`;

    const getBorders = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'marineMeister',
          device: {
            subtype: 'Border'
          }
        }
      });
      if (data) {
        setBorders(data);
        setBorderNames([...data.map((d: Device) => d.translations)]);
        sessionStorage.setItem(borderKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedBorders = sessionStorage.getItem(borderKey);
    if (cachedBorders) {
      const parsedBorders = JSON.parse(cachedBorders);
      setBorders(parsedBorders);
      setBorderNames([...parsedBorders.map((d: Device) => d.translations)]);
    } else {
      getBorders();
    }

    return () => {};
  }, []);

  return {
    borderNames,
    borders
  };
};
