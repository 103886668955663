import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';

export const isDeviceCompatibleWithDepth = (device: Device, depth: number): boolean => {
  for (let i = 0; i < device.model.depths.length; i++) {
    const d = device.model.depths[i];
    if (d === depth) return true;
  }
  return false;
};

export const isDeviceCompatibleWithSubtype = (device: Device, subtype: Subtype | string) => {
  if (device && device.dependency && device.dependency.equipmentCombinations) {
    if (typeof subtype === 'string') subtype = Subtype[subtype];
    for (let i = 0; i < device.dependency.equipmentCombinations.length; i++) {
      let compareType: Subtype | string = device.dependency.equipmentCombinations[i];
      if (typeof compareType === 'string') compareType = Subtype[compareType];
      if (compareType === subtype) return true;
    }
  }
  return false;
};
