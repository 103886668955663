import { useState } from 'react';

export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    handleClose: () => setIsOpen(false),
    handleOpen: () => setIsOpen(true),
    isOpen
  };
};
