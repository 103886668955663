import { RoomPillar } from 'page/Editor/configuration/RoomPillar';
import Scene from 'page/Editor/Scene';
import InnerRoomNode from './helper/InnerRoomNode';

export class RoomPillarNode extends InnerRoomNode {
  private _roomPillar: RoomPillar;

  constructor(roomPillar: RoomPillar) {
    super(roomPillar, 'pillar', Scene.CURRENT_SCENE);
    this._roomPillar = roomPillar;
  }

  public getRoomPillar() {
    return this._roomPillar;
  }
}
