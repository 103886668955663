import { SortCriteria } from 'components/Table';
import { useCallback, useEffect, useState } from 'react';
import { SavedConfiguration } from 'types';
import { post } from 'utils';

export const useTableData = (isArchive: boolean, query: string) => {
  const ROWS = 20;
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState<SortCriteria>('created');
  const [direction, setDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [configs, setConfigs] = useState<SavedConfiguration[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);

  const refetchConfigs = useCallback(async () => {
    setLoading(true);
    const { count, data, error } = await post(`${process.env.REACT_APP_API_URL}${isArchive ? '/configuration/search/public' : '/configuration/search'}`, {
      data: {
        rows: ROWS,
        offset: 0,
        query,
        sort: {
          field: sortBy,
          order: direction
        }
      }
    });

    if (data) {
      setConfigs(data);
    }
    setHasMore(count > configs.length);
    if (error) console.log('Error:', error);
    setLoading(false);
  }, [direction, ROWS, sortBy, setConfigs, setLoading, query, offset]);

  const handleLoadMore = useCallback(async () => {
    setLoading(true);
    const { count, data, error } = await post(`${process.env.REACT_APP_API_URL}${isArchive ? '/configuration/search/public' : '/configuration/search'}`, {
      data: {
        rows: ROWS,
        offset: offset + ROWS,
        query,
        sort: {
          field: sortBy,
          order: direction
        }
      }
    });
    if (data) {
      setConfigs([...configs, ...data]);
      setHasMore(count > configs.length + data.length);
    }

    if (error) console.log('Error:', error);
    setOffset(offset + ROWS);
    setLoading(false);
  }, [direction, ROWS, sortBy, query, configs, setHasMore, setLoading, setConfigs, setOffset, offset]);

  useEffect(() => {
    refetchConfigs();
  }, [direction, ROWS, sortBy, query]);

  return {
    configs,
    direction,
    loading,
    onLoadMore: handleLoadMore,
    setDirection,
    setSortBy,
    sortBy,
    hasMore,
    setConfigs
  };
};
