import React, { CSSProperties, FC, useCallback, useContext, useEffect, useState } from 'react';

// Components
import { AEditorMenuItem } from 'atoms';
import { ErrorMessage, SearchResults, SelectedCategory, SelectedCategoryFull, SelectedDevice } from 'page/Editor/Menu/Shared';
import { ModularNOLGlobal, ModularNOLHome, ModularNOLFunctional, ModularNOLStructure, Substructures, ModularNOLUpperStructure } from './Partials';

import Block from 'page/Editor/configuration/Block';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { Category } from 'types';

// Utils
import { getCategories, hasOwnProperty, isDevice, updateBlockIds } from 'utils';

interface Props {}

export const ModularNOL: FC<Props> = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const {
    configuration,
    selection,
    errorAlert,
    fullscreen,
    menuView,
    modularNOLView,
    scene,
    searchResults,
    selectedModularNOL,
    setErrorAlert,
    setFullscreen,
    setMasterlineView,
    setModularNOLView,
    setSelection,
    setSelectedModularNOL
  } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [fullscreenIsMenu, setFullscreenIsMenu] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>(null);

  const style: CSSProperties = { height: '100%', position: 'relative' };
  const left: string = '5%';

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        if (selection && selection instanceof BlockItem) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    if (selection) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  useEffect(() => {
    if (configuration && configuration.getModularNOL() && configuration.getModularNOL().length > 0) {
      setSelectedModularNOL(configuration.getModularNOL()[0]);
    }
  }, [configuration]);

  useEffect(() => {
    const fetchCategories = async () => {
      const { data, error } = await getCategories('modularNOL');
      if (data) {
        setCategories(data);
      }
      if (error) {
        console.log(error);
      }
    };
    fetchCategories();
    return () => {
      setSelectedCategory(null);
    };
  }, []);

  useEffect(() => {
    // reset all Selections and Submenus
    //if (selection) setSelection(null);
    if (selectedCategory) setSelectedCategory(null);
    if (fullscreenIsMenu) setFullscreenIsMenu(false);
    if (errorAlert) setErrorAlert(null);
    return () => {
      setFullscreen(null);
      setFullscreenIsMenu(null);
    };
  }, []);

  useEffect(() => {
    if (fullscreen === 'menu') {
      setFullscreenIsMenu(true);
    }
    if (fullscreen !== 'menu' && fullscreenIsMenu) {
      if (scene && scene.getSelected && scene.getSelected() instanceof BlockItem) {
        setSelectedCategory(null);
        setSelection(scene.getSelected());
      }
      setFullscreenIsMenu(false);
    }
  }, [fullscreen, fullscreenIsMenu]);

  useEffect(() => {
    // reset all Selections and Submenus
    //if (selection) setSelection(null);
    if (selectedCategory) setSelectedCategory(null);
    if (fullscreenIsMenu) setFullscreenIsMenu(false);
    if (errorAlert) setErrorAlert(null);
  }, [menuView, modularNOLView]);

  const selectionOrError = selection || selectedCategory || menuView !== 'modularNOL';

  if (!(menuView === 'modularNOL')) {
    return <></>;
  }

  const showSearchResults = searchResults?.length && !errorAlert?.includes('size') && !selection && menuView === 'modularNOL';
  const showSelectedDevice =
    !fullscreen &&
    /* !searchResults && */
    selection &&
    selection instanceof BlockItem &&
    !(modularNOLView === 'substructures') &&
    !(modularNOLView === 'upperStructures') &&
    !fullscreen &&
    !(errorAlert && (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement')));
  const showOtherSelectedDevice =
    !fullscreen &&
    /* !searchResults && */
    selection &&
    hasOwnProperty(selection, 'type') &&
    isDevice(selection) &&
    !(modularNOLView === 'substructures') &&
    !(modularNOLView === 'upperStructures') &&
    !fullscreen &&
    !(
      errorAlert &&
      (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
    ) &&
    menuView === 'modularNOL';
  const showSelectedCategory =
    !fullscreen &&
    !searchResults &&
    selectedCategory &&
    (!selection || (hasOwnProperty(selection, 'type') && isDevice(selection))) &&
    menuView === 'modularNOL';

  const showSelectedCategoryFull = selectedCategory && !selection && fullscreenIsMenu && menuView === 'modularNOL';
  const showStructureMenu =
    !fullscreen &&
    !searchResults &&
    !(
      errorAlert &&
      (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
    ) &&
    ((!selectionOrError && modularNOLView === 'structure' && menuView === 'modularNOL') || (selection && selection instanceof Block));

  const showFunctionalMenu = !fullscreen && !searchResults && !selectionOrError && modularNOLView === 'functional';

  const showUpperStructureMenu = !fullscreen && !searchResults && modularNOLView === 'upperStructures';
  const showSubstructureMenu = !fullscreen && !searchResults && modularNOLView === 'substructures' && menuView === 'modularNOL';

  const showGlobalMenu = !fullscreen && !searchResults && !selectionOrError && modularNOLView === 'global';
  const showHome = !fullscreen && !searchResults && !selectionOrError && modularNOLView === 'home';
  const showMultipleErrors = !fullscreen && !searchResults && errorAlert && errorAlert.length > 1 && !errorAlert.includes('delete');
  const showDelete = !fullscreen && !searchResults && errorAlert && errorAlert.length > 0 && errorAlert.includes('delete');

  return (
    <>
      {/* Search Results */}
      {showSearchResults && (
        <AEditorMenuItem isVisible={showSearchResults} key="ModularNOL-Search-Results" left={left} style={style}>
          <SearchResults />
        </AEditorMenuItem>
      )}

      {/* Selected Device */}
      {!fullscreen &&
        /* !searchResults && */
        selection &&
        selection instanceof BlockItem &&
        !(modularNOLView === 'substructures') &&
        !(modularNOLView === 'upperStructures') &&
        !fullscreen &&
        !(
          errorAlert &&
          (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
        ) && (
          <AEditorMenuItem
            isVisible={showSelectedDevice}
            key="modularNOL-selected-device"
            left={left}
            style={{ ...style, position: 'absolute', zIndex: 9001, top: 0 }}
          >
            <SelectedDevice isOverlay selectedDevice={[selection.getDeviceObject()]} />
          </AEditorMenuItem>
        )}

      {!fullscreen &&
        /* !searchResults && */
        selection &&
        hasOwnProperty(selection, 'type') &&
        isDevice(selection) &&
        !(modularNOLView === 'substructures') &&
        !(modularNOLView === 'upperStructures') &&
        !fullscreen &&
        !(
          errorAlert &&
          (errorAlert.includes('size') || errorAlert.includes('delete') || errorAlert.includes('fryer') || errorAlert.includes('coverEnlargement'))
        ) &&
        isDevice(selection) &&
        menuView === 'modularNOL' && (
          <AEditorMenuItem
            isVisible={showOtherSelectedDevice}
            key="ModularNOL-Device - 2"
            left={left}
            style={{ ...style, position: 'absolute', zIndex: 9001, top: 0 }}
          >
            {/* @ts-ignore */}
            <SelectedDevice isOverlay selectedDevice={[selection]} setSelection={setSelection} />
          </AEditorMenuItem>
        )}

      {/* Selected Category */}
      {showSelectedCategory && (
        <AEditorMenuItem isVisible={showSelectedCategory} key="ModularNOL-selected-Category" left={left} style={{ ...style, overflow: 'hidden' }}>
          <SelectedCategory setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        </AEditorMenuItem>
      )}

      {/* Selected Category Full */}
      {showSelectedCategoryFull && (
        <AEditorMenuItem isVisible={showSelectedCategoryFull} key="ModularNOL-Category-Fullscreen" left={left} style={style}>
          <SelectedCategoryFull setSelection={setSelection} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        </AEditorMenuItem>
      )}

      {/* Structure Menu */}
      {showStructureMenu && (
        <AEditorMenuItem isVisible={showStructureMenu} key="modularNOL-menu-structure" left={left} style={style}>
          <ModularNOLStructure setView={setModularNOLView} />
        </AEditorMenuItem>
      )}

      {/* Functional Menu */}
      {showFunctionalMenu && (
        <AEditorMenuItem isVisible={showFunctionalMenu} key="modularNOL-menu-functional" left={left} style={style}>
          <ModularNOLFunctional
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSelectedDevice={setSelection}
            setView={setModularNOLView}
          />
        </AEditorMenuItem>
      )}

      {/* Upper Structure Menu */}
      {showUpperStructureMenu && (
        <AEditorMenuItem isVisible={showUpperStructureMenu} key="modularNOL-menu-upperStructures" left={left} style={style}>
          <ModularNOLUpperStructure />
        </AEditorMenuItem>
      )}

      {/* Substructure Menu */}
      {showSubstructureMenu && (
        <AEditorMenuItem isVisible={showSubstructureMenu} key="modularNOL-menu-substructures" left={left} style={style}>
          <Substructures />
        </AEditorMenuItem>
      )}

      {/* Global Menu */}
      {showGlobalMenu && (
        <AEditorMenuItem isVisible={showGlobalMenu} key="modularNOL-menu-global" left={left} style={style}>
          <ModularNOLGlobal />
        </AEditorMenuItem>
      )}

      {/* Home Menu */}
      {showHome && (
        <AEditorMenuItem isVisible={showHome} key="modularNOL-menu-home" left={left} style={style}>
          <ModularNOLHome setView={setModularNOLView} />
        </AEditorMenuItem>
      )}

      {/* Multiple Errors */}
      {showMultipleErrors && (
        <AEditorMenuItem isVisible={showMultipleErrors} key="ModularNOL-ErrorMessageMultiple" left={left} style={style}>
          <ErrorMessage goBack={() => setErrorAlert(null)} />
        </AEditorMenuItem>
      )}

      {/* Delete Confirm */}
      {showDelete && (
        <AEditorMenuItem isVisible={showDelete} key="ModularNOL-ErrorMessageDelete" left={left} style={style}>
          <ErrorMessage
            title={Localizations['delete'][lang]}
            message={Localizations['confirmDelete'][lang]}
            confirm={() => {
              if ((modularNOLView === 'structure' && selectedModularNOL) || (scene && scene.getSelected() && scene.getSelected() instanceof Block)) {
                const index = configuration.getModularNOL().findIndex((b: Block) => b.getId() === selectedModularNOL.getId());
                configuration.removeModularNOL(index);
                if (!configuration.getModularNOL() || (configuration.getModularNOL() && configuration.getModularNOL().length === 0)) {
                  setSelectedModularNOL(null);
                } else {
                  setSelectedModularNOL(configuration.getModularNOL()[0]);
                }
                updateBlockIds(configuration.getModularNOL(), 'modularNOL');
              } else if (selection instanceof BlockItem || selection instanceof BlockGroup) {
                selection.delete();
                setSelection(null);
                updateBlockIds(configuration.getModularNOL(), 'modularNOL');

                window.removeEventListener('keydown', handleKeyDown);
              } else {
                console.log('could not delete');
                setSelection(null);
                /*  if (scene.getSelected()) {
                  scene.getSelected().delete();
                  window.removeEventListener('keydown', handleKeyDown);
                } */
              }
              if (configuration) {
                if (selectedModularNOL && selectedModularNOL.getRowBottom() && selectedModularNOL.getRowBottom().getItems().length === 0) {
                  if (
                    !selectedModularNOL.getRowTop() ||
                    (selectedModularNOL.getRowTop() && selectedModularNOL.getRowTop().getItems() && selectedModularNOL.getRowTop().getItems().length === 0)
                  ) {
                    //setCanAdvanceMenu(false);
                    setMasterlineView('home');
                    setModularNOLView('home');
                    setModularNOLView('home');
                  }
                }
              }
              setSelection(null);
              setErrorAlert(null);
              scene.setSelected(null);
            }}
            goBack={() => setErrorAlert(null)}
          />
        </AEditorMenuItem>
      )}
    </>
  );
};
