import Block from '../page/Editor/configuration/Block';
import BlockItem from '../page/Editor/configuration/BlockItem';
import BlockGroup from '../page/Editor/configuration/BlockGroup';

export const initializeContainsMerges = (block: Block) => {
  const rowTop = block.getRowTop();
  const rowBottom = block.getRowBottom();
  return (
    (rowTop &&
      rowTop.getItems() &&
      rowTop.getItems().length > 0 &&
      rowTop.getItems().findIndex((item: BlockItem | BlockGroup) => item instanceof BlockGroup) !== -1) ||
    (rowBottom &&
      rowBottom.getItems() &&
      rowBottom.getItems().length > 0 &&
      rowBottom.getItems().findIndex((item: BlockItem | BlockGroup) => item instanceof BlockGroup) !== -1)
  );
};