import React, { FC, useState, useEffect, useContext } from 'react';

// Styles:
import './style.scss';

// Context:
import { EditorContext } from 'context';

// Atoms:
import { Icon } from 'atoms';

// ===================================================================
interface Props {}

// ===================================================================
export const TDControls: FC<Props> = () => {
  const { scene } = useContext(EditorContext);
  const [viewMode, setViewMode] = useState<'Diagonal' | 'Back' | 'Top2'>(null);

  useEffect(() => {
    if (scene) {
      //view: 'Free' | 'Top' | 'FreeFront' | 'FreeBack' | 'FreeDiagonal' | 'Front' | 'Back' | 'Diagonal'
      scene.setView(viewMode ? viewMode : 'Free');
    }
  }, [viewMode]);

  // Render:
  return (
    <div className="TDControls">
      <div className="TDControls-Control">
        <Icon
          margin="-1.25rem 0"
          onClick={() => setViewMode(viewMode === 'Diagonal' ? null : 'Diagonal')}
          type={viewMode === 'Diagonal' ? 'topLeftViewActive' : 'topLeftViewInactive'}
          size="7rem"
        />
      </div>
      <div className="TDControls-Control">
        <Icon
          margin="-1.25rem 0"
          onClick={() => setViewMode(viewMode === 'Back' ? null : 'Back')}
          type={viewMode === 'Back' ? 'sideViewActive' : 'sideViewInactive'}
          size="7rem"
        />
      </div>
      <div className="TDControls-Control">
        <Icon
          margin="-1.25rem 0"
          onClick={() => setViewMode(viewMode === 'Top2' ? null : 'Top2')}
          type={viewMode === 'Top2' ? 'topViewActive' : 'topViewInactive'}
          size="7rem"
        />
      </div>
    </div>
  );
};
