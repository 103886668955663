import React from 'react';
import './style.scss';

interface Props {
  pose?: 'active' | 'inactive';
}

export const ASearchbar: React.FC<Props> = ({ children, pose }) => {
  return <div className={pose === 'active' ? 'Searchbar-Active' : 'Searchbar-Inactive'}>{children}</div>;
};
