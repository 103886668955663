import { useOrganizationContext } from 'page/Organization/context/OrganizationDataProvider';
import { useState } from 'react';
import { Organization } from 'types';
import { post } from 'utils';

export const useUpdateOrganization = () => {
  const { organizationData } = useOrganizationContext();

  const [loading, setLoading] = useState(false);

  const handleSaveOrg = async (org: Partial<Organization>) => {
    if (!organizationData) return { data: null, error: 'No Org' };
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/organization/save`, {
      data: {
        ...organizationData,
        ...org
      }
    });
    if (data) {
      console.log('Updated Org: ', data);
    }
    if (error) {
      console.log('Error updating Org - ', error);
    }
    setLoading(false);
    return { data, error };
  };

  return {
    handleSaveOrg,
    loading
  };
};
