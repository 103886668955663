import React, { FC, Fragment, useContext, useState, useEffect } from 'react';
import { SwatchesPicker, ColorResult } from 'react-color';

// Assets
import Info from 'assets/svg/Icon_Info.svg';

// Components
import { Button, Input, Selectbox } from 'atoms';
import { BlockSelector } from 'components';
import { ContextMenu, EquipmentSwitch, ErrorMessage } from 'page/Editor/Menu/Shared';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types:
import { Category, Device } from 'types';

// Utils
import ralColors from 'utils/ralColors';
import ralColorsFlat from 'utils/ralColorsFlat';
import { checkMarginInput, findDeviceFromList, post } from 'utils';
import { useHandleShelfSettings, useInitShelfSettings } from './hooks';
import { useShelfSelection } from './hooks/useShelfSelection';
import { useHandleType } from './hooks/useHandleType';

// ===================================================================

export type BordSettings = {
  marginLeft: number;
  marginRight: number;
  marginMixingFaucetLeft: number;
  marginMixingFaucetRight: number;
  mixingFaucetLeft: Device[];
  mixingFaucetRight: Device[];
  salamanderLeft: boolean;
  salamanderRight: boolean;
};

type Translation = {
  name: string;
  description: string;
};

type Translations = {
  '043r1': Translation;
  vdrqR: Translation;
  vRkb4: Translation;
};

interface Props {
  selectedCategory: Category;
}

const UPDATE_TIMEOUT = 500;

// ===================================================================
export const MasterlineGlobal: FC<Props> = ({ selectedCategory }) => {
  const standardBorder = {
    de: 'Abdeckungs-Abschluss mit Überstand 50 mm',
    en: 'Worktop edge - Standard corner with overstand 50 mm',
    // TODO: Add czech translation when available
    cz: 'Worktop edge - Standard corner with overstand 50 mm',
    // TODO: Add fr translation when available
    fr: 'Worktop edge - Standard corner with overstand 50 mm'
  };
  const defaultMetallic = '#9B9BA0';
  const hexFromRal = ralColors.map(rC => rC.map(c => c.hex));

  const { lang, langId } = useContext(LangContext);

  const { selectedMasterline, scene, setErrorAlert, setMasterlineView, setSelection } = useContext(EditorContext);

  const [boardDevices, setBoardDevices] = useState<Device[]>([]);
  const [borders, setBorders] = useState<Device[]>([]);
  const [borderNames, setBorderNames] = useState<String[]>(['']);

  const [color, setColor] = useState<number | null>(selectedMasterline ? selectedMasterline.getBlendColor() : 0);
  const [doorColor, setDoorColor] = useState<number>(selectedMasterline ? selectedMasterline.getDoorColor() : 0);

  const [cutsX, setCutsX] = useState(selectedMasterline ? selectedMasterline.getCutX() : 0);
  const [cutsY, setCutsY] = useState(selectedMasterline ? selectedMasterline.getCutY() : 0);

  const [customRal, setCustomRal] = useState(color ? color.toString() : '');
  const [customDoorRal, setCustomDoorRal] = useState(doorColor ? doorColor.toString() : '');
  const [updating, setUpdating] = useState(false);

  const [handrailNames, setHandrailNames] = useState<String[]>(['']);
  const [handrails, setHandrails] = useState<Device[]>([]);
  const [powerSocketAmounts, setPowerSocketAmounts] = useState<number[]>([]);
  const [powerSocketDevices, setPowerSocketDevices] = useState<Device[]>([]);
  const [powerSocketTranslations, setPowerSocketTranslations] = useState<Translations[]>([]);
  const [selectedBorderRight, setSelectedBorderRight] = useState(standardBorder[lang] ? standardBorder[lang] : standardBorder.de);
  const [selectedBorderLeft, setSelectedBorderLeft] = useState(standardBorder[lang] ? standardBorder[lang] : standardBorder.de);
  const [selectedHandlebar, setSelectedHandlebar] = useState('');
  const [selectedShelf, setSelectedShelf] = useState(
    selectedMasterline?.getBoard()?.getDeviceObject()?.translations
      ? selectedMasterline.getBoard().getDeviceObject().translations[langId]?.name
      : selectedMasterline?.getBoard()?.isSalamanderLeft() || selectedMasterline?.getBoard()?.isSalamanderRight()
      ? Localizations['salamanderBoard'][lang]
      : Localizations['noShelf'][lang]
  );
  const IS_WALL = selectedMasterline?.getSingleType() === 'Wall' && selectedMasterline.getType() === 'Single';
  const [isFreeBoard, setIsFreeBoard] = useState(false);
  const [isMediaBoard, setIsMediaBoard] = useState(false);
  const [mixingFaucetDevice, setMixingFaucetDevice] = useState<Device>(null);
  const [shelfMixingFaucetDevice, setShelfMixingFaucetDevice] = useState<Device>(null);

  const [boardSettings, setBoardSettings] = useState<BordSettings>({
    marginLeft: 0,
    marginRight: 0,
    marginMixingFaucetLeft: 0,
    marginMixingFaucetRight: 0,
    salamanderLeft: false,
    salamanderRight: false,
    mixingFaucetLeft: [],
    mixingFaucetRight: []
  });
  const [settings, setSettings] = useState({
    panel: true,
    colorSB: false,
    sideCover: selectedMasterline ? selectedMasterline.isSideCover() : false,
    steelKnob: selectedMasterline ? selectedMasterline.isSteelKnob() : false
  });
  const [showColors, setShowColors] = useState(false);
  const [showDoorColors, setShowDoorColors] = useState(false);
  const [showShelfHint, setShowShelfHint] = useState(false);
  const [shelfOptions, setShelfOptions] = useState([Localizations['noShelf'][lang]]);
  const [sideBoardDevices, setSideBoardDevices] = useState<Device[]>([]);
  const [sideBoardLeft, setSideBoardLeft] = useState('');
  const [sideBoardLeftWidth, setSideBoardLeftWidth] = useState<'300' | '400'>('300');
  const [sideBoardRight, setSideBoardRight] = useState('');
  const [sideBoardRightWidth, setSideBoardRightWidth] = useState<'300' | '400'>('300');

  const socketOptions = [
    Localizations['mknSocket'][lang],
    Localizations['mknSocket200'][lang],
    Localizations['buildSideSocket'][lang],
    Localizations['buildSideSocket200'][lang],
    Localizations['installedFeet'][lang],
    Localizations['installedFeet200'][lang]
  ];
  const [socketStyle, setSocketStyle] = useState('');

  const usedMixingFaucet = isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice;

  const [didMount, setDidMount] = useState(false);
  const { shelfInitialized } = useInitShelfSettings(didMount, boardSettings, setBoardSettings, setIsMediaBoard, setIsFreeBoard, setUpdating);
  const { handleTypeOptionsLabels, handleType, setHandleType } = useHandleType(
    didMount,
    handrails.find((hR: Device) => hR.translations[langId].name === selectedHandlebar)
  );

  useHandleShelfSettings(
    shelfInitialized,
    boardSettings,
    isMediaBoard,
    setIsMediaBoard,
    setIsFreeBoard,
    setUpdating,
    shelfMixingFaucetDevice,
    mixingFaucetDevice,
    usedMixingFaucet,
    UPDATE_TIMEOUT
  );

  useShelfSelection(didMount, shelfOptions, setUpdating, selectedShelf, boardSettings, setBoardSettings, boardDevices, shelfInitialized);

  const getRGB = (ral: number) => {
    const { r, g, b } = ralColorsFlat.find(c => c.RAL === ral).rgb;
    return `rgb(${r}, ${g}, ${b})`;
  };

  // Fetch options for Handrails and Borders
  useEffect(() => {
    const borderKey = `glbl-brdr-dvcs`;
    const handrailKey = `glbl-hndrl-dvcs`;
    const getHandrails = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'HandRail'
          }
        }
      });
      if (data) {
        setHandrails(data);
        setHandrailNames([...data.map((d: Device) => d.translations)]);
        sessionStorage.setItem(handrailKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const getBorders = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'Border'
          }
        }
      });
      if (data) {
        setBorders(data);
        setBorderNames([...data.map((d: Device) => d.translations)]);
        sessionStorage.setItem(borderKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedBorders = sessionStorage.getItem(borderKey);
    if (cachedBorders) {
      const parsedBorders = JSON.parse(cachedBorders);
      setBorders(parsedBorders);
      setBorderNames([...parsedBorders.map((d: Device) => d.translations)]);
    } else {
      getBorders();
    }

    const cachedHandrails = sessionStorage.getItem(handrailKey);
    if (cachedHandrails) {
      const parsedHandrails = JSON.parse(cachedHandrails);
      setHandrails(parsedHandrails);
      setHandrailNames([...parsedHandrails.map((d: Device) => d.translations)]);
    } else {
      getHandrails();
    }
    return () => {
      setShowShelfHint(false);
      setDidMount(false);
    };
  }, []);

  // Fetch Power Socket Options
  useEffect(() => {
    const powerSocketKey = `glbl-pwsckt-dvcs`;

    const getPowerSockets = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            type: 'Option',
            subtype: 'UpperPowerSocket'
          }
        }
      });
      if (data) {
        setPowerSocketDevices(data);
        setPowerSocketTranslations([...data.map((d: Device) => d.translations)]);
        const amounts = [...powerSocketAmounts];
        data.forEach((d: Device, i: number) => {
          amounts.splice(i, 1, selectedMasterline.getEquipmentCount(d));
        });
        setPowerSocketAmounts(amounts);
        sessionStorage.setItem(powerSocketKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };

    const cachedPowerSockets = sessionStorage.getItem(powerSocketKey);
    if (cachedPowerSockets) {
      const parsedPowerSockets = JSON.parse(cachedPowerSockets);
      setPowerSocketDevices(parsedPowerSockets);
      setPowerSocketTranslations([...parsedPowerSockets.map((d: Device) => d.translations)]);
      const amounts = [...powerSocketAmounts];
      parsedPowerSockets.forEach((d: Device, i: number) => {
        amounts.splice(i, 1, selectedMasterline?.getEquipmentCount(d));
      });
      setPowerSocketAmounts(amounts);
    } else {
      getPowerSockets();
    }

    const sideBoardKey = 'sdbrd-dvc';

    const getSideBoardDevices = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            style: 'SideBoard'
          }
        }
      });
      if (data && Array.isArray(data) && data.length > 0) {
        setSideBoardDevices(data);
        sessionStorage.setItem(sideBoardKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedSideBoard = sessionStorage.getItem(sideBoardKey);

    if (cachedSideBoard) {
      const parsedShelfs = JSON.parse(cachedSideBoard);
      if (Array.isArray(parsedShelfs)) setSideBoardDevices(parsedShelfs);
      else getSideBoardDevices();
    } else {
      getSideBoardDevices();
    }
  }, []);

  useEffect(() => {
    if (didMount) {
      setUpdating(true);
      setSettings({
        panel: true,
        colorSB: false,
        /* shelf: selectedMasterline ? !!selectedMasterline.getBoard() : false, */
        sideCover: selectedMasterline ? selectedMasterline.isSideCover() : false,
        steelKnob: selectedMasterline ? selectedMasterline.isSteelKnob() : false
      });
      setCutsX(selectedMasterline.getCutX());
      setCutsY(selectedMasterline.getCutY());

      const leftSideBoard = selectedMasterline.getSideBoardLeftObject();
      setSideBoardLeft(leftSideBoard ? leftSideBoard.translations[langId].name : Localizations['none'][lang]);
      //@ts-ignore
      setSideBoardLeftWidth(selectedMasterline.getSideBoardLeftWidth() ? selectedMasterline.getSideBoardLeftWidth().toString() : '300');

      const rightSideBoard = selectedMasterline.getSideBoardRightObject();
      setSideBoardRight(rightSideBoard ? rightSideBoard.translations[langId].name : Localizations['none'][lang]);
      //@ts-ignore
      setSideBoardRightWidth(selectedMasterline.getSideBoardRightWidth() ? selectedMasterline.getSideBoardRightWidth().toString() : '300');

      setColor(selectedMasterline.getBlendColor());
      setDoorColor(selectedMasterline.getDoorColor());
      setSelectedShelf(
        selectedMasterline?.getBoard()?.getDeviceObject()?.translations
          ? selectedMasterline.getBoard().getDeviceObject().translations[langId]?.name
          : selectedMasterline?.getBoard()?.isSalamanderLeft() || selectedMasterline?.getBoard()?.isSalamanderRight()
          ? Localizations['salamanderBoard'][lang]
          : Localizations['noShelf'][lang]
      );

      const leftBorder = selectedMasterline.getBorderLeftObject();
      setSelectedBorderLeft(leftBorder?.translations[langId]?.description || standardBorder[lang]);
      const rightBorder = selectedMasterline.getBorderRightObject();
      setSelectedBorderRight(rightBorder?.translations[langId]?.description || standardBorder[lang]);

      // Griffstange
      setSelectedHandlebar(selectedMasterline.getHandle() ? selectedMasterline.getHandle().translations[langId].name : Localizations['none'][lang]);

      // Socket
      if (selectedMasterline && selectedMasterline.getBottom()) {
        const bottom = selectedMasterline.getBottom();
        const bottomHeight = selectedMasterline.getBottomHeight();
        if (bottomHeight === 20) {
          if (bottom === 'BaseMKN') {
            setSocketStyle(Localizations['mknSocket200'][lang]);
          } else if (bottom === 'Base') {
            setSocketStyle(Localizations['buildSideSocket200'][lang]);
          } else if (bottom === 'Feet') {
            setSocketStyle(Localizations['installedFeet200'][lang]);
          }
        } else {
          if (bottom === 'BaseMKN') {
            setSocketStyle(Localizations['mknSocket'][lang]);
          } else if (bottom === 'Base') {
            setSocketStyle(Localizations['buildSideSocket'][lang]);
          } else if (bottom === 'Feet') {
            setSocketStyle(Localizations['installedFeet'][lang]);
          }
        }
      }

      // Steckdosen
      const powerSocketAmounts = powerSocketDevices?.map(d => {
        return selectedMasterline.getEquipmentCount(d);
      });

      setPowerSocketAmounts(powerSocketAmounts);
      setTimeout(() => {
        setUpdating(false);
      }, UPDATE_TIMEOUT);
    }
  }, [selectedMasterline]);

  // Set Handlebar Selectboxes initial value to value of current config
  useEffect(() => {
    if (didMount) {
      setUpdating(true);
      if (selectedHandlebar && selectedHandlebar !== Localizations['none'][lang]) {
        const current = selectedMasterline.getHandle();

        if (!current || (current && current.translations[langId].name !== selectedHandlebar)) {
          const newBar = handrails.find((hR: Device) => hR.translations[langId].name === selectedHandlebar);
          selectedMasterline.setHandle(newBar);
        }
      } else if (selectedHandlebar !== '') {
        if (selectedMasterline.getHandle() !== null) {
          selectedMasterline.setHandle(null);
        }
      }
      setUpdating(false);
    }
  }, [selectedHandlebar, handrails]);

  // Set Border Selectboxes initial value to value of current config
  // RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT RIGHT
  useEffect(() => {
    if (didMount) {
      setUpdating(true);
      /* if (selectedBorderRight && selectedBorderRight !== standardBorder[lang]) { */

      const current = selectedMasterline.getBorderRightObject();

      if (!current || (current && current.translations[langId].description !== selectedBorderRight)) {
        const newBorder = borders.find((brdr: Device) => brdr.translations[langId].description === selectedBorderRight);
        if (newBorder) {
          selectedMasterline.setBorderRight(newBorder);
        }
      }
      setUpdating(false);
    }
  }, [selectedBorderRight, borders]);

  // Set Border Selectboxes initial value to value of current config
  // LEFT LEFT LEFT LEFT LEFT LEFT LEFT
  useEffect(() => {
    if (didMount) {
      setUpdating(true);

      const current = selectedMasterline.getBorderLeftObject();
      if (!current || (current && current.translations[langId].description !== selectedBorderLeft)) {
        const newBorder = borders.find((brdr: Device) => brdr.translations[langId].description === selectedBorderLeft);
        if (newBorder) {
          selectedMasterline.setBorderLeft(newBorder);
        }
      }
      setUpdating(false);
    }
  }, [selectedBorderLeft, borders]);

  useEffect(() => {
    if (didMount) {
      selectedMasterline.setCutX(Number(cutsX));
    }
  }, [cutsX]);

  useEffect(() => {
    if (didMount) {
      selectedMasterline.setCutY(Number(cutsY));
    }
  }, [cutsY]);

  useEffect(() => {
    if (didMount && sideBoardDevices) {
      setUpdating(true);

      if (sideBoardLeft !== Localizations['none'][lang]) {
        const device = findDeviceFromList(sideBoardDevices, sideBoardLeft, 'translation', langId);
        selectedMasterline.setSideBoardLeft(device);
      } else {
        selectedMasterline.setSideBoardLeft(null);
      }
      setUpdating(false);
    }
  }, [sideBoardLeft, sideBoardDevices]);

  useEffect(() => {
    if (didMount) {
      selectedMasterline.setSideBoardLeftWidth(Number(sideBoardLeftWidth));
    }
  }, [sideBoardLeftWidth]);

  useEffect(() => {
    if (didMount && sideBoardDevices) {
      setUpdating(true);

      if (sideBoardRight !== Localizations['none'][lang]) {
        const device = findDeviceFromList(sideBoardDevices, sideBoardRight, 'translation', langId);
        selectedMasterline.setSideBoardRight(device);
      } else {
        selectedMasterline.setSideBoardRight(null);
      }
      setUpdating(false);
    }
  }, [sideBoardRight, sideBoardDevices]);

  useEffect(() => {
    if (didMount) {
      selectedMasterline.setSideBoardRightWidth(Number(sideBoardRightWidth));
    }
  }, [sideBoardRightWidth]);

  useEffect(() => {
    /* DEVICES HOLEN */
    // Mixing Faucet
    const mixingFaucetKey = `slctdbrd-mxng-fct`;
    const getMixingFaucet = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'MixingFaucet'
          }
        }
      });
      if (Array.isArray(data)) {
        setMixingFaucetDevice(data[0]);
        if (data[0]) {
          sessionStorage.setItem(mixingFaucetKey, JSON.stringify(data[0]));
        }
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedMixingFaucet = sessionStorage.getItem(mixingFaucetKey);
    console.log(cachedMixingFaucet);
    if (cachedMixingFaucet) {
      setMixingFaucetDevice(JSON.parse(cachedMixingFaucet));
    } else {
      getMixingFaucet();
    }

    // Shelf Mixing Faucet
    const shelfMixingFaucetKey = `slctdbrd-shlf-mxng-fct`;
    const getShelfMixingFaucet = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'ShelfMixingFaucet'
          }
        }
      });
      if (Array.isArray(data)) {
        setShelfMixingFaucetDevice(data[0]);
        if (data[0]) {
          sessionStorage.setItem(shelfMixingFaucetKey, JSON.stringify(data[0]));
        }
      }
      if (error) {
        console.log(error);
      }
    };
    const cachedShelfMixingFaucet = sessionStorage.getItem(shelfMixingFaucetKey);
    if (cachedShelfMixingFaucet) {
      setShelfMixingFaucetDevice(JSON.parse(cachedShelfMixingFaucet));
    } else {
      getShelfMixingFaucet();
    }
  }, []);

  useEffect(() => {
    if (didMount) {
      const bottomHeight = selectedMasterline.getBottomHeight();
      if (socketStyle === Localizations['mknSocket'][lang]) {
        if (bottomHeight === 20) selectedMasterline.setBottomHeight(15);
        if (!(selectedMasterline.getBottom() === 'BaseMKN')) selectedMasterline.setBottom('BaseMKN');
      } else if (socketStyle === Localizations['buildSideSocket'][lang]) {
        if (bottomHeight === 20) selectedMasterline.setBottomHeight(15);
        if (!(selectedMasterline.getBottom() === 'Base')) selectedMasterline.setBottom('Base');
      } else if (socketStyle === Localizations['installedFeet'][lang]) {
        if (bottomHeight === 20) selectedMasterline.setBottomHeight(15);
        if (!(selectedMasterline.getBottom() === 'Feet')) selectedMasterline.setBottom('Feet');
      } else if (socketStyle === Localizations['buildSideSocket200'][lang]) {
        if (bottomHeight === 15) selectedMasterline.setBottomHeight(20);
        if (!(selectedMasterline.getBottom() === 'Base')) selectedMasterline.setBottom('Base');
      } else if (socketStyle === Localizations['installedFeet200'][lang]) {
        if (bottomHeight === 15) selectedMasterline.setBottomHeight(20);
        if (!(selectedMasterline.getBottom() === 'Feet')) selectedMasterline.setBottom('Feet');
      } else if (socketStyle === Localizations['mknSocket200'][lang]) {
        if (bottomHeight === 15) selectedMasterline.setBottomHeight(20);
        if (!(selectedMasterline.getBottom() === 'BaseMKN')) selectedMasterline.setBottom('BaseMKN');
      }
    }
  }, [socketStyle]);

  // Handle Door Color Updates
  useEffect(() => {
    if (didMount) {
      const updateDoorColor = () => {
        if (doorColor !== selectedMasterline.getDoorColor()) {
          selectedMasterline.setDoorColor(doorColor);
          if (doorColor) {
            setCustomDoorRal(doorColor.toString());
          } else {
            setCustomDoorRal('');
          }
        }
      };
      updateDoorColor();
    }
  }, [doorColor]);

  // Fetch Board Devices
  useEffect(() => {
    const shelfKey = 'glbl-shlf-dvc';

    const getBoardDevices = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/editor/menu/device`, {
        data: {
          type: 'masterline',
          device: {
            subtype: 'Shelf',
            style: 'Tray%'
          }
        }
      });
      if (data) {
        setBoardDevices(data);
        setShelfOptions([Localizations['noShelf'][lang], Localizations['salamanderBoard'][lang], ...data.map((d: Device) => d.translations[langId].name)]);
        sessionStorage.setItem(shelfKey, JSON.stringify(data));
      }
      if (error) {
        console.log(error);
      }
    };
    if (!didMount) {
      setSelectedHandlebar(selectedMasterline?.getHandle() ? selectedMasterline.getHandle().translations[langId].name : Localizations['none'][lang]);
      // Get the right one
      setSelectedBorderRight(
        selectedMasterline?.getBorderRightObject() ? selectedMasterline.getBorderRightObject().translations[langId].description : standardBorder[lang]
      );
      // Get the left one
      setSelectedBorderLeft(
        selectedMasterline?.getBorderLeftObject() ? selectedMasterline.getBorderLeftObject().translations[langId].description : standardBorder[lang]
      );

      setSettings({
        ...settings,
        /* shelf: !!selectedMasterline.getBoard(), */
        sideCover: selectedMasterline?.isSideCover(),
        steelKnob: selectedMasterline?.isSteelKnob()
      });
      if (selectedMasterline) {
        const leftSideBoard = selectedMasterline.getSideBoardLeftObject();
        setSideBoardLeft(leftSideBoard ? leftSideBoard.translations[langId].name : Localizations['none'][lang]);
        //@ts-ignore
        setSideBoardLeftWidth(selectedMasterline.getSideBoardLeftWidth() ? selectedMasterline.getSideBoardLeftWidth() : '300');
        const rightSideBoard = selectedMasterline.getSideBoardRightObject();
        setSideBoardRight(rightSideBoard ? rightSideBoard.translations[langId].name : Localizations['none'][lang]);
        //@ts-ignore
        setSideBoardRightWidth(selectedMasterline.getSideBoardRightWidth() ? selectedMasterline.getSideBoardRightWidth().toString() : '300');
      }
      if (selectedMasterline && selectedMasterline.getBottom()) {
        const bottom = selectedMasterline.getBottom();
        const bottomHeight = selectedMasterline.getBottomHeight();
        if (bottomHeight === 20) {
          if (bottom === 'BaseMKN') {
            setSocketStyle(Localizations['mknSocket200'][lang]);
          } else if (bottom === 'Base') {
            setSocketStyle(Localizations['buildSideSocket200'][lang]);
          } else if (bottom === 'Feet') {
            setSocketStyle(Localizations['installedFeet200'][lang]);
          }
        } else {
          if (bottom === 'BaseMKN') {
            setSocketStyle(Localizations['mknSocket'][lang]);
          } else if (bottom === 'Base') {
            setSocketStyle(Localizations['buildSideSocket'][lang]);
          } else if (bottom === 'Feet') {
            setSocketStyle(Localizations['installedFeet'][lang]);
          }
        }
      }
      setDidMount(true);

      const cachedShelfs = sessionStorage.getItem(shelfKey);
      if (cachedShelfs) {
        const parsedShelfs = JSON.parse(cachedShelfs);
        setBoardDevices(parsedShelfs);
        setShelfOptions([
          Localizations['noShelf'][lang],
          Localizations['salamanderBoard'][lang],
          ...parsedShelfs.map((d: Device) => d.translations[langId].name)
        ]);
      } else {
        getBoardDevices();
      }
      if (selectedMasterline && selectedMasterline.getBlendColorRGB()) {
        const { r, g, b } = selectedMasterline.getBlendColorRGB();
        const isDefault = r === 155 && g === 155 && b === 160;
        setShowColors(!isDefault);
      }
      if (selectedMasterline && selectedMasterline.getDoorColorRGB()) {
        const { r, g, b } = selectedMasterline.getDoorColorRGB();
        const isDefault = r === 155 && g === 155 && b === 160;
        setShowDoorColors(!isDefault);
      }
    }
  }, []);

  // Handle custom Ral input
  useEffect(() => {
    if (customRal.length === 4) {
      setColor(Number(customRal));
    }
  }, [customRal]);

  // Handle custom door Ral input
  useEffect(() => {
    if (customDoorRal.length === 4) {
      setDoorColor(Number(customDoorRal));
    }
  }, [customDoorRal]);

  // Handle Color Updates
  useEffect(() => {
    if (didMount) {
      const updateColor = () => {
        if (color !== selectedMasterline.getBlendColor()) {
          selectedMasterline.setBlendColor(color);

          if (color) {
            setCustomRal(color.toString());
          } else {
            setCustomRal('');
          }
        }
      };
      updateColor();
    }
  }, [color]);

  // Shelf Hint
  if (showShelfHint) {
    return (
      <div>
        <ErrorMessage
          goBack={() => {
            setShowShelfHint(false);
            scene.setSelected(selectedMasterline.getBoard());
            setSelection(selectedMasterline.getBoard());
          }}
          title={Localizations['shelf'][lang]}
          message={Localizations['shelfHint'][lang]}
        />
      </div>
    );
  }

  const updatePowerSockets = (amount: number, i: number) => {
    if (i < powerSocketAmounts.length) {
      const newPowerSocketAmounts = [...powerSocketAmounts];
      newPowerSocketAmounts.splice(i, 1, amount);
      setPowerSocketAmounts(newPowerSocketAmounts);
    }

    if (i < powerSocketDevices.length) {
      selectedMasterline.setEquipmentCount(powerSocketDevices[i], amount);
    }
  };

  return (
    <Fragment>
      <ContextMenu mode="back" noMargin onClick={() => setMasterlineView('home')}>
        {selectedCategory && (
          <div className="ml-1">
            <div className="Submenu-Headline">
              <span>{selectedCategory.id}</span>
            </div>
          </div>
        )}
      </ContextMenu>
      <div className="mt-2">
        <BlockSelector />
      </div>
      <div className="Functional-View-Switch-Area Custom-Scroll" style={{ maxHeight: 'calc(100% - 80px)', overflowY: 'auto' }}>
        <EquipmentSwitch
          disabled={updating || (selectedMasterline?.getType() === 'Single' && selectedMasterline?.getSingleType() === 'Wall')}
          label={Localizations['sideCover'][lang]}
          status={settings.sideCover}
          toggleStatus={() => {
            setUpdating(true);
            setSettings({ ...settings, sideCover: !settings.sideCover });
            selectedMasterline.setSideCover(!settings.sideCover);
            setTimeout(() => {
              setUpdating(false);
            }, UPDATE_TIMEOUT);
          }}
          type="first"
        />
        <EquipmentSwitch
          label={Localizations['steelKnob'][lang]}
          disabled={updating}
          status={settings.steelKnob}
          toggleStatus={() => {
            setUpdating(true);

            setSettings({ ...settings, steelKnob: !settings.steelKnob });
            selectedMasterline.setSteelKnob(!settings.steelKnob);
            setTimeout(() => {
              setUpdating(false);
            }, UPDATE_TIMEOUT);
          }}
          type="first"
        />
        <div className="mb-2">
          <h5 className="mb-1">{Localizations['handlebar'][lang]}</h5>
          <Selectbox
            state={selectedHandlebar}
            setState={setSelectedHandlebar}
            options={
              handrailNames && handrailNames.length > 0 && handrailNames[0] !== ''
                ? [...handrailNames.map(hR => hR[langId]['name']), Localizations['none'][lang]]
                : ['']
            }
          />
        </div>
        {!IS_WALL && selectedHandlebar !== Localizations['none'][lang] && (
          <div className="mb-2">
            <h5 className="mb-1">{Localizations['handlebarPosition'][lang]}</h5>
            <Selectbox
              state={handleTypeOptionsLabels.find(ol => ol.value === handleType)?.label}
              setState={v => {
                setHandleType(handleTypeOptionsLabels.find(ol => ol.label === v)?.value);
                const isUmlaufend = v === Localizations['Full'][lang];
                if (isUmlaufend) {
                  const roundedBorderDevice = borders.find(b => b.style === 'BorderRoundRound');
                  setSelectedBorderLeft(roundedBorderDevice.translations[langId].description);
                  selectedMasterline.setBorderLeft(roundedBorderDevice);
                  setSelectedBorderRight(roundedBorderDevice.translations[langId].description);
                  selectedMasterline.setBorderRight(roundedBorderDevice);
                }
              }}
              options={handleTypeOptionsLabels.map(ol => ol?.label)}
            />
          </div>
        )}

        <div className="mb-2">
          <h5 className="mb-1">{`${Localizations['middleOptions'][lang]}`}</h5>
          <Selectbox
            disabled={updating || !selectedMasterline?.canBoardBeEnabled()}
            state={selectedShelf}
            setState={setSelectedShelf}
            options={selectedMasterline?.getWidth() < 1000 ? [Localizations['noShelf'][lang], Localizations['salamanderBoard'][lang]] : shelfOptions}
          />
        </div>

        <>
          {selectedShelf !== Localizations['noShelf'][lang] && (
            <>
              <div className="Submenu-Attribute">
                <label className="Submenu-Label">
                  <input
                    className="Submenu-Input"
                    disabled={updating}
                    onBlur={e => {
                      const board = selectedMasterline.getBoard();
                      const marginRight = board.getMarginRight();
                      setBoardSettings({ ...boardSettings, marginRight });
                      checkMarginInput(parseInt(e.target.value), marginRight, setErrorAlert);
                    }}
                    onChange={event => {
                      if (selectedMasterline.getBoard()) {
                        const newMargin = parseInt(event.target.value);
                        const shouldUpdateState = selectedMasterline.getBoard().setMarginRight(newMargin);
                        if (shouldUpdateState) {
                          setBoardSettings({
                            ...boardSettings,
                            marginRight: newMargin,
                            marginMixingFaucetRight:
                              boardSettings.marginMixingFaucetRight <= boardSettings.marginRight ? newMargin : boardSettings.marginMixingFaucetRight,
                            marginLeft: selectedMasterline.getBoard().getMarginLeft()
                          });
                        }
                      }
                    }}
                    min={0}
                    step={100}
                    type="number"
                    value={boardSettings.marginRight}
                  />
                  {`${Localizations['edgeDistance'][lang]} ${Localizations['right'][lang]} (mm)`}
                </label>
              </div>
              <div className="Submenu-Attribute">
                <label className="Submenu-Label">
                  <input
                    className="Submenu-Input"
                    disabled={updating}
                    onBlur={e => {
                      const board = selectedMasterline.getBoard();
                      const marginLeft = board.getMarginLeft();
                      setBoardSettings({ ...boardSettings, marginLeft });
                      checkMarginInput(parseInt(e.target.value), marginLeft, setErrorAlert);
                    }}
                    onChange={event => {
                      if (selectedMasterline.getBoard()) {
                        const newMargin = parseInt(event.target.value);

                        const shouldUpdateState = selectedMasterline.getBoard().setMarginLeft(newMargin);
                        if (shouldUpdateState) {
                          setBoardSettings({
                            ...boardSettings,
                            marginLeft: newMargin,
                            marginMixingFaucetLeft:
                              boardSettings.marginMixingFaucetLeft <= boardSettings.marginLeft ? newMargin : boardSettings.marginMixingFaucetLeft,
                            marginRight: selectedMasterline.getBoard().getMarginRight()
                          });
                        }
                      }
                    }}
                    min={0}
                    step={100}
                    type="number"
                    value={boardSettings.marginLeft}
                  />
                  {`${Localizations['edgeDistance'][lang]} ${Localizations['left'][lang]} (mm)`}
                </label>
              </div>

              <div>
                <EquipmentSwitch
                  label={`Salamander ${Localizations['right'][lang]}`}
                  status={boardSettings.salamanderRight}
                  disabled={updating}
                  toggleStatus={() => {
                    if (boardSettings.salamanderLeft) {
                      setBoardSettings({ ...boardSettings, salamanderRight: !boardSettings.salamanderRight, salamanderLeft: false });
                    } else {
                      setBoardSettings({ ...boardSettings, salamanderRight: !boardSettings.salamanderRight });
                    }
                    if (!boardSettings.salamanderRight) setErrorAlert(['salamander']);
                  }}
                  type="first"
                />
              </div>
              <div>
                <EquipmentSwitch
                  label={`Salamander ${Localizations['left'][lang]}`}
                  status={boardSettings.salamanderLeft}
                  disabled={updating || !selectedMasterline || !(selectedMasterline.getType() === 'Double')}
                  toggleStatus={() => {
                    setUpdating(true);
                    if (boardSettings.salamanderRight) {
                      setBoardSettings({ ...boardSettings, salamanderLeft: !boardSettings.salamanderLeft, salamanderRight: false });
                    } else {
                      setBoardSettings({ ...boardSettings, salamanderLeft: !boardSettings.salamanderLeft });
                    }
                    if (!boardSettings.salamanderLeft) setErrorAlert(['salamander']);
                  }}
                  type="first"
                />
              </div>
            </>
          )}
          {(isMediaBoard || isFreeBoard || selectedShelf === Localizations['noShelf'][lang]) && (
            <>
              <div>
                <EquipmentSwitch
                  disabled={updating || !(selectedMasterline?.getType() === 'Double')}
                  label={`${isMediaBoard ? Localizations['mixingFaucetMedia'][lang] : Localizations['middleMixingFaucet'][lang]} ${
                    Localizations['right'][lang]
                  }`}
                  status={boardSettings.mixingFaucetRight.length > 0}
                  toggleStatus={() => {
                    setUpdating(true);
                    if (selectedShelf === Localizations['noShelf'][lang]) {
                      if (boardSettings.mixingFaucetRight.length === 0) {
                        selectedMasterline.enableBoard(true, null);
                      } else {
                        if (boardSettings.mixingFaucetLeft.length < 1) {
                          selectedMasterline.enableBoard(false, null);
                        }
                      }
                    }
                    setBoardSettings({
                      ...boardSettings,
                      mixingFaucetRight: boardSettings.mixingFaucetRight.length > 0 ? [] : [isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice],
                      marginMixingFaucetRight: boardSettings.marginRight
                    });
                  }}
                  type="first"
                />
              </div>
              <div className="Submenu-Attribute mt-2">
                <label className={`Submenu-Label ${!boardSettings.mixingFaucetRight || boardSettings.mixingFaucetRight.length < 1 ? 'disabled' : ''}`}>
                  <input
                    className="Submenu-Input"
                    disabled={updating || !boardSettings.mixingFaucetRight || boardSettings.mixingFaucetRight.length < 1}
                    onBlur={e => {
                      const board = selectedMasterline.getBoard();
                      const rightDevice = board.getRight();
                      if (Array.isArray(rightDevice) && rightDevice[0]) {
                        const marginMixingFaucetRight = rightDevice[0].getMargin();
                        setBoardSettings({ ...boardSettings, marginMixingFaucetRight });
                        checkMarginInput(parseInt(e.target.value), rightDevice[0].getMargin(), setErrorAlert);
                      }
                    }}
                    onChange={event => {
                      setBoardSettings({ ...boardSettings, marginMixingFaucetRight: parseInt(event.target.value) });
                    }}
                    min={boardSettings.marginRight}
                    max={
                      selectedMasterline && selectedMasterline.getBoard()?.getWidth()
                        ? selectedMasterline.getBoard()?.getWidth() + boardSettings.marginRight - usedMixingFaucet?.model?.width
                        : 99999
                    }
                    step={100}
                    type="number"
                    value={boardSettings.marginMixingFaucetRight}
                  />
                  {`${Localizations['edgeDistance'][lang]} ${Localizations['faucet'][lang]} R. (mm)`}
                </label>
              </div>

              <div>
                <EquipmentSwitch
                  disabled={updating || !(selectedMasterline?.getType() === 'Double')}
                  label={`${isMediaBoard ? Localizations['mixingFaucetMedia'][lang] : Localizations['middleMixingFaucet'][lang]} ${
                    Localizations['left'][lang]
                  }`}
                  status={boardSettings.mixingFaucetLeft.length > 0}
                  toggleStatus={() => {
                    setUpdating(true);
                    if (selectedShelf === Localizations['noShelf'][lang]) {
                      if (boardSettings.mixingFaucetLeft.length === 0) {
                        selectedMasterline.enableBoard(true, null);
                      } else {
                        if (boardSettings.mixingFaucetRight.length < 1) {
                          selectedMasterline.enableBoard(false, null);
                        }
                      }
                    }
                    setBoardSettings({
                      ...boardSettings,
                      mixingFaucetLeft: boardSettings.mixingFaucetLeft.length > 0 ? [] : [isMediaBoard ? shelfMixingFaucetDevice : mixingFaucetDevice],
                      marginMixingFaucetLeft: boardSettings.marginLeft
                    });
                  }}
                  type="first"
                />
              </div>
              <div className="Submenu-Attribute mt-2">
                <label className={`Submenu-Label ${!boardSettings.mixingFaucetLeft || boardSettings.mixingFaucetLeft.length < 1 ? 'disabled' : ''}`}>
                  <input
                    className={`Submenu-Input`}
                    disabled={updating || !boardSettings.mixingFaucetLeft || boardSettings.mixingFaucetLeft.length < 1}
                    onBlur={e => {
                      const board = selectedMasterline.getBoard();
                      const leftDevice = board.getLeft();
                      if (Array.isArray(leftDevice) && leftDevice[0]) {
                        const marginMixingFaucetLeft = leftDevice[0].getMargin();
                        setBoardSettings({ ...boardSettings, marginMixingFaucetLeft });
                        checkMarginInput(parseInt(e.target.value), leftDevice[0].getMargin(), setErrorAlert);
                      }
                    }}
                    onChange={event => {
                      setBoardSettings({ ...boardSettings, marginMixingFaucetLeft: parseInt(event.target.value) });
                    }}
                    min={boardSettings.marginLeft}
                    max={
                      selectedMasterline && selectedMasterline.getBoard()
                        ? selectedMasterline.getBoard()?.getWidth() + boardSettings.marginLeft - usedMixingFaucet?.model?.width
                        : null
                    }
                    step={100}
                    type="number"
                    value={boardSettings.marginMixingFaucetLeft}
                  />
                  {`${Localizations['edgeDistance'][lang]} ${Localizations['faucet'][lang]} L. (mm)`}
                </label>
              </div>
            </>
          )}
        </>

        <div className="mb-2">
          <h5 className="mb-1">{`${Localizations['border'][lang]} (${Localizations['right'][lang]})`}</h5>
          <Selectbox
            disabled={updating || sideBoardRight !== Localizations['none'][lang]}
            state={selectedBorderRight}
            setState={setSelectedBorderRight}
            options={
              borderNames && borderNames.length > 0 && borderNames[0] !== ''
                ? [...borderNames.map(bN => bN[langId]['description']) /* , Localizations['none'][lang] */]
                : ['']
            }
          />
        </div>

        <div className="mb-2">
          <h5 className="mb-1">{`${Localizations['border'][lang]} (${Localizations['left'][lang]})`}</h5>
          <Selectbox
            disabled={updating || sideBoardLeft !== Localizations['none'][lang]}
            state={selectedBorderLeft}
            setState={val => {
              setSelectedBorderLeft(val);
            }}
            options={
              borderNames && borderNames.length > 0 && borderNames[0] !== ''
                ? [...borderNames.map(bN => bN[langId]['description']) /* , Localizations['none'][lang] */]
                : ['']
            }
          />
        </div>

        <div className="mb-2">
          <h5 className="mb-1">{Localizations['pedestal'][lang]}</h5>
          <Selectbox state={socketStyle} setState={setSocketStyle} options={socketOptions} />
        </div>

        <div className="mb-2">
          <h5 className="mb-1">
            {Localizations['blockCut'][lang] + ' X'}{' '}
            <Button btnType="link" padding={'0 .25rem'} onClick={() => setErrorAlert(['cuts'])} width="auto">
              <img src={Info} style={{ width: '12px', height: '12px' }} />
            </Button>
          </h5>
          <Selectbox state={cutsX} setState={setCutsX} options={[0, 1]} />
        </div>

        <div className="mb-2">
          <h5 className="mb-1">
            {Localizations['blockCut'][lang] + ' Y'}
            <Button btnType="link" padding={'0 .25rem'} onClick={() => setErrorAlert(['cuts'])} width="auto">
              <img src={Info} style={{ width: '12px', height: '12px' }} />
            </Button>
          </h5>
          <Selectbox state={cutsY} setState={setCutsY} options={[0, 1, 2]} />
        </div>
        <div className="bold mt-2 text-sm">
          <EquipmentSwitch
            noBorder={showColors}
            label={Localizations['colorSB'][lang]}
            status={showColors}
            toggleStatus={() => {
              if (showColors) {
                setColor(0);
              }
              setShowColors(!showColors);
            }}
            type="first"
          />
          {showColors && (
            <>
              <SwatchesPicker
                color={ralColorsFlat.find(c => c.RAL === color)?.rgb}
                colors={[...hexFromRal /* , [defaultMetallic] */]}
                onChangeComplete={(color: ColorResult) => {
                  const newColor = ralColorsFlat.find(c => c.rgb.r === color.rgb.r && c.rgb.b === color.rgb.b && color.rgb.g === c.rgb.g);
                  setColor(newColor.RAL);
                }}
                height={125}
                width={264}
              />
              {/* <Button btnType={'second'} onClick={() => setColor({ r: 155, g: 155, b: 160 })} padding="8px" fontSize={'.75rem'} margin=".5rem 0 0 0">
                {Localizations['removeColor'][lang]}
              </Button> */}
              <div className="mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  style={{
                    background: color ? getRGB(color) : defaultMetallic,
                    borderRadius: '2px',
                    flexShrink: 0,
                    height: '1.5rem',
                    width: '1.5rem'
                  }}
                ></div>
                <Input
                  margin="0 0 0 .5rem"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    event.target.value.length <= 4 && event.target.value.length >= 0 && setCustomRal(event.target.value)
                  }
                  placeholder="RAL Code"
                  type="number"
                  value={customRal}
                />
              </div>
            </>
          )}
        </div>
        <div className="bold mt-2 text-sm">
          <EquipmentSwitch
            noBorder={showDoorColors}
            label={Localizations['doorColor'][lang]}
            status={showDoorColors}
            toggleStatus={() => {
              if (showDoorColors) {
                setDoorColor(null);
              }
              setShowDoorColors(!showDoorColors);
            }}
            type="first"
          />
          {showDoorColors && (
            <>
              <SwatchesPicker
                color={ralColorsFlat.find(c => c.RAL === doorColor)?.rgb}
                colors={[...hexFromRal]}
                onChangeComplete={(color: ColorResult) => {
                  const newColor = ralColorsFlat.find(c => c.rgb.r === color.rgb.r && c.rgb.b === color.rgb.b && color.rgb.g === c.rgb.g);
                  setDoorColor(newColor.RAL);
                }}
                height={125}
                width={264}
              />
              <div className="mt-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div
                  style={{
                    background: doorColor ? getRGB(doorColor) : defaultMetallic,
                    borderRadius: '2px',
                    flexShrink: 0,
                    height: '1.5rem',
                    width: '1.5rem'
                  }}
                ></div>
                <Input
                  margin="0 0 0 .5rem"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    event.target.value.length <= 4 && event.target.value.length >= 0 && setCustomDoorRal(event.target.value)
                  }
                  placeholder="RAL Code"
                  type="number"
                  value={customDoorRal}
                />
              </div>
            </>
          )}
          <div className="Submenu-Headline mt-4">
            {Localizations['sockets'][lang]}{' '}
            <Button btnType="link" padding={'0 .25rem'} onClick={() => setErrorAlert(['powerSocket'])} width="auto">
              <img src={Info} style={{ width: '12px', height: '12px' }} />
            </Button>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label flex">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    updatePowerSockets(val, 0);
                    // Update Block
                  }
                }}
                type="number"
                value={powerSocketAmounts[0] || 0}
              />
              <div style={{ width: '60%' }}>
                <div>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[0] && powerSocketTranslations[0][langId]
                    ? `${powerSocketTranslations[0][langId].name}`
                    : 'PowerSocket 1'}
                </div>
                <div style={{ fontWeight: 'normal' }}>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[0] && powerSocketTranslations[0][langId]
                    ? powerSocketTranslations[0][langId].description
                    : ''}
                </div>
              </div>
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label flex">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    updatePowerSockets(val, 1);
                    // Update Block
                  }
                }}
                type="number"
                value={powerSocketAmounts[1] || 0}
              />
              <div style={{ width: '60%' }}>
                <div>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[1] && powerSocketTranslations[1][langId]
                    ? `${powerSocketTranslations[1][langId].name}`
                    : 'PowerSocket 2'}
                </div>
                <div style={{ fontWeight: 'normal' }}>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[1] && powerSocketTranslations[1][langId]
                    ? powerSocketTranslations[1][langId].description
                    : ''}
                </div>
              </div>
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label flex">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    updatePowerSockets(val, 2);
                    // Update Block
                  }
                }}
                type="number"
                value={powerSocketAmounts[2] || 0}
              />
              <div style={{ width: '60%' }}>
                <div>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[2] && powerSocketTranslations[2][langId]
                    ? `${powerSocketTranslations[2][langId].name}`
                    : 'PowerSocket 3'}
                </div>
                <div style={{ fontWeight: 'normal' }}>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[2] && powerSocketTranslations[2][langId]
                    ? powerSocketTranslations[2][langId].description
                    : ''}
                </div>
              </div>
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label" style={{ display: 'flex' }}>
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    updatePowerSockets(val, 3);
                    // Update Block
                  }
                }}
                type="number"
                value={powerSocketAmounts[3] || 0}
              />
              <div style={{ width: '60%' }}>
                <div>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[3] && powerSocketTranslations[3][langId]
                    ? `${powerSocketTranslations[3][langId].name}`
                    : 'PowerSocket 4'}
                </div>
                <div style={{ fontWeight: 'normal' }}>
                  {Array.isArray(powerSocketTranslations) && powerSocketTranslations[3] && powerSocketTranslations[3][langId]
                    ? powerSocketTranslations[3][langId].description
                    : ''}
                </div>
              </div>
            </label>
            <br />
          </div>
          {(selectedMasterline?.getSingleType() === 'Free' || selectedMasterline?.getType() === 'Double') && (
            <>
              <div className="Submenu-Headline mt-4"> {Localizations['sideBoard'][lang]}</div>

              <div className="Submenu-Attribute flex-col">
                <label className="Submenu-Label">
                  {Localizations['sideBoard'][lang] + ' ' + Localizations['right'][lang]}
                  <Selectbox
                    options={[Localizations['none'][lang], ...sideBoardDevices.map(d => d.translations[langId].name)]}
                    setState={val => {
                      setSideBoardRight(val);
                      if (val !== Localizations['none'][lang]) {
                        const roundedBorderDevice = borders.find(b => b.style === 'BorderAngular_chamfer');
                        setSelectedBorderRight(roundedBorderDevice.translations[langId].description);
                        selectedMasterline.setBorderRight(roundedBorderDevice);
                      }
                    }}
                    state={sideBoardRight}
                  />
                </label>
                {sideBoardRight && sideBoardRight !== Localizations['none'][lang] && (
                  <div className="mt-2">
                    <label className="Submenu-Label">
                      {Localizations['depth'][lang]}
                      <Selectbox options={['300', '400']} setState={(val: '300' | '400') => setSideBoardRightWidth(val)} state={sideBoardRightWidth} />
                    </label>
                  </div>
                )}
              </div>
              <div className="Submenu-Attribute flex-col">
                <label className="Submenu-Label">
                  {Localizations['sideBoard'][lang] + ' ' + Localizations['left'][lang]}
                  <Selectbox
                    options={[Localizations['none'][lang], ...sideBoardDevices.map(d => d.translations[langId].name)]}
                    setState={val => {
                      setSideBoardLeft(val);
                      if (val !== Localizations['none'][lang]) {
                        const roundedBorderDevice = borders.find(b => b.style === 'BorderAngular_chamfer');
                        setSelectedBorderLeft(roundedBorderDevice.translations[langId].description);
                        selectedMasterline.setBorderLeft(roundedBorderDevice);
                      }
                    }}
                    state={sideBoardLeft}
                  />
                </label>

                {sideBoardLeft && sideBoardLeft !== Localizations['none'][lang] && (
                  <div className="mt-2">
                    <label className="Submenu-Label">
                      {Localizations['depth'][lang]}
                      <Selectbox
                        options={['300', '400']}
                        setState={(val: '300' | '400') => {
                          setSideBoardLeftWidth(val);
                        }}
                        state={sideBoardLeftWidth}
                      />
                    </label>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};
