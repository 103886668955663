import * as BABYLON from 'babylonjs';
import BasicUtils from 'components/babylon/util/BasicUtils';
import ralColorsFlat from 'utils/ralColorsFlat';

export default class RGB {
  r: number = 0;
  g: number = 0;
  b: number = 0;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  public static fromColor(color: BABYLON.Color3 | BABYLON.Color4) {
    const rgb = new RGB(0, 0, 0);
    rgb.set(color);
    return rgb;
  }

  public static fromRAL(ral: number): RGB {
    for (let i = 0; i < ralColorsFlat.length; i++) {
      const r = ralColorsFlat[i];
      if (r.RAL === ral) return new RGB(r.rgb.r, r.rgb.g, r.rgb.b);
    }
    return null;
  }

  set(v: BABYLON.Color3 | BABYLON.Color4) {
    this.r = Math.floor(v.r * 255);
    this.g = Math.floor(v.b * 255);
    this.b = Math.floor(v.b * 255);
  }

  get(): BABYLON.Color3 {
    return BasicUtils.color(this.r, this.g, this.b);
  }

  getRAL(): number {
    for (let i = 0; i < ralColorsFlat.length; i++) {
      const r = ralColorsFlat[i];
      if (r.rgb.r === this.r && r.rgb.g === this.g && r.rgb.b === this.b) return r.RAL;
    }
    return null;
  }
}
