import RoomNode from 'components/babylon/node/RoomNode';
import { RoomWallNode } from 'components/babylon/node/RoomWallNode';
import Dimensions from 'utils/Dimensions';
import Room, { InnerRoom } from './Room';

export class RoomWall extends InnerRoom {
  public clazz = 'RoomWall';

  private height: number = 1200;

  private _node: RoomWallNode;

  constructor(room: Room) {
    super(room);
    this._node = new RoomWallNode(this);

    this.setWidth(1000);
    this.setDepth(100);
    this.setHeight(Dimensions.MM(RoomNode.WALL_HEIGHT));
  }

  public getHeight() {
    return this.height;
  }

  public setHeight(height: number) {
    this.height = height;
    this._node.updateHeight();
  }

  public getNode() {
    return this._node;
  }
}
