import React, { useContext } from 'react';

import { LangContext } from 'context';
import Localizations from 'languages';
import { TextWithHeader } from 'components';

import './style.scss';

export const Imprint = () => {
  const { lang } = useContext(LangContext);

  return (
    <div className="container">
      <div className="content">
        <h1>
          {Localizations['headlineImprint'][lang]}
          {Localizations['secondHeadlineImprint'][lang] ? (
            <>
              <br />
              {Localizations['secondHeadlineImprint'][lang]}
            </>
          ) : (
            ''
          )}
        </h1>
        <p>MKN</p>
        <p>
          Maschinenfabrik Kurt Neubauer GmbH & Co. KG
          <br />
          Halberstädter Straße 2a
          <br />
          38300 Wolfenbüttel
        </p>
        <p>
          {Localizations['phone'][lang]}: +49 5331 89-0
          <br />
          {Localizations['fax'][lang]}: +49 5331 89-280
        </p>
        <p>
          E-mail: <a href="mailto:info@mkn.de">info@mkn.de</a>
          <br />
          Website:{' '}
          <a href="https://www.mkn.com" target="_blank" rel="noopener noreferrer">
            www.mkn.com
          </a>
        </p>
        <br />
        <p>{Localizations['manager'][lang]}</p>
        <p>{Localizations['personallyLiable'][lang]}</p>
        <p>{Localizations['court'][lang]}</p>
        <p>
          {Localizations['taxOffice'][lang]}
          <br />
          {Localizations['taxNumber'][lang]}
          <br />
          {Localizations['vat'][lang]}
        </p>
        <br />
        <TextWithHeader header={'contentHeader'} text={'contentContent'} />
        <TextWithHeader header={'referralsHeader'} text={'referralsContent'} />
        <h4>{Localizations['dataProtectionLinkHeader'][lang]}</h4>
        <p>
          {Localizations['dataProtectionLinkContent'][lang]}{' '}
          <a href="https://www.mkn.com/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
            {Localizations['here'][lang]}
          </a>
        </p>
        <TextWithHeader header={'copyright'} text={'copyrightContent'} />
        <TextWithHeader header={'legalValidity'} text={'legalValidityContent'} />
      </div>
    </div>
  );
};
