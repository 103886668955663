import React, { useEffect, useState } from 'react';
import './style.scss';

type Props = {
  pose: 'exit' | 'expanded' | 'enter';
};

export const AEditorMenu: React.FC<Props> = ({ children, pose }) => {
  const [mounted, setMounted] = useState(false);
  const [className, setClassName] = useState('AEditor-Menu');

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      if (pose === 'expanded') {
        setClassName('AEditor-Menu-Mounted-Expanded');
      } else if (pose === 'exit') {
        setClassName('AEditor-Menu-Exited');
      } else {
        setClassName('AEditor-Menu-Mounted');
      }
    }
  }, [mounted, pose]);
  return <div className={'Editor-Menu ' + className}>{children}</div>;
};
