import { useEffect } from 'react';
import Scene from 'page/Editor/Scene';

export const useBlockMode = (scene: Scene) => {
  useEffect(() => {
    if (scene) {
      scene.setBlockMode(true);
    }
    return () => {
      if (scene) {
        scene.setBlockMode(false);
      }
    };
  }, [scene]);
};
