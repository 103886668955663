import { ADialog, ASimple, Button, Checkbox, Heading, Icon, Input } from 'atoms';
import { LoadingSpinner } from 'components';
import { LangContext } from 'context';
import Localizations from 'languages';
import { useContext, useState } from 'react';
import { Portal } from 'react-portal';
import { Member } from 'types';
import { useEditSubuser } from '../../../../hooks';

import { useOrganizationContext } from 'page/Organization/context/OrganizationDataProvider';
import { Right } from 'types/Member';
import { getLabelForRight } from 'utils';
import { useSendUserPassword } from '../../../../hooks/useSendUserPassword';
import './style.scss';

type Props = {
  abort: () => void;
  user: Member | null;
};

export const EditDialog: React.FC<Props> = ({ abort, user }) => {
  const [active, setActive] = useState(!!user?.active);
  const [email, setEmail] = useState(user?.email || '');
  const [supervisor, setSupervisor] = useState(!!user?.supervisor);
  const [rights, setRights] = useState<Right[]>(user?.rights || []);
  const [errorMsg, setErrorMsg] = useState('');
  const { organizationData } = useOrganizationContext();
  const { handleSendPasswordEmail, sendingEmail } = useSendUserPassword();
  const { rights: availableRights } = organizationData || {};

  const isCreate = !user;

  const { lang } = useContext(LangContext);

  const { loading: isEditingUser, handleSaveUser } = useEditSubuser();

  const handleSubmit = async () => {
    const { error: userError } = await handleSaveUser({ active, email, id: user?.id, supervisor, rights });

    if (userError) {
      setErrorMsg('User could not be saved.');
    } else {
      setErrorMsg('');
    }
  };

  const toggleRight = (r: Right) => {
    if (rights?.includes(r)) {
      setRights(rights.filter(ri => ri !== r));
    } else {
      setRights([...rights, r]);
    }
  };

  const isLoading = isEditingUser;

  return (
    <Portal>
      {/* Background */}
      <ASimple key="EditSubuser-Background" className="EditDialog-Background" style={{ height: window.innerHeight }} onClick={abort} />

      {/* Content */}
      <ADialog key="DelteConfirm-Content" className="EditDialog-Content" topExit="0" topEnter="50%">
        {/* Close Icon */}
        <div className="EditDialog-Content-Icon">
          <Icon type="close" size="1.25rem" color="grey" onClick={abort} />
        </div>

        {/* Main */}
        <div className="EditDialog-Content-Main">
          <Heading level="1" color="dark">
            {isCreate ? Localizations['createUser'][lang] : Localizations['editUser'][lang]}
          </Heading>

          <form
            className="EditDialog-Content-Main-Form"
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <span>
              <b>{Localizations['mail'][lang]}</b>
            </span>

            <Input
              value={email}
              placeholder="E-Mail"
              type="email"
              onChange={e => {
                const target = e.target as HTMLInputElement;
                setEmail(target.value);
              }}
            />

            <Checkbox
              centered={false}
              boxSize="1rem"
              id="User-Superuser-Check"
              labelColor="black"
              status={supervisor}
              setStatus={() => setSupervisor(!supervisor)}
              label="Superuser"
            />

            <Checkbox
              centered={false}
              boxSize="1rem"
              id="User-Active-Check"
              labelColor="black"
              status={active}
              setStatus={() => setActive(!active)}
              label={Localizations['activateUser'][lang]}
            />

            <div className="EditDialog-Content-Main-Form-Rights">
              <span>
                <b>{Localizations['rights'][lang]}</b>
              </span>
              {availableRights?.map(r => (
                <div key={r}>
                  <Checkbox
                    boxSize="1rem"
                    centered={false}
                    id={`${r}-user-right-check`}
                    labelColor="black"
                    status={rights?.includes(r)}
                    setStatus={() => toggleRight(r)}
                    label={getLabelForRight({ right: r, langId: lang })}
                  />
                </div>
              ))}
            </div>
            <p className={'text-center'}>{errorMsg}</p>
            <Button btnType="third" maxWidth={400} type="submit" margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
              {isLoading ? <LoadingSpinner size="1rem" /> : Localizations['save'][lang]}
            </Button>
          </form>

          <Button btnType="fourth" maxWidth={400} onClick={abort} margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
            {isLoading ? <LoadingSpinner size="1rem" /> : Localizations['cancel'][lang]}
          </Button>

          {user?.id && (
            <Button disabled={sendingEmail} onClick={() => handleSendPasswordEmail(user.id)}>
              {sendingEmail ? <LoadingSpinner color="medium" size=".875rem" /> : Localizations['sendPasswordEmail'][lang]}
            </Button>
          )}
        </div>
      </ADialog>
    </Portal>
  );
};
