import React, { useContext, useEffect, useState, FC } from 'react';
import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Input } from 'atoms';

// Components:
import { LoadingSpinner } from 'components';

// Context:
import { LangContext } from 'context';

// Languages:
import Localizations from 'languages';

// Utils:
import { post } from 'utils';

interface Props {
  setShowPasswordDialog: (s: boolean) => void;
}

export const ResetPasswordDialog: FC<Props> = ({ setShowPasswordDialog }) => {
  const { lang } = useContext(LangContext);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [wasSent, setWasSent] = useState<boolean>(null);

  const close = (e: any) => {
    e.preventDefault();

    setShowPasswordDialog(false);
  };

  const sendResetPassword = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/member/password/reset`, {
      data: {
        email
      }
    });
    if (data) {
      setWasSent(true);
    }
    if (error) {
      setWasSent(false);
    }
    console.log(`Send E-Mail to: ${email}`);
    setLoading(false);
    //close(e);
  };

  useEffect(() => {
    return () => {
      setWasSent(null);
    };
  }, []);

  return (
    <Portal>
      {/* Background */}
      <ASimple key="PasswordDialog-Background" className="PasswordDialog-Background" onClick={close} style={{ height: window.innerHeight }} />
      {/* Content */}
      <ADialog key="PasswordDialog-Content" className="PasswordDialog-Content" topExit="0" topEnter="22%">
        <>
          <Heading level="2" color="dark">
            {Localizations['resetPassword'][lang]}
          </Heading>
          <p style={{ height: '60px' }}>{wasSent ? Localizations['resetSent'][lang] : wasSent === false ? Localizations['resetNotSent'][lang] : ''}</p>
          <form
            action=""
            className="LoginPage-ResetForm"
            onSubmit={e => {
              e.preventDefault();
              if (!loading) {
                sendResetPassword(e);
              }
            }}
          >
            <Input
              autoComplete="current-password"
              className="LoginPage-InputField"
              //@ts-ignore
              onChange={event => setEmail(event.target.value)}
              placeholder={'E-Mail'}
              type="email"
              value={email}
              margin="0 0 0.5rem"
            />
            <Button btnType="third" margin="3rem 0 0 0" type="submit" fontSize=".65rem" padding=".75rem 0 .75rem 0" width={'100%'} maxWidth={350}>
              {loading ? <LoadingSpinner size="20px" /> : Localizations['continue'][lang]}
            </Button>
            <Button
              btnType="second"
              type="button"
              fontSize=".65rem"
              padding=".75rem 0 .75rem 0"
              margin="1rem 0 0 0"
              onClick={close}
              width={'100%'}
              maxWidth={350}
            >
              {Localizations['abort'][lang]}
            </Button>
          </form>
        </>
      </ADialog>
    </Portal>
  );
};
