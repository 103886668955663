import { Right } from 'types/Member';
import Localizations from 'languages';

export const getLabelForRight = ({ right, langId }: { right: Right; langId: string }): string => {
  if (right === 'Admin') return 'Admin';
  else if (right === 'AccessFlexi') return `${Localizations['access'][langId]} ${Localizations['flexichef'][langId]}`;
  else if (right === 'AccessMasterline') return `${Localizations['access'][langId]} ${Localizations['masterline'][langId]}`;
  else if (right === 'AccessModular') return `${Localizations['access'][langId]} ${Localizations['modular'][langId]}`;
  else if (right === 'AccessRoom') return `${Localizations['access'][langId]} ${Localizations['room'][langId]}`;
  else if (right === 'AccessSpace') return `${Localizations['access'][langId]} ${Localizations['spacekombi'][langId]}`;
  else if (right === 'ShowTotalPrice') return `${Localizations['access'][langId]} ${Localizations['totalPriceRight'][langId]}`;
  else if (right === 'ShowSinglePricesFlexi') return `${Localizations['access'][langId]} ${Localizations['singlePriceRight'][langId]} ${Localizations['flexichef'][langId]}`;
  else if (right === 'ShowSinglePricesMasterline') return `${Localizations['access'][langId]} ${Localizations['singlePriceRight'][langId]} ${Localizations['masterline'][langId]}`;
  else if (right === 'ShowSinglePricesModular') return `${Localizations['access'][langId]} ${Localizations['singlePriceRight'][langId]} ${Localizations['modular'][langId]}`;
  else if (right === 'ShowSinglePricesSpace') return `${Localizations['access'][langId]} ${Localizations['singlePriceRight'][langId]} ${Localizations['spacekombi'][langId]}`;
  else if (right === 'ShowSinglePricesMarineMeister') return `${Localizations['access'][langId]} ${Localizations['singlePriceRight'][langId]} ${Localizations['marineMeister'][langId]}`;
  /*  else if (right === 'EditModular') return `${Localizations['edit'][langId]} ${Localizations['modular'][langId]}`;
  else if (right === 'EditMasterline') return `${Localizations['edit'][langId]} ${Localizations['masterline'][langId]}`; */

  return '';
};
