import React, { FC, useContext, useEffect, useState } from 'react';

// Components
import { Icon } from 'atoms';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import RoomDoor from 'page/Editor/configuration/RoomDoor';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Context
interface Props {}

export const Doors: FC<Props> = () => {
  const { lang } = useContext(LangContext);
  const { setRoomView, scene, selection, setErrorAlert, setSelection } = useContext(EditorContext);

  const [door, setDoor] = useState({
    width: selection instanceof RoomDoor ? selection.getWidth() : 0,
    height: selection instanceof RoomDoor ? selection.getHeight() : 0
  });

  useEffect(() => {
    if (selection && selection instanceof RoomDoor) {
      setDoor({ width: selection instanceof RoomDoor ? selection.getWidth() : 0, height: selection instanceof RoomDoor ? selection.getHeight() : 0 });
    }
  }, [selection]);

  return (
    <div key="room-doors-menu">
      <ContextMenu
        mode="back"
        onClick={() => {
          if (scene.getSelected()) scene.setSelected(null);
          if (selection) setSelection(null);
          setRoomView('home');
        }}
      />
      {selection && selection instanceof RoomDoor ? (
        <>
          <div className="Submenu-Headline"> {Localizations['doors'][lang]}</div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setDoor({
                      ...door,
                      width: val
                    });
                    if (selection instanceof RoomDoor) {
                      selection.setWidth(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={door.width}
              />
              {Localizations['width'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setDoor({
                      ...door,
                      height: val
                    });
                    if (selection instanceof RoomDoor) {
                      selection.setHeight(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={door.height}
              />
              {Localizations['height'][lang]} (mm)
            </label>
          </div>
          <div className="DeviceDetails-Small-Bottom">
            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        </>
      ) : (
        <div className="mt-4 text-sm bold">{Localizations['selectDoor'][lang]}</div>
      )}
    </div>
  );
};
