// ===================================================================
export const colors = {
  /* General */
  transparent: 'transparent',
  white: 'white',
  extraLight: 'rgb(249, 249, 251)',
  light: 'rgb(225, 225, 225)',
  grey: 'rgb(200,208,214)',
  medium: '#222F47',
  dark: 'rgb(50, 50, 50)',
  greyText: '#8e9fa9',

  lightBlue: 'rgba(0, 175, 255, 1)',
  blue: 'rgba(0, 140, 215, 1)',
  darkBlue: 'rgba(30, 100, 180, 1)',

  /* Component */
  menu: '#222F47',

  /* Status */
  disabled: 'rgb(200,208,214)',
  disabledGrey: 'rgb(235, 235, 235)',

  signal: "#ce281f"
};

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
