import React, { useState, CSSProperties, useCallback, useContext, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Portal } from 'react-portal';

// Atoms
import { AEditorMenuItem } from 'atoms';

// Components
import { Doors, GroundPlan, Pillars, Walls, Windows } from './Partials';
import { ErrorMessage } from 'page/Editor/Menu/Shared';
import { MenuTile } from './Partials';
import Room, { RoomChild } from 'page/Editor/configuration/Room';
import RoomDoor from 'page/Editor/configuration/RoomDoor';
import { RoomWall } from 'page/Editor/configuration/RoomWall';
import RoomWindow from 'page/Editor/configuration/RoomWindow';
import { RoomPillar } from 'page/Editor/configuration/RoomPillar';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Styles
import './style.scss';
import { colors } from 'styles/theme';

export const RoomMenu = () => {
  const { configuration, errorAlert, menuView, roomView, scene, selection, setErrorAlert, setRoomView, setSelection } = useContext(EditorContext);

  const { lang } = useContext(LangContext);
  const [draggingInScene, setDraggingInScene] = useState<boolean>(false);

  const [roomExists, setRoomExists] = useState(configuration && configuration.getRoom() ? true : false);
  // Contants
  const style: CSSProperties = { height: '100%', position: 'relative' };
  const left: string = '5%';

  useEffect(() => {
    if (configuration) {
      setRoomExists(configuration.getRoom() ? true : false);
    }
  }, [configuration]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Delete') {
        if (selection && (selection instanceof Room || selection instanceof RoomChild)) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    if (selection) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  useEffect(() => {
    if (configuration) {
      const room = configuration.getRoom();
      if (room) {
        room.setActive(true);
      }
    }
    return () => {
      if (configuration && configuration.getRoom()) {
        configuration.getRoom().setActive(false);
      }
    };
  }, []);

  return (
    <>
      {/* HOME */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'home' && (
        <div className="flex-col justify-between" key="room-home-menu">
          {/* <ContextMenu /> */}
          {/* GroundPlan Drag */}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Devices-Room') && !draggingInScene) {
                setDraggingInScene(true);
              } else {
                setDraggingInScene(false);
              }
            }}
            onDragEnd={() => {
              if (draggingInScene) {
                configuration.setRoom(new Room());
                configuration.getRoom().setActive(true);
                //if (!window['_room']) window['_room'] = new Room();
                setRoomExists(true);
              }
            }}
          >
            <Droppable droppableId="Droppable-Room" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable
                    key="drag-ground-plan"
                    isDragDisabled={configuration ? configuration.getRoom() !== null : false}
                    draggableId={'drag-ground-plan-id'}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <MenuTile
                            disabled={false}
                            disabledAdd={configuration ? configuration.getRoom() !== null : false}
                            icon={'room_groundPlan'}
                            iconOnClick={() => {
                              configuration.setRoom(new Room());
                              configuration.getRoom().setActive(true);
                              //if (!window['_room']) window['_room'] = new Room();
                              setRoomExists(true);
                            }}
                            onClick={() => {
                              if (configuration && configuration.getRoom()) {
                                setRoomView('groundPlan');
                              }
                            }}
                            style={{ cursor: roomExists ? 'pointer' : 'default' }}
                            text={Localizations['groundPlan'][lang]}
                          />{' '}
                        </div>
                        {snapshot.isDragging && (
                          <div id={`Draggable-Room-Div`}>
                            <MenuTile
                              disabled={false}
                              icon={'room_groundPlan'}
                              iconOnClick={() => {
                                configuration.setRoom(new Room());
                                configuration.getRoom().setActive(true);
                                //if (!window['_room']) window['_room'] = new Room();
                                setRoomExists(true);
                              }}
                              onClick={() => {
                                if (configuration.getRoom()) {
                                  setRoomView('groundPlan');
                                }
                              }}
                              style={{ cursor: roomExists ? 'pointer' : 'default' }}
                              text={Localizations['groundPlan'][lang]}
                            />{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Portal>
              <Droppable droppableId="Droppable-Devices-Room" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Devices-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
          {/* Door Drag */}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Doors-Scene') && !draggingInScene) {
                scene.onDragOver(new RoomDoor(configuration.getRoom()), null, configuration);
                setDraggingInScene(true);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
              } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Devices') && draggingInScene) {
                scene.onDragOut();
                setDraggingInScene(false);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
              }
            }}
            onDragEnd={() => {
              setDraggingInScene(false);
            }}
          >
            <Droppable droppableId="Droppable-Doors" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable
                    key="drag-doors"
                    isDragDisabled={configuration ? configuration.getRoom() === null : true}
                    draggableId={'Draggable-Doors'}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <MenuTile
                            disabled={!roomExists}
                            icon={'room_doors'}
                            iconOnClick={() => {
                              if (configuration.getRoom()) {
                                new RoomDoor(configuration.getRoom());
                              }
                            }}
                            onClick={() => setRoomView('doors')}
                            text={Localizations['doors'][lang]}
                          />{' '}
                        </div>
                        {snapshot.isDragging && (
                          <div id={`Draggable-Doors-Div`}>
                            <MenuTile
                              disabled={!roomExists}
                              icon={'room_doors'}
                              iconOnClick={() => {
                                if (configuration.getRoom()) {
                                  new RoomDoor(configuration.getRoom());
                                }
                              }}
                              onClick={() => setRoomView('doors')}
                              text={Localizations['doors'][lang]}
                            />{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Portal>
              <Droppable droppableId="Droppable-Doors-Scene" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Devices-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
          {/* Window Drag */}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Windows-Scene') && !draggingInScene) {
                scene.onDragOver(new RoomWindow(configuration.getRoom()), null, configuration);
                setDraggingInScene(true);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
              } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Devices') && draggingInScene) {
                scene.onDragOut();
                setDraggingInScene(false);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
              }
            }}
            onDragEnd={() => {
              setDraggingInScene(false);
            }}
          >
            <Droppable droppableId="Droppable-Windows" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable
                    key="drag-windows"
                    isDragDisabled={configuration ? configuration.getRoom() === null : true}
                    draggableId={'Draggable-Windows'}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <MenuTile
                            disabled={!roomExists}
                            icon={'room_windows'}
                            iconOnClick={() => {
                              if (configuration.getRoom()) {
                                new RoomWindow(configuration.getRoom());
                              }
                            }}
                            onClick={() => setRoomView('windows')}
                            text={Localizations['windows'][lang]}
                          />{' '}
                        </div>
                        {snapshot.isDragging && (
                          <div id={`Draggable-Windows-Div`}>
                            <MenuTile
                              disabled={!roomExists}
                              icon={'room_windows'}
                              iconOnClick={() => {
                                if (configuration.getRoom()) {
                                  new RoomWindow(configuration.getRoom());
                                }
                              }}
                              onClick={() => setRoomView('windows')}
                              text={Localizations['windows'][lang]}
                            />{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Portal>
              <Droppable droppableId="Droppable-Windows-Scene" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Devices-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
          {/* Wall Drag */}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Walls-Scene') && !draggingInScene) {
                scene.onDragOver(new RoomWall(configuration.getRoom()), null, configuration);
                setDraggingInScene(true);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
              } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Devices') && draggingInScene) {
                scene.onDragOut();
                setDraggingInScene(false);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
              }
            }}
            onDragEnd={() => {
              setDraggingInScene(false);
            }}
          >
            <Droppable droppableId="Droppable-Walls" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable
                    key="drag-walls"
                    isDragDisabled={configuration ? configuration.getRoom() === null : true}
                    draggableId={'Draggable-Walls'}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <MenuTile
                            disabled={!roomExists}
                            icon={'room_walls'}
                            iconOnClick={() => {
                              if (configuration.getRoom()) {
                                new RoomWall(configuration.getRoom());
                              }
                            }}
                            onClick={() => setRoomView('walls')}
                            text={Localizations['walls'][lang]}
                          />{' '}
                        </div>
                        {snapshot.isDragging && (
                          <div id={`Draggable-Walls-Div`}>
                            <MenuTile
                              disabled={!roomExists}
                              icon={'room_walls'}
                              iconOnClick={() => {
                                if (configuration.getRoom()) {
                                  new RoomWall(configuration.getRoom());
                                }
                              }}
                              onClick={() => setRoomView('walls')}
                              text={Localizations['walls'][lang]}
                            />{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Portal>
              <Droppable droppableId="Droppable-Walls-Scene" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Devices-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
          {/* Pillar Drag */}
          <DragDropContext
            onDragUpdate={initial => {
              if (scene && initial.destination && initial.destination.droppableId.includes('Droppable-Pillars-Scene') && !draggingInScene) {
                scene.onDragOver(new RoomPillar(configuration.getRoom()), null, configuration);
                setDraggingInScene(true);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'none';
              } else if (scene && (!initial.destination || initial.destination.droppableId === 'Droppable-Pillars') && draggingInScene) {
                scene.onDragOut();
                setDraggingInScene(false);
                document.getElementById(`${initial.draggableId}-Div`).style.display = 'block';
              }
            }}
            onDragEnd={() => {
              setDraggingInScene(false);
            }}
          >
            <Droppable droppableId="Droppable-Pillars" ignoreContainerClipping isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Draggable
                    key="Draggable-Pillars"
                    isDragDisabled={configuration ? configuration.getRoom() === null : true}
                    draggableId={'Draggable-Pillars'}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div>
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <MenuTile
                            disabled={!roomExists}
                            icon={'room_pillars'}
                            iconOnClick={() => {
                              if (configuration.getRoom()) {
                                new RoomPillar(configuration.getRoom());
                              }
                            }}
                            onClick={() => setRoomView('pillars')}
                            text={Localizations['pillars'][lang]}
                          />{' '}
                        </div>
                        {snapshot.isDragging && (
                          <div id={`Draggable-Pillars-Div`}>
                            <MenuTile
                              disabled={!roomExists}
                              icon={'room_pillars'}
                              iconOnClick={() => {
                                if (configuration.getRoom()) {
                                  new RoomPillar(configuration.getRoom());
                                }
                              }}
                              onClick={() => setRoomView('pillars')}
                              text={Localizations['pillars'][lang]}
                            />{' '}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Portal>
              <Droppable droppableId="Droppable-Pillars-Scene" ignoreContainerClipping>
                {(provided, snapshot) => (
                  <div
                    className="Droppable-Pillars-Scene"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ border: '5px solid ' + (snapshot.isDraggingOver ? colors['lightBlue'] : 'transparent') }}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Portal>
          </DragDropContext>
          {/* 
            <MenuTile
              disabled={!roomExists}
              icon={'room_walls'}
              iconOnClick={() => {
                if (configuration.room) {
                  new RoomWall(configuration.room);
                }
              }}
              onClick={() => setRoomView('walls')}
              text={Localizations['walls'][lang]}
            />
            <MenuTile
              disabled={!roomExists}
              icon={'room_pillars'}
              iconOnClick={() => {
                if (configuration.room) {
                  new RoomPillar(configuration.room);
                }
              }}
              onClick={() => setRoomView('pillars')}
              text={Localizations['pillars'][lang]}
            /> */}
        </div>
      )}

      {/* Ground Plan */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'groundPlan' && (
        <AEditorMenuItem key="room-menu-grounPlan" left={left} style={style}>
          <GroundPlan />
        </AEditorMenuItem>
      )}

      {/* Doors */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'doors' && (
        <AEditorMenuItem key="room-menu-doors" left={left} style={style}>
          <Doors />
        </AEditorMenuItem>
      )}

      {/* Windows */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'windows' && (
        <AEditorMenuItem key="room-menu-windows" left={left} style={style}>
          <Windows />
        </AEditorMenuItem>
      )}

      {/* Walls */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'walls' && (
        <AEditorMenuItem key="room-menu-walls" left={left} style={style}>
          <Walls />
        </AEditorMenuItem>
      )}

      {/* Pillars */}
      {(!errorAlert || (errorAlert && errorAlert.length === 0)) && roomView === 'pillars' && (
        <AEditorMenuItem key="room-menu-pillars" left={left} style={style}>
          <Pillars />
        </AEditorMenuItem>
      )}

      {errorAlert && errorAlert.includes('delete') && menuView === 'room' && (
        <AEditorMenuItem key="room-menu-delete" left={left} style={style}>
          <ErrorMessage
            title={Localizations['delete'][lang]}
            message={Localizations['confirmDelete'][lang]}
            confirm={() => {
              if (roomView === 'groundPlan') {
                // selection.delete();
                configuration.getRoom()?.getNode()?.dispose();
                configuration.setRoom(null);
                setSelection(null);
                window.removeEventListener('keydown', handleKeyDown);
              } else if (selection && selection instanceof RoomChild) {
                const child = scene.getSelected();
                if (child instanceof RoomChild) {
                  child.delete();
                }
                setSelection(null);
                window.removeEventListener('keydown', handleKeyDown);
              }
              setSelection(null);
              setErrorAlert(null);
              scene.setSelected(null);
            }}
            goBack={() => setErrorAlert(null)}
          />
        </AEditorMenuItem>
      )}
    </>
  );
};
