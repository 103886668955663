/* 
Takes a string and returns the word contained in the passed-amount of characters
*/

export const shortenText = (text: string, chars: number) => {
  const words = text.split(' ');
  let length = 0;
  let shortenedText = '';
  words.forEach(word => {
    if (length + word.length + 1 <= chars) {
      shortenedText += `${word} `;
      length += word.length + 1;
    }
  });
  return shortenedText;
};
