import React, { useContext, FC } from 'react';

// Atoms
import { Button } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Languages
import Localizations from 'languages';

export const SeriesFilter: FC = () => {
  const { seriesFilter, setSeriesFilter } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  return (
    <div className="Functional-View-ContextMenu-Series">
      <span style={{ marginRight: '.25rem' }}>{Localizations['series'][lang]}</span>
      <Button
        btnType={seriesFilter === '700' || seriesFilter === 'all' ? 'third' : 'fourth'}
        fontSize=".6rem"
        margin="0 1px 0 0"
        onClick={() => setSeriesFilter(seriesFilter === '700' ? 'all' : seriesFilter === 'all' ? '850' : 'all')}
        padding=".25rem"
        thinBorder
        width="50px"
      >
        700
      </Button>
      <Button
        btnType={seriesFilter === '850' || seriesFilter === 'all' ? 'third' : 'fourth'}
        fontSize=".6rem"
        margin="0 0 0 1px"
        onClick={() => setSeriesFilter(seriesFilter === '850' ? 'all' : seriesFilter === 'all' ? '700' : 'all')}
        padding=".25rem"
        thinBorder
        width="50px"
      >
        850
      </Button>
    </div>
  );
};
