import React, { FC, useContext, useEffect, useState } from 'react';

// Components
import { Icon } from 'atoms';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import RoomWindow from 'page/Editor/configuration/RoomWindow';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Context
interface Props {}

export const Windows: FC<Props> = () => {
  const { selection, setErrorAlert, setRoomView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const [window, setWindow] = useState({
    width: selection instanceof RoomWindow ? selection.getWidth() : 0,
    height: selection instanceof RoomWindow ? selection.getHeight() : 0,
    floorDistance: selection instanceof RoomWindow ? selection.getBottom() : 0
  });

  useEffect(() => {
    setWindow({
      width: selection instanceof RoomWindow ? selection.getWidth() : 0,
      height: selection instanceof RoomWindow ? selection.getHeight() : 0,
      floorDistance: selection instanceof RoomWindow ? selection.getBottom() : 0
    });
  }, [selection]);

  return (
    <div key="room-windows-menu">
      <ContextMenu mode="back" onClick={() => setRoomView('home')} />
      {selection instanceof RoomWindow ? (
        <>
          <div className="Submenu-Headline"> {Localizations['windows'][lang]}</div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWindow({
                      ...window,
                      width: val
                    });
                    if (selection instanceof RoomWindow) {
                      selection.setWidth(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={window.width}
              />
              {Localizations['width'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWindow({
                      ...window,
                      height: val
                    });
                    if (selection instanceof RoomWindow) {
                      selection.setHeight(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={window.height}
              />
              {Localizations['height'][lang]} (mm)
            </label>
          </div>
          <div className="Submenu-Attribute">
            <label className="Submenu-Label">
              <input
                className="Submenu-Input"
                min={0}
                onChange={event => {
                  const val = parseInt(event.target.value);
                  if (val >= 0) {
                    setWindow({
                      ...window,
                      floorDistance: val
                    });
                    if (selection instanceof RoomWindow) {
                      selection.setBottom(val);
                    }
                  }
                }}
                step={100}
                type="number"
                value={window.floorDistance}
              />
              {Localizations['floorDistance'][lang]} (mm)
            </label>
          </div>
          <div className="DeviceDetails-Small-Bottom">
            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        </>
      ) : (
        <div className="mt-4 text-sm bold">{Localizations['selectWindow'][lang]}</div>
      )}
    </div>
  );
};
