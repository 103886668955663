import React, { FC } from 'react';

// Style:
import './style.scss';
import { colors } from 'styles/theme';

interface Props {
  color?: 'white' | 'blue' | 'medium';
  size?: string;
  borderWidth?: number;
}

// ===================================================================
export const LoadingSpinner: FC<Props> = ({ color = 'white', size = '40px', borderWidth = 3 }) => (
  <div
    className="LoadingSpinner"
    style={{
      height: size,
      width: size,
      borderWidth: borderWidth,
      borderColor: colors[color]
    }}
  />
);
