import React from 'react';

// Types:
import Block from 'page/Editor/configuration/Block';
import Scene, { Selected } from 'page/Editor/Scene';
import Configuration from '../page/Editor/configuration/Configuration';

// Types
import {
  Device,
  DeviceImage,
  DeviceImageStorage,
  DeviceSeries,
  Dialog,
  ErrorAlert,
  FlexiMenuView,
  FullscreenView,
  MasterlineMenu,
  MarineMeisterMenu,
  MenuView,
  ModularView,
  ModularNOLView,
  RoomView,
  SavedConfiguration
} from 'types';

interface EditorContextType {
  configuration: Configuration;
  setConfiguration: (c: Configuration) => void;
  coverEnlargementSize: string;
  setCoverEnlargementSize: (s: string) => void;
  canAdvanceMarineMeister: boolean;
  setCanAdvanceMarineMeister: (canAdvance: boolean) => void;
  canAdvanceMasterline: boolean;
  setCanAdvanceMasterline: (c: boolean) => void;
  canAdvanceModular: boolean;
  setCanAdvanceModular: (c: boolean) => void;
  canAdvanceModularNOL: boolean;
  setCanAdvanceModularNOL: (c: boolean) => void;
  savedConfiguration: SavedConfiguration;
  setSavedConfiguration: (c: SavedConfiguration) => void;
  containsMerges: boolean;
  setContainsMerges: (c: boolean) => void;
  dialog: Dialog;
  setDialog: (d: Dialog) => void;
  discountFlexiChef: number;
  setDiscountFlexiChef: (dFC: number) => void;
  discountSpaceCombi: number;
  setDiscountSpaceCombi: (dSC: number) => void;
  discountModular: number;
  setDiscountModular: (dMo: number) => void;
  discountMasterline: number;
  setDiscountMasterline: (dMa: number) => void;
  deviceImageStorage: DeviceImageStorage;
  addImageToStorage: (image: DeviceImage) => void;
  flexiMenuView: FlexiMenuView;
  setFlexiMenuView: (view: FlexiMenuView) => void;
  fullscreen: FullscreenView;
  setFullscreen: (screen: FullscreenView) => void;
  errorAlert: ErrorAlert[];
  setErrorAlert: (err: ErrorAlert[]) => void;
  hasMarineMeister: boolean;
  setHasMarineMeister: (hasMarineMeister: boolean) => void;
  hasMasterline: boolean;
  setHasMasterline: (hasMasterline: boolean) => void;
  marineMeisterView: MarineMeisterMenu;
  setMarineMeisterView: (mV: MarineMeisterMenu) => void;
  masterlineView: MasterlineMenu;
  setMasterlineView: (mV: MasterlineMenu) => void;
  menuView: MenuView;
  setMenuView: (view: MenuView) => void;
  modularView: ModularView;
  setModularView: (mV: ModularView) => void;
  modularNOLView: ModularNOLView;
  setModularNOLView: (mV: ModularNOLView) => void;
  owner: boolean;
  setOwner: (o: boolean) => void;
  profileOpen: boolean;
  setProfileOpen: (open: boolean) => void;
  roomView: RoomView;
  setRoomView: (rV: RoomView) => void;
  savingError: boolean;
  setSavingError: (err: boolean) => void;
  scene: Scene;
  setScene: (scene: Scene) => void;
  searching: boolean;
  setSearching: (b: boolean) => void;
  searchQuery: string;
  setSearchQuery: (q: string) => void;
  searchResults: Device[];
  setSearchResults: (results: Device[]) => void;
  seriesFilter: DeviceSeries;
  setSeriesFilter: (filter: DeviceSeries) => void;
  selectedMarineMeister: Block;
  setSelectedMarineMeister: (marineMeisterBlock: Block) => void;
  selectedMasterline: Block;
  setSelectedMasterline: (masterlineBlock: Block) => void;
  selectedModular: Block;
  setSelectedModular: (modularBlock: Block) => void;
  selectedModularNOL: Block;
  setSelectedModularNOL: (modularBlock: Block) => void;
  selection: Selected | Device | null;
  setSelection: (selection: Selected | Device | null) => void;
  showLoadingError: boolean;
  setShowLoadingError: (show: boolean) => void;
  surchargeMasterline: number;
  setSurchargeMasterline: React.Dispatch<React.SetStateAction<number>>;
}

// ==============================================================
export const EditorContext = React.createContext<EditorContextType>({
  configuration: null,
  setConfiguration: (configuration: Configuration) => {},

  coverEnlargementSize: '',
  setCoverEnlargementSize: (size: string) => {},

  canAdvanceMarineMeister: false,
  setCanAdvanceMarineMeister: (canAdvance: boolean) => {},

  canAdvanceMasterline: null,
  setCanAdvanceMasterline: (canAdvance: boolean) => {},

  canAdvanceModular: null,
  setCanAdvanceModular: (canAdvance: boolean) => {},

  canAdvanceModularNOL: null,
  setCanAdvanceModularNOL: (canAdvance: boolean) => {},

  savedConfiguration: null,
  setSavedConfiguration: (config: SavedConfiguration) => {},

  containsMerges: null,
  setContainsMerges: (containsMerges: boolean) => {},

  dialog: null,
  setDialog: (dialog: Dialog) => {},

  discountFlexiChef: null,
  setDiscountFlexiChef: (dFC: number) => {},

  discountSpaceCombi: null,
  setDiscountSpaceCombi: (dSC: number) => {},

  discountModular: null,
  setDiscountModular: (dMo: number) => {},

  discountMasterline: null,
  setDiscountMasterline: (dMa: number) => {},

  deviceImageStorage: null,
  addImageToStorage: (image: DeviceImage) => {},

  flexiMenuView: null,
  setFlexiMenuView: (v: FlexiMenuView) => {},

  fullscreen: null,
  setFullscreen: (screen: FullscreenView) => {},

  errorAlert: null,
  setErrorAlert: (err: ErrorAlert[]) => {},

  hasMarineMeister: null,
  setHasMarineMeister: (hasMarineMeister: boolean) => {},

  hasMasterline: null,
  setHasMasterline: (hasMasterline: boolean) => {},

  marineMeisterView: 'home',
  setMarineMeisterView: (mV: MarineMeisterMenu) => {},

  masterlineView: null,
  setMasterlineView: (mV: MasterlineMenu) => {},

  menuView: 'room' as MenuView,
  setMenuView: (view: MenuView) => {},

  modularView: null,
  setModularView: (mV: ModularView) => {},

  modularNOLView: null,
  setModularNOLView: (mV: ModularNOLView) => {},

  owner: null,
  setOwner: (o: boolean) => {},

  profileOpen: null,
  setProfileOpen: (open: boolean) => {},

  roomView: null,
  setRoomView: (rV: RoomView) => {},

  savingError: null,
  setSavingError: (err: boolean) => {},

  scene: null,
  setScene: (scene: Scene) => {},

  searchQuery: null,
  setSearchQuery: (q: string) => {},

  searchResults: null,
  setSearchResults: (results: Device[]) => {},

  seriesFilter: null,
  setSeriesFilter: (filter: DeviceSeries) => {},

  selectedMarineMeister: null,
  setSelectedMarineMeister: (marineMeisterBlock: Block) => {},

  selectedMasterline: null,
  setSelectedMasterline: (masterlineBlock: Block) => {},

  selectedModular: null,
  setSelectedModular: (modularBlock: Block) => {},

  selectedModularNOL: null,
  setSelectedModularNOL: (modularBlock: Block) => {},

  selection: null,
  setSelection: (selection: Selected | Device | null) => {},

  searching: false,
  setSearching: (s: boolean) => {},

  showLoadingError: false,
  setShowLoadingError: (show: boolean) => {},

  surchargeMasterline: 0,
  setSurchargeMasterline: (surcharge: number) => {}
});
