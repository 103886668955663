const ralColors = [
  [
    { RAL: 1000, rgb: { r: 205, g: 186, b: 136 }, hex: '#cdba88' },
    { RAL: 1001, rgb: { r: 208, g: 176, b: 132 }, hex: '#d0b084' },
    { RAL: 1002, rgb: { r: 210, g: 170, b: 109 }, hex: '#d2aa6d' },
    { RAL: 1003, rgb: { r: 249, g: 168, b: 0 }, hex: '#f9a800' },
    { RAL: 1004, rgb: { r: 228, g: 158, b: 0 }, hex: '#e49e22' }
  ],
  [
    { RAL: 1005, rgb: { r: 203, g: 142, b: 30 }, hex: '#cb8e1e' },
    { RAL: 1006, rgb: { r: 226, g: 144, b: 0 }, hex: '#e29000' },
    { RAL: 1007, rgb: { r: 232, g: 140, b: 0 }, hex: '#e88c00' },
    { RAL: 1011, rgb: { r: 175, g: 128, b: 79 }, hex: '#af804f' },
    { RAL: 1012, rgb: { r: 221, g: 175, b: 39 }, hex: '#ddaf27' }
  ],
  [
    { RAL: 1013, rgb: { r: 227, g: 217, b: 198 }, hex: '#e3d9c6' },
    { RAL: 1014, rgb: { r: 221, g: 196, b: 154 }, hex: '#ddc49a' },
    { RAL: 1015, rgb: { r: 230, g: 210, b: 181 }, hex: '#e6d2b5' },
    { RAL: 1016, rgb: { r: 241, g: 221, b: 56 }, hex: '#f1dd38' },
    { RAL: 1017, rgb: { r: 246, g: 169, b: 80 }, hex: '#f6a950' }
  ],
  [
    { RAL: 1018, rgb: { r: 250, g: 202, b: 48 }, hex: '#faca30' },
    { RAL: 1019, rgb: { r: 164, g: 143, b: 122 }, hex: '#a48f7a' },
    { RAL: 1020, rgb: { r: 160, g: 143, b: 101 }, hex: '#a08f65' },
    { RAL: 1021, rgb: { r: 246, g: 182, b: 0 }, hex: '#f6b600' },
    { RAL: 1023, rgb: { r: 247, g: 181, b: 0 }, hex: '#f7b500' }
  ],
  [
    { RAL: 1024, rgb: { r: 186, g: 143, b: 76 }, hex: '#ba8f4c' },
    { RAL: 1026, rgb: { r: 255, g: 255, b: 0 }, hex: '#ffff00' },
    { RAL: 1027, rgb: { r: 167, g: 127, b: 14 }, hex: '#a77f0e' },
    { RAL: 1028, rgb: { r: 255, g: 155, b: 0 }, hex: '#ff9b00' },
    { RAL: 1032, rgb: { r: 226, g: 163, b: 0 }, hex: '#e2a300' }
  ],
  [
    { RAL: 1033, rgb: { r: 249, g: 154, b: 28 }, hex: '#f99a1c' },
    { RAL: 1034, rgb: { r: 235, g: 156, b: 82 }, hex: '#eb9c52' },
    { RAL: 1035, rgb: { r: 144, g: 131, b: 112 }, hex: '#908370' },
    { RAL: 1036, rgb: { r: 128, g: 100, b: 63 }, hex: '#80643f' },
    { RAL: 1037, rgb: { r: 240, g: 146, b: 0 }, hex: '#f09200' }
  ],

  [
    { RAL: 2000, rgb: { r: 218, g: 110, b: 0 }, hex: '#da6e00' },
    { RAL: 2001, rgb: { r: 186, g: 72, b: 27 }, hex: '#ba481b' },
    { RAL: 2002, rgb: { r: 191, g: 57, b: 34 }, hex: '#bf3922' },
    { RAL: 2003, rgb: { r: 246, g: 120, b: 40 }, hex: '#f67728' },
    { RAL: 2004, rgb: { r: 226, g: 83, b: 3 }, hex: '#e25303' }
  ],

  [
    { RAL: 2005, rgb: { r: 255, g: 77, b: 6 }, hex: '#ff4d06' },
    { RAL: 2007, rgb: { r: 255, g: 178, b: 0 }, hex: '#ffb200' },
    { RAL: 2008, rgb: { r: 237, g: 107, b: 33 }, hex: '#ed6b21' },
    { RAL: 2009, rgb: { r: 222, g: 83, b: 7 }, hex: '#de5307' },
    { RAL: 2010, rgb: { r: 208, g: 93, b: 40 }, hex: '#d05d28' }
  ],

  [
    { RAL: 2011, rgb: { r: 226, g: 110, b: 14 }, hex: '#e26e0e' },
    { RAL: 2012, rgb: { r: 213, g: 101, b: 77 }, hex: '#d5654d' },
    { RAL: 2013, rgb: { r: 146, g: 62, b: 37 }, hex: '#923e25' },
    { RAL: 3000, rgb: { r: 167, g: 41, b: 32 }, hex: '#a72920' },
    { RAL: 3001, rgb: { r: 155, g: 36, b: 35 }, hex: '#9b2423' }
  ],
  [
    { RAL: 3002, rgb: { r: 155, g: 35, b: 33 }, hex: '#872321' },
    { RAL: 3003, rgb: { r: 134, g: 26, b: 34 }, hex: '#861a22' },
    { RAL: 3004, rgb: { r: 107, g: 28, b: 35 }, hex: '#6b1c23' },
    { RAL: 3005, rgb: { r: 89, g: 25, b: 31 }, hex: '#59191f' },
    { RAL: 3007, rgb: { r: 62, g: 32, b: 34 }, hex: '#3e2022' }
  ],

  [
    { RAL: 3009, rgb: { r: 109, g: 52, b: 45 }, hex: '#6d342d' },
    { RAL: 3011, rgb: { r: 121, g: 36, b: 35 }, hex: '#792423' },
    { RAL: 3012, rgb: { r: 198, g: 132, b: 109 }, hex: '#c6846d' },
    { RAL: 3013, rgb: { r: 151, g: 46, b: 37 }, hex: '#972e25' },
    { RAL: 3014, rgb: { r: 203, g: 115, b: 117 }, hex: '#cb7375' }
  ],

  [
    { RAL: 3015, rgb: { r: 216, g: 160, b: 166 }, hex: '#d8a0a6' },
    { RAL: 3016, rgb: { r: 166, g: 61, b: 47 }, hex: '#a63d2f' },
    { RAL: 3017, rgb: { r: 203, g: 85, b: 93 }, hex: '#cb555d' },
    { RAL: 3018, rgb: { r: 199, g: 63, b: 74 }, hex: '#c73f4a' },
    { RAL: 3020, rgb: { r: 187, g: 30, b: 16 }, hex: '#bb1e10' }
  ],

  [
    { RAL: 3022, rgb: { r: 207, g: 105, b: 85 }, hex: '#cf6955' },
    { RAL: 3024, rgb: { r: 255, g: 45, b: 33 }, hex: '#ff2d21' },
    { RAL: 3026, rgb: { r: 255, g: 42, b: 27 }, hex: '#ff2a1b' },
    { RAL: 3027, rgb: { r: 171, g: 39, b: 60 }, hex: '#ab273c' },
    { RAL: 3028, rgb: { r: 204, g: 44, b: 36 }, hex: '#cc2c24' }
  ],

  [
    { RAL: 3031, rgb: { r: 166, g: 52, b: 55 }, hex: '#a63437' },
    { RAL: 3032, rgb: { r: 112, g: 29, b: 35 }, hex: '#701d23' },
    { RAL: 3033, rgb: { r: 165, g: 58, b: 45 }, hex: '#a53a2d' },
    { RAL: 4001, rgb: { r: 129, g: 97, b: 131 }, hex: '#816183' },
    { RAL: 4002, rgb: { r: 141, g: 60, b: 75 }, hex: '#8d3c4b' }
  ],

  [
    { RAL: 4003, rgb: { r: 196, g: 97, b: 140 }, hex: '#c4618c' },
    { RAL: 4004, rgb: { r: 101, g: 30, b: 56 }, hex: '#651e38' },
    { RAL: 4005, rgb: { r: 118, g: 104, b: 154 }, hex: '#76689a' },
    { RAL: 4006, rgb: { r: 144, g: 50, b: 115 }, hex: '#903373' },
    { RAL: 4007, rgb: { r: 71, g: 36, b: 60 }, hex: '#47243c' }
  ],

  [
    { RAL: 4008, rgb: { r: 132, g: 76, b: 130 }, hex: '#844c82' },
    { RAL: 4009, rgb: { r: 157, g: 134, b: 146 }, hex: '#9d8692' },
    { RAL: 4010, rgb: { r: 188, g: 64, b: 119 }, hex: '#bc4077' },
    { RAL: 4011, rgb: { r: 110, g: 99, b: 135 }, hex: '#6e6387' },
    { RAL: 4012, rgb: { r: 107, g: 107, b: 127 }, hex: '#6b6b7f' }
  ],

  [
    { RAL: 5000, rgb: { r: 49, g: 79, b: 111 }, hex: '#314f6f' },
    { RAL: 5001, rgb: { r: 15, g: 76, b: 100 }, hex: '#0f4c64' },
    { RAL: 5002, rgb: { r: 0, g: 56, b: 123 }, hex: '#00387b' },
    { RAL: 5003, rgb: { r: 31, g: 56, b: 85 }, hex: '#1f3855' },
    { RAL: 5004, rgb: { r: 25, g: 30, b: 40 }, hex: '#191e28' }
  ],

  [
    { RAL: 5005, rgb: { r: 0, g: 83, b: 135 }, hex: '#005387' },
    { RAL: 5007, rgb: { r: 55, g: 107, b: 140 }, hex: '#376b8c' },
    { RAL: 5008, rgb: { r: 43, g: 58, b: 68 }, hex: '#2b3a44' },
    { RAL: 5009, rgb: { r: 34, g: 95, b: 120 }, hex: '#225f78' },
    { RAL: 5010, rgb: { r: 0, g: 79, b: 124 }, hex: '#004f7c' }
  ],

  [
    { RAL: 5011, rgb: { r: 26, g: 43, b: 60 }, hex: '#1a2b3c' },
    { RAL: 5012, rgb: { r: 0, g: 137, b: 182 }, hex: '#0089b6' },
    { RAL: 5013, rgb: { r: 25, g: 49, b: 83 }, hex: '#193153' },
    { RAL: 5014, rgb: { r: 99, g: 125, b: 150 }, hex: '#637d96' },
    { RAL: 5015, rgb: { r: 0, g: 124, b: 176 }, hex: '#007cb0' }
  ],

  [
    { RAL: 5017, rgb: { r: 0, g: 91, b: 140 }, hex: '#005b8c' },
    { RAL: 5018, rgb: { r: 5, g: 139, b: 140 }, hex: '#058b8c' },
    { RAL: 5019, rgb: { r: 0, g: 94, b: 131 }, hex: '#005e83' },
    { RAL: 5020, rgb: { r: 0, g: 65, b: 75 }, hex: '#00414b' },
    { RAL: 5021, rgb: { r: 0, g: 117, b: 119 }, hex: '#007577' }
  ],

  [
    { RAL: 5022, rgb: { r: 34, g: 45, b: 90 }, hex: '#222d5a' },
    { RAL: 5023, rgb: { r: 66, g: 105, b: 140 }, hex: '#42698c' },
    { RAL: 5024, rgb: { r: 96, g: 147, b: 172 }, hex: '#6093ac' },
    { RAL: 5025, rgb: { r: 33, g: 105, b: 124 }, hex: '#21697c' },
    { RAL: 5026, rgb: { r: 15, g: 48, b: 82 }, hex: '#0f3052' }
  ],

  [
    { RAL: 6000, rgb: { r: 60, g: 116, b: 96 }, hex: '#3c7460' },
    { RAL: 6001, rgb: { r: 54, g: 103, b: 53 }, hex: '#366735' },
    { RAL: 6002, rgb: { r: 50, g: 89, b: 40 }, hex: '#325928' },
    { RAL: 6003, rgb: { r: 80, g: 83, b: 60 }, hex: '#50533c' },
    { RAL: 6004, rgb: { r: 2, g: 68, b: 66 }, hex: '#024442' }
  ],

  [
    { RAL: 6005, rgb: { r: 17, g: 66, b: 50 }, hex: '#114232' },
    { RAL: 6006, rgb: { r: 60, g: 57, b: 46 }, hex: '#3c392e' },
    { RAL: 6007, rgb: { r: 44, g: 50, b: 34 }, hex: '#2c3222' },
    { RAL: 6008, rgb: { r: 55, g: 52, b: 42 }, hex: '#37342a' },
    { RAL: 6009, rgb: { r: 39, g: 53, b: 42 }, hex: '#27352a' }
  ],

  [
    { RAL: 6010, rgb: { r: 77, g: 111, b: 57 }, hex: '#4d6f39' },
    { RAL: 6011, rgb: { r: 108, g: 124, b: 89 }, hex: '#6c7c59' },
    { RAL: 6012, rgb: { r: 48, g: 61, b: 58 }, hex: '#303d3a' },
    { RAL: 6013, rgb: { r: 125, g: 118, b: 90 }, hex: '#7d765a' },
    { RAL: 6014, rgb: { r: 71, g: 65, b: 53 }, hex: '#474135' }
  ],

  [
    { RAL: 6015, rgb: { r: 61, g: 61, b: 54 }, hex: '#3d3d36' },
    { RAL: 6016, rgb: { r: 0, g: 105, b: 76 }, hex: '#00694c' },
    { RAL: 6017, rgb: { r: 88, g: 127, b: 64 }, hex: '#587f40' },
    { RAL: 6018, rgb: { r: 97, g: 153, b: 59 }, hex: '#61993b' },
    { RAL: 6019, rgb: { r: 185, g: 206, b: 172 }, hex: '#b9ceac' }
  ],

  [
    { RAL: 6020, rgb: { r: 55, g: 66, b: 47 }, hex: '#37422f' },
    { RAL: 6021, rgb: { r: 138, g: 153, b: 119 }, hex: '#8a9977' },
    { RAL: 6022, rgb: { r: 58, g: 51, b: 39 }, hex: '#3a3327' },
    { RAL: 6024, rgb: { r: 0, g: 131, b: 81 }, hex: '#008351' },
    { RAL: 6025, rgb: { r: 94, g: 110, b: 59 }, hex: '#5e6e3b' }
  ],

  [
    { RAL: 6026, rgb: { r: 0, g: 95, b: 78 }, hex: '#005f4e' },
    { RAL: 6027, rgb: { r: 126, g: 186, b: 181 }, hex: '#7ebab5' },
    { RAL: 6028, rgb: { r: 49, g: 84, b: 66 }, hex: '#315442' },
    { RAL: 6029, rgb: { r: 0, g: 111, b: 61 }, hex: '#006f3d' },
    { RAL: 6032, rgb: { r: 35, g: 127, b: 82 }, hex: '#237f52' }
  ],

  [
    { RAL: 6033, rgb: { r: 70, g: 135, b: 127 }, hex: '#46877f' },
    { RAL: 6034, rgb: { r: 122, g: 172, b: 172 }, hex: '#7aacac' },
    { RAL: 6035, rgb: { r: 25, g: 77, b: 37 }, hex: '#194d25' },
    { RAL: 6036, rgb: { r: 4, g: 87, b: 75 }, hex: '#04574b' },
    { RAL: 6037, rgb: { r: 0, g: 139, b: 41 }, hex: '#008b29' }
  ],

  [
    { RAL: 6038, rgb: { r: 0, g: 181, b: 26 }, hex: '#00b51a' },
    { RAL: 7000, rgb: { r: 122, g: 136, b: 142 }, hex: '#7a888e' },
    { RAL: 7001, rgb: { r: 140, g: 150, b: 157 }, hex: '#8c969d' },
    { RAL: 7002, rgb: { r: 129, g: 120, b: 99 }, hex: '#817863' },
    { RAL: 7003, rgb: { r: 122, g: 118, b: 105 }, hex: '#7a7669' }
  ],

  [
    { RAL: 7004, rgb: { r: 155, g: 155, b: 155 }, hex: '#9b9b9b' },
    { RAL: 7005, rgb: { r: 108, g: 110, b: 107 }, hex: '#6c6e6b' },
    { RAL: 7006, rgb: { r: 118, g: 106, b: 94 }, hex: '#766a5e' },
    { RAL: 7008, rgb: { r: 116, g: 94, b: 61 }, hex: '#745e3d' },
    { RAL: 7009, rgb: { r: 93, g: 96, b: 88 }, hex: '#5d6058' }
  ],

  [
    { RAL: 7010, rgb: { r: 88, g: 92, b: 86 }, hex: '#585c56' },
    { RAL: 7011, rgb: { r: 82, g: 89, b: 93 }, hex: '#52595d' },
    { RAL: 7012, rgb: { r: 87, g: 93, b: 94 }, hex: '#575d5e' },
    { RAL: 7013, rgb: { r: 87, g: 80, b: 68 }, hex: '#575044' },
    { RAL: 7015, rgb: { r: 79, g: 83, b: 88 }, hex: '#4f5358' }
  ],

  [
    { RAL: 7016, rgb: { r: 56, g: 62, b: 66 }, hex: '#383e42' },
    { RAL: 7021, rgb: { r: 47, g: 50, b: 52 }, hex: '#2f3234' },
    { RAL: 7022, rgb: { r: 76, g: 74, b: 68 }, hex: '#4c4a44' },
    { RAL: 7023, rgb: { r: 128, g: 128, b: 118 }, hex: '#808076' },
    { RAL: 7024, rgb: { r: 69, g: 73, b: 78 }, hex: '#45494e' }
  ],

  [
    { RAL: 7026, rgb: { r: 55, g: 67, b: 69 }, hex: '#374345' },
    { RAL: 7030, rgb: { r: 146, g: 142, b: 133 }, hex: '#928e85' },
    { RAL: 7031, rgb: { r: 91, g: 104, b: 109 }, hex: '#5b686d' },
    { RAL: 7032, rgb: { r: 181, g: 176, b: 161 }, hex: '#b5b0a1' },
    { RAL: 7033, rgb: { r: 127, g: 130, b: 116 }, hex: '#7f8274' }
  ],

  [
    { RAL: 7034, rgb: { r: 146, g: 136, b: 111 }, hex: '#92886f' },
    { RAL: 7035, rgb: { r: 197, g: 199, b: 196 }, hex: '#c5c7c4' },
    { RAL: 7036, rgb: { r: 151, g: 147, b: 146 }, hex: '#979392' },
    { RAL: 7037, rgb: { r: 122, g: 123, b: 122 }, hex: '#7a7b7a' },
    { RAL: 7038, rgb: { r: 176, g: 176, b: 169 }, hex: '#b0b0a9' }
  ],

  [
    { RAL: 7039, rgb: { r: 107, g: 102, b: 94 }, hex: '#6b665e' },
    { RAL: 7040, rgb: { r: 152, g: 158, b: 161 }, hex: '#989ea1' },
    { RAL: 7042, rgb: { r: 142, g: 146, b: 145 }, hex: '#8e9291' },
    { RAL: 7043, rgb: { r: 79, g: 82, b: 80 }, hex: '#4f5250' },
    { RAL: 7044, rgb: { r: 183, g: 179, b: 168 }, hex: '#b7b3a8' }
  ],

  [
    { RAL: 7045, rgb: { r: 141, g: 146, b: 149 }, hex: '#8d9295' },
    { RAL: 7046, rgb: { r: 127, g: 134, b: 138 }, hex: '#7f868a' },
    { RAL: 7047, rgb: { r: 200, g: 200, b: 199 }, hex: '#c8c8c7' },
    { RAL: 7048, rgb: { r: 129, g: 123, b: 115 }, hex: '#817b73' },
    { RAL: 8000, rgb: { r: 137, g: 105, b: 62 }, hex: '#89693e' }
  ],

  [
    { RAL: 8001, rgb: { r: 157, g: 98, b: 43 }, hex: '#9d622b' },
    { RAL: 8002, rgb: { r: 121, g: 77, b: 62 }, hex: '#794d3e' },
    { RAL: 8003, rgb: { r: 126, g: 75, b: 38 }, hex: '#7e4b26' },
    { RAL: 8004, rgb: { r: 141, g: 73, b: 49 }, hex: '#8d4931' },
    { RAL: 8007, rgb: { r: 112, g: 69, b: 42 }, hex: '#70452a' }
  ],

  [
    { RAL: 8008, rgb: { r: 114, g: 74, b: 37 }, hex: '#724a25' },
    { RAL: 8011, rgb: { r: 90, g: 56, b: 38 }, hex: '#5a3826' },
    { RAL: 8012, rgb: { r: 102, g: 51, b: 43 }, hex: '#66332b' },
    { RAL: 8014, rgb: { r: 74, g: 53, b: 38 }, hex: '#4a3526' },
    { RAL: 8015, rgb: { r: 94, g: 47, b: 38 }, hex: '#5e2f26' }
  ],

  [
    { RAL: 8016, rgb: { r: 76, g: 43, b: 32 }, hex: '#4c2b20' },
    { RAL: 8017, rgb: { r: 68, g: 47, b: 41 }, hex: '#442f29' },
    { RAL: 8019, rgb: { r: 61, g: 54, b: 53 }, hex: '#3d3635' },
    { RAL: 8022, rgb: { r: 26, g: 23, b: 24 }, hex: '#1a1718' },
    { RAL: 8023, rgb: { r: 164, g: 87, b: 41 }, hex: '#a45729' }
  ],

  [
    { RAL: 8024, rgb: { r: 121, g: 80, b: 56 }, hex: '#795038' },
    { RAL: 8025, rgb: { r: 117, g: 88, b: 71 }, hex: '#755847' },
    { RAL: 8028, rgb: { r: 81, g: 58, b: 42 }, hex: '#513a2a' },
    { RAL: 8029, rgb: { r: 127, g: 64, b: 49 }, hex: '#7f4031' },
    { RAL: 9001, rgb: { r: 233, g: 224, b: 210 }, hex: '#e9e0d2' }
  ],

  [
    { RAL: 9002, rgb: { r: 215, g: 213, b: 203 }, hex: '#d7d5cb' },
    { RAL: 9003, rgb: { r: 236, g: 236, b: 231 }, hex: '#ecece7' },
    { RAL: 9004, rgb: { r: 43, g: 43, b: 44 }, hex: '#2b2b2c' },
    { RAL: 9005, rgb: { r: 14, g: 14, b: 16 }, hex: '#0e0e10' },
    { RAL: 9006, rgb: { r: 161, g: 161, b: 160 }, hex: '#a1a1a0' }
  ],

  [
    { RAL: 9007, rgb: { r: 135, g: 133, b: 129 }, hex: '#878581' },
    { RAL: 9010, rgb: { r: 241, g: 236, b: 225 }, hex: '#f1ece1' },
    { RAL: 9011, rgb: { r: 39, g: 41, b: 43 }, hex: '#27292b' },
    { RAL: 9016, rgb: { r: 241, g: 240, b: 234 }, hex: '#f1f0ea' },
    { RAL: 9017, rgb: { r: 42, g: 41, b: 42 }, hex: '#2a292a' }
  ],

  [
    { RAL: 9018, rgb: { r: 200, g: 203, b: 196 }, hex: '#c8cbc4' },
    { RAL: 9022, rgb: { r: 133, g: 133, b: 131 }, hex: '#858583' },
    { RAL: 9023, rgb: { r: 121, g: 123, b: 122 }, hex: '#797b7a' }
  ]
];

export default ralColors;
