import { Device } from 'types';

type Key = 'id' | 'translation';

export const findDeviceFromList = (list: Device[], param: string, key?: Key, langId?: string): Device | null => {
  if (!param || !Array.isArray(list) || list.length === 0) return null;
  if (!key) return list.find(d => d.id === param);
  if (key === 'translation' && !langId) {
    return null;
  }

  return list.find(d => d.translations[langId].name === param);
};
