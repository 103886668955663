import { OrganizationDataContext } from 'page/Organization/context';
import { useContext } from 'react';
import { useOrganizationData } from '../hooks/useOrganizationData';
import { useSelectedUser } from '../hooks/useSelectedUser';

export const OrganizationDataProvider: React.FC = ({ children }) => {
  const { loading, setOrganizationData, refetchData, organizationData } = useOrganizationData();
  const { setSelectedUser, selectedUser } = useSelectedUser();
  return (
    <OrganizationDataContext.Provider value={{ refetchData, organizationData, loading, selectedUser, setSelectedUser, setOrganizationData }}>
      {children}
    </OrganizationDataContext.Provider>
  );
};

export const useOrganizationContext = () => useContext(OrganizationDataContext);
