import * as BABYLON from 'babylonjs';
import BlockBoard from 'page/Editor/configuration/BlockBoard';
import Scene from 'page/Editor/Scene';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import BasicUtils from '../util/BasicUtils';
import LoaderUtils from '../util/LoaderUtils';
import MarkUtils from '../util/MarkUtils';
import DeviceNode from './DeviceNode';

export default class BlockBoardNode extends BABYLON.TransformNode {
  private _blockBoard: BlockBoard;
  private base: BABYLON.TransformNode;
  private generics: BABYLON.TransformNode;
  private output: BABYLON.TransformNode;
  private mark: BABYLON.TransformNode;
  private markEnabled = false;

  constructor(blockBoard: BlockBoard) {
    super('board', Scene.CURRENT_SCENE, undefined);
    // console.log('BlockBoardNode', 'constructor', blockBoard);
    this._blockBoard = blockBoard;
    this.getBlock().getNode().setBlockBoardNode(this);
    this.output = new BABYLON.TransformNode('output', Scene.CURRENT_SCENE);
    this.output.parent = this;

    if (blockBoard.getDeviceId()) {
      LoaderUtils.load(`${process.env.REACT_APP_API_URL}/device/get/${blockBoard.getDeviceId()}/model/object`, lr => {
        const base = lr.model;
        base.name = 'board.' + this.uniqueId;
        base.parent = DeviceNode.defaultSettings.modelNode;
        {
          const node = BasicUtils.findFirstChild('Tray', base);
          const left = BasicUtils.findFirstChild('Left', node);
          const right = BasicUtils.clone(left, 'Right', node);
          right.scaling.x = -1;
        }
        {
          const node = BasicUtils.findFirstChild('Piller', base);
          const left = BasicUtils.findFirstChild('Left', node);
          BasicUtils.clone(left, 'Right', node);
          BasicUtils.clone(left, 'Center', node);
        }

        base.setEnabled(false);
        this.base = base;
        this.bake();
      });
    }

    LoaderUtils.load('/gltf/generic_board.gltf', lr => {
      const generics = lr.model;
      generics.name = 'board.' + this.uniqueId;
      generics.parent = DeviceNode.defaultSettings.modelNode;
      const cloneLeft = ['Salamander', 'Faucet'];
      for (let i = 0; i < cloneLeft.length; i++) {
        const name = cloneLeft[i];
        const node = BasicUtils.findFirstChild(name, generics);
        const left = BasicUtils.findFirstChild('Left', node);
        const right = BasicUtils.clone(left, 'Right', node);
        right.scaling.x = -1;
      }
      {
        const node = BasicUtils.findFirstChild('FaucetTray', generics);
        const left = BasicUtils.findFirstChild('Left', node);
        const right = BasicUtils.clone(left, 'Right', node);
        right.scaling.x = -1;
        right.scaling.z = -1;
      }
      generics.setEnabled(false);
      this.generics = generics;
      this.bake();
    });
  }

  public getBlockBoard() {
    return this._blockBoard;
  }

  public isEnabledMark() {
    return this.markEnabled;
  }

  public setEnabledMark(value: boolean) {
    this.markEnabled = value;
    if (this.mark) this.mark.setEnabled(value);
  }

  public getBounds() {
    return BasicUtils.getBounds(this.output);
  }

  public reposition() {
    this.output.position.z = -this.getBlock().getDepthExtension() / 20;
  }

  public getBlock() {
    return this.getBlockBoard().getBlock();
  }

  public bake() {
    HighPerformanceQueue.push(this.uniqueId, () => {
      const base = this.base;
      const generics = this.generics;
      if (true) {
        const width = this.getBlock().getWidth();
        const nodes: Array<BABYLON.Node> = [];
        // Position Top
        // if (generics) {
        //   generics.position.y = this.getBlock().getBottomHeight();
        //   BasicUtils.computeAllWorldMatrix(generics);
        // }
        // if (base) {
        //   base.position.y = this.getBlock().getBottomHeight();
        //   BasicUtils.computeAllWorldMatrix(base);
        // }

        // Create Temporary Render Node
        const renderNode = new BABYLON.TransformNode(BasicUtils.generateRandomString(6), this.getScene());
        renderNode.parent = DeviceNode.defaultSettings.modelNode;
        renderNode.position.y = this.getBlock().getBottomHeight();
        //renderNode.position.x = -width / 10;
        nodes.push(renderNode);

        // Get Error Material
        const materialMetalError = this.getScene().getMaterialByName('_metal_error');

        // Render Array
        const renderArray = this._blockBoard.getRenderArray();
        renderArray.forEach(e => {
          let source: BABYLON.TransformNode;
          switch (e.source) {
            case 'Base':
              if (base) source = base;
              break;
            case 'Generic':
              if (generics) source = generics;
              break;
          }
          if (source) {
            const baseNode = BasicUtils.findFirstChild(e.item, source);
            if (!baseNode) {
              console.error(`Can not find '${e.item}' from '${e.source}' @ BlockBoardNode:bake()`);
              return;
            }
            const node = BasicUtils.clone(baseNode, undefined, renderNode);
            node.position.x = e.position / 10;

            switch (e.style) {
              case 'None':
                // Nothing to do
                break;
              case 'Error':
                node.getChildMeshes().forEach(mesh => {
                  if (mesh.material && mesh.material.name === '_metal') mesh.material = materialMetalError;
                });
                break;
            }
          }
        });

        this.position.x = -width / 20;
        this.reposition();
        BasicUtils.computeAllWorldMatrix(renderNode);
        BasicUtils.computeAllWorldMatrix(this);

        // Clear old output
        {
          const children = this.output.getChildren();
          for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child instanceof BABYLON.AbstractMesh) {
              for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
                const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
                shadowGenerator.removeShadowCaster(child);
              }
            }
            child.dispose();
          }
        }

        // Merge
        const materialMap = new Map<string, BABYLON.Mesh[]>();
        const materialMapKeys = new Array<string>();
        for (let i = 0; i < nodes.length; i++) {
          const element = nodes[i];
          const childMeshes = element.getChildMeshes();
          for (let i = 0; i < childMeshes.length; i++) {
            const mesh = childMeshes[i];
            // console.log('check', mesh.name, mesh.material ? mesh.material.name : '', mesh);
            if (mesh instanceof BABYLON.Mesh && mesh.material) {
              if (!materialMap.has(mesh.material.name)) {
                materialMap.set(mesh.material.name, []);
                materialMapKeys.push(mesh.material.name);
              }
              if (!base && mesh.name === 'salamnder_end_extension') continue;
              materialMap.get(mesh.material.name).push(mesh);
            }
          }
        }
        for (let i = 0; i < materialMapKeys.length; i++) {
          // console.log('.....................................');
          const mat = materialMapKeys[i];
          const meshes = materialMap.get(mat);
          // console.log('merge', mat, meshes);
          // const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true);
          const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true, undefined, false);
          mergedMesh.name = 'mesh-' + mat;
          mergedMesh.parent = this.output;
          for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
            const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
            shadowGenerator.addShadowCaster(mergedMesh);
          }
          for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
            const mirror = DeviceNode.defaultSettings.mirrors[i];
            mirror.renderList.push(mergedMesh);
          }
          mergedMesh.receiveShadows = true;
          if (mat === '_metal_error') mergedMesh.renderOverlay = true;
          else if (this.getBlock().isFullBlendColor() && mergedMesh.name === 'mesh-_metal_blue_panel') {
            mergedMesh.material = this.getBlock().getBlendColorMaterial();
          } else if (mergedMesh.name === 'mesh-_metal_blue_panel_top') {
            mergedMesh.material = this.getBlock().getBlendColorMaterial();
          }
        }

        // if (centerNode) centerNode.dispose();
        if (renderNode) renderNode.dispose();

        const bounds = this.getBounds();
        // Add Marks
        this.mark = MarkUtils.buildMark({
          width: bounds.width,
          height: bounds.height,
          depth: bounds.depth,
          offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
          parent: this.output
        });
        this.mark.setEnabled(this.isEnabledMark());
      }
      return true;
    });
  }

  dispose() {
    this.getBlock().getNode().setBlockBoardNode(null);
    super.dispose();
  }
}
