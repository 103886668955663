import { useCallback, useContext, useEffect, useState } from 'react';
import { Device, Subtype } from 'types';
import { isSubtype, post } from 'utils';
import { EditorContext } from 'context';
import FreeItem from 'page/Editor/configuration/FreeItem';
import { EquipmentHelper } from '../../../../configuration/Equipment';

type UsedDevicesKeys = 'flexiCombiAir' | 'magicHood' | 'flexiCombiDoor';

export const useSpacecombiSubtypes = () => {
  const { selection } = useContext(EditorContext);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [usedFlexiCombiAirDevices, setUsedFlexiCombiAirDevices] = useState<Device[]>([]);
  const [usedFlexiCombiDoorDevices, setUsedFlexiCombiDoorDevices] = useState<Device[]>([]);
  const [usedMagicHoodDevices, setUsedMagicHoodDevices] = useState<Device[]>([]);

  const existingSubtypes = ['FlexiCombiAir', 'MagicHood', 'FlexiCombiDoor'];

  const [usedDevices, setUsedDevices] = useState({
    flexiCombiAir: false,
    flexiCombiDoor: false,
    magicHood: false
  });

  const toggleSelection = useCallback(
    (key: UsedDevicesKeys) => {
      if (selection instanceof FreeItem) {
        setUsedDevices({ ...usedDevices, [key]: !usedDevices[key] });
        switch (key) {
          case 'flexiCombiAir':
            if (usedDevices?.flexiCombiAir) {
              EquipmentHelper.setFlexiCombiAir(selection, null);
            } else {
              EquipmentHelper.setFlexiCombiAir(selection, usedFlexiCombiAirDevices[0]);
            }
            break;
          case 'magicHood':
            if (usedDevices?.magicHood) {
              EquipmentHelper.setMagicHood(selection, null);
            } else {
              EquipmentHelper.setMagicHood(selection, usedMagicHoodDevices[0]);
            }
            break;
          case 'flexiCombiDoor':
            if (usedDevices?.flexiCombiDoor) {
              EquipmentHelper.setFlexiCombiDoor(selection, null);
            } else {
              EquipmentHelper.setFlexiCombiDoor(selection, usedFlexiCombiDoorDevices[0]);
            }
            break;

          default:
            break;
        }
      }
    },
    [selection, usedDevices, usedFlexiCombiAirDevices, usedMagicHoodDevices]
  );

  // Init
  useEffect(() => {
    if (selection instanceof FreeItem) {
      setUsedDevices({
        ...usedDevices,
        flexiCombiAir: !!EquipmentHelper.getFlexiCombiAir(selection),
        flexiCombiDoor: !!EquipmentHelper.getFlexiCombiDoor(selection),
        magicHood: !!EquipmentHelper.getMagicHood(selection)
      });
      setUsedFlexiCombiAirDevices(getUsedFlexiCombiAirDevices(selection, availableDevices));
      setUsedFlexiCombiDoorDevices(getUsedFlexiCombiDoorDevices(selection, availableDevices));
      setUsedMagicHoodDevices(getUsedMagicHoodDevices(selection, availableDevices));
    }
  }, [selection, availableDevices]);

  // Get Devices
  useEffect(() => {
    let isActive = true;
    const SESSION_KEY = 'spacecombi-subtype-devices';
    const getSubtypes = async () => {
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          //type: 'spaceCombi',
          //style: 'Styler',
          subtypes: existingSubtypes
        }
      });
      if (data && isActive) {
        setAvailableDevices(data);
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(data));
      } else if (error) {
        console.log('Error fetching subtypes: ', error);
      }
    };
    const cachedDevices = sessionStorage.getItem(SESSION_KEY);
    if (cachedDevices) {
      setAvailableDevices(JSON.parse(cachedDevices));
    } else {
      getSubtypes();
    }
    return () => {
      isActive = false;
    };
  }, []);

  return {
    availableDevices,
    canHaveFlexiCombiAir:
      selection instanceof FreeItem &&
      EquipmentHelper.canHaveFlexiCombiAir(selection) &&
      Array.isArray(usedFlexiCombiAirDevices) &&
      usedFlexiCombiAirDevices[0],
    canHaveFlexiCombiDoor:
      selection instanceof FreeItem &&
      EquipmentHelper.canHaveFlexiCombiDoor(selection) &&
      Array.isArray(usedFlexiCombiDoorDevices) &&
      usedFlexiCombiDoorDevices[0],
    canHaveMagicHood:
      selection instanceof FreeItem && EquipmentHelper.canHaveMagicHood(selection) && Array.isArray(usedMagicHoodDevices) && usedMagicHoodDevices[0],
    toggleSelection,
    usedDevices
  };
};

const getUsedFlexiCombiAirDevices = (selection: FreeItem, devices: Device[]): Device[] => {
  const style = selection.getDeviceObject().style;
  const params = new URLSearchParams(style);
  const deviceCode = params.get('FC');
  const filteredDevices = devices.filter(d => isSubtype(d.subtype, [Subtype.FlexiCombiAir]) && d.code === deviceCode);
  return filteredDevices;
};

const getUsedFlexiCombiDoorDevices = (selection: FreeItem, devices: Device[]): Device[] => {
  /* const style = selection.getDeviceObject().style;
  const params = new URLSearchParams(style);
  const deviceCode = params.get('FCD_01'); */
  const filteredDevices = devices.filter(d => isSubtype(d.subtype, [Subtype.FlexiCombiDoor]) /* && d.code === deviceCode */);
  return filteredDevices;
};

const getUsedMagicHoodDevices = (selection: FreeItem, devices: Device[]): Device[] => {
  const isJunior = selection?.getDeviceObject()?.style?.includes('Junior');
  const filteredDevices = devices.filter(d => isSubtype(d.subtype, [Subtype.MagicHood]));
  const device = isJunior ? filteredDevices.find(d => d.style.includes('Junior')) : filteredDevices.find(d => !d.style.includes('Junior'));
  return [device];
};
