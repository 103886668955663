import { RoomPillarNode } from 'components/babylon/node/RoomPillarNode';
import Room, { InnerRoom } from './Room';

export class RoomPillar extends InnerRoom {
  public clazz = 'RoomPillar';

  private _node: RoomPillarNode;

  constructor(room: Room) {
    super(room);
    this._node = new RoomPillarNode(this);

    this.setWidth(400);
    this.setDepth(400);
    this._node.updateHeight();
  }

  public getNode() {
    return this._node;
  }
}
