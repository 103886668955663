// Components
import { BlockSelector } from 'components';
// Context
import { EditorContext, useUser } from 'context';
import { CategoryMenu, ContextMenu, SeriesFilter } from 'page/Editor/Menu/Shared';
import React, { FC, Fragment, useContext } from 'react';
// Types
import { Category } from 'types';
import { useIsSingleFree } from 'utils';

// ===================================================================

interface Props {
  categories: Category[];
  selectedCategory: Category;
  setSelectedCategory: (category: Category) => void;
}

// ===================================================================
export const MasterlineFunctional: FC<Props> = ({ categories, setSelectedCategory }) => {
  const { setMasterlineView } = useContext(EditorContext);
  const { hasFlexiAccess } = useUser();
  const isSingleFree = useIsSingleFree();
  const filteredCategories =
    hasFlexiAccess && !isSingleFree
      ? categories
      : categories.filter(c => {
          const translations = Object.values(c.translations);
          return !translations || !!(translations && translations.findIndex(({ name }: { name: string }) => name?.includes('Flexi')));
        });
  return (
    <Fragment>
      <ContextMenu>
        <div className="Functional-View-ContextMenu">
          <SeriesFilter />
        </div>
      </ContextMenu>
      <div style={{ marginTop: '3rem' }}>
        <BlockSelector />
      </div>
      <div style={{ paddingTop: '1rem' }}>
        <ContextMenu mode="back" onClick={() => setMasterlineView('home')} style={{ marginBottom: '1rem' }} />
      </div>

      <div className="Custom-Scroll h-100" style={{ maxHeight: 'calc(100% - 150px)' }}>
        {filteredCategories &&
          filteredCategories.length > 0 &&
          filteredCategories.map((c, index) => {
            return (
              <CategoryMenu key={`Modular-Category-${c.id}`} category={c} isLast={index === categories.length - 1} onSelect={() => setSelectedCategory(c)} />
            );
          })}
      </div>
    </Fragment>
  );
};
