import React, { FC } from 'react';

// Styles:
import { colors } from 'styles/theme';

// Atoms:
import { ASimple, Icon } from 'atoms';

// ===================================================================
interface Props {
  id: string;
  centered?: boolean;
  status: boolean;
  setStatus: (status: boolean) => void;
  label?: string | null;
  labelColor?: string;
  labelFontSize?: string;
  boxSize?: string;
}

// ===================================================================
export const Checkbox: FC<Props> = ({
  centered = true,
  id,
  status,
  setStatus,
  label = null,
  boxSize = '1.75rem',
  labelColor = 'grey',
  labelFontSize = '1rem'
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: centered ? 'center' : 'flex-start'
    }}
  >
    {/* Label */}
    {label && (
      <label
        onClick={() => setStatus(!status)}
        htmlFor={id}
        style={{
          fontSize: labelFontSize,
          cursor: 'pointer',
          color: colors[labelColor],
          fontWeight: 500,
          display: 'flex'
        }}
      >
        {/* Box */}
        <div
          id={id}
          style={{
            alignItems: 'center',
            background: status ? colors.lightBlue : colors.transparent,
            border: 'solid 2px ' + (status ? colors.lightBlue : colors.disabled),
            cursor: 'pointer',
            display: 'flex',
            height: boxSize,
            justifyContent: 'center',
            marginRight: '1rem',
            width: boxSize
          }}
          onClick={() => setStatus(!status)}
        >
          {status && (
            <ASimple
              key="Checkbox"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Icon type="check" size="70%" />
            </ASimple>
          )}
        </div>

        {label}
      </label>
    )}
  </div>
);
