import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Icon } from 'atoms';

// Context:
import { EditorContext, LangContext } from 'context';

// Components:
import { LoadingSpinner } from 'components';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';
import { colors } from 'styles/theme';

// ===================================================================
interface Props {}

// ===================================================================
export const LoadingErrorHint: FC<Props> = () => {
  const { showLoadingError, setShowLoadingError } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const [loading] = useState<boolean>(false);
  const history = useHistory();

  /* Methods */
  const close = () => {
    history.push('/');
    setShowLoadingError(false);
  };

  /* Render */
  return (
    <Portal>
      {/* Background */}
      {showLoadingError && <ASimple key="LoadingError-Background" className="LoadingError-Background" style={{ height: window.innerHeight }} onClick={close} />}

      {/* Content */}
      {showLoadingError && (
        <ADialog key="LoadingError-Content" className="LoadingError-Content" topExit="0" topEnter="50%">
          {/* Close Icon */}
          <div className="LoadingError-Content-Icon">
            <Icon type="close" size="1.25rem" color="grey" onClick={close} />
          </div>

          {/* Main */}
          <div className="LoadingError-Content-Main">
            <Heading level="1" color="dark">
              {Localizations['notFound'][lang]}
            </Heading>

            <p style={{ color: colors.medium, flex: 1, fontSize: '.9rem', letterSpacing: '0.3px', lineHeight: 1.5 }}>{Localizations['notFoundDesc'][lang]}</p>

            <Button btnType="third" maxWidth={400} onClick={close} margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
              {loading ? <LoadingSpinner size="1rem" /> : Localizations['toMenu'][lang]}
            </Button>
          </div>
        </ADialog>
      )}
    </Portal>
  );
};
