import axios from 'axios';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import Equipment from 'page/Editor/configuration/Equipment';
import Scene from 'page/Editor/Scene';
import { DeviceComponent } from 'types/Device';
import BlockGroupNode from '../node/BlockGroupNode';
import BlockNode from '../node/BlockNode';
import DeviceNode from '../node/DeviceNode';
import { OptionNode } from '../node/OptionNode';
import BasicUtils, { Bounds } from './BasicUtils';

export type ImportSettings = {
  scaling?: BABYLON.Vector3;
  rotation?: BABYLON.Vector3;
  shadowGenerators?: BABYLON.ShadowGenerator[];
  mirrors?: BABYLON.AbstractMesh[];
  pluginExtension?: string;
};

export type LoadReturn = {
  bounds: Bounds;
  model: BABYLON.TransformNode;
};

export default class LoaderUtils {
  private static defaultSettings: ImportSettings = {
    scaling: new BABYLON.Vector3(1, 1, 1),
    rotation: new BABYLON.Vector3(0, 0, 0),
    pluginExtension: '.gltf'
  };

  public static initialize(): void {
    if (LoaderUtils.loadedNode && !LoaderUtils.loadedNode.isDisposed()) LoaderUtils.loadedNode.dispose();
    LoaderUtils.loadedNode = new BABYLON.TransformNode('_loader', Scene.CURRENT_SCENE);
    LoaderUtils.loadedNode.setEnabled(false);

    LoaderUtils.loading.clear();
    LoaderUtils.loaded.clear();
  }

  private static loading = new Map<string, Array<{ callback: (r: LoadReturn) => void; error: () => void }>>();
  private static loaded = new Map<string, BABYLON.TransformNode>();
  private static loadedNode: BABYLON.TransformNode = null;

  public static async load(obj: string, callback?: (r: LoadReturn) => void, error?: () => void): Promise<LoadReturn> {
    // Check if model has already been loaded
    if (LoaderUtils.loaded.has(obj)) {
      return new Promise((resolve, reject) => {
        const model = LoaderUtils.loaded.get(obj);
        const r = {
          bounds: BasicUtils.getBounds(model),
          model: BasicUtils.clone(model)
        };
        if (callback) {
          callback(r);
          resolve(null);
        } else resolve(r);
      });
    }
    // Check if model is loading
    else if (LoaderUtils.loading.has(obj)) {
      LoaderUtils.loading.get(obj).push({ callback: callback, error: error });
      return new Promise((resolve, reject) => {
        const loading = () => {
          if (LoaderUtils.loaded.has(obj)) {
            if (callback) resolve(null);
            else {
              const model = LoaderUtils.loaded.get(obj);
              const r = {
                bounds: BasicUtils.getBounds(model),
                model: BasicUtils.clone(model)
              };
              resolve(r);
            }
          } else {
            setTimeout(loading, 100);
          }
        };
        setTimeout(loading, 100);
      });
    }
    // Load model
    else {
      LoaderUtils.loading.set(obj, []);
      return new Promise((resolve, reject) => {
        const scaling = LoaderUtils.defaultSettings.scaling;
        const rotation = LoaderUtils.defaultSettings.rotation;
        const pluginExtension = LoaderUtils.defaultSettings.pluginExtension;

        var model = new BABYLON.TransformNode(obj, Scene.CURRENT_SCENE);

        // console.log('load', obj);

        axios
          .get(obj, {
            withCredentials: true,
            headers: {
              jwt: window.localStorage.getItem('jwt')
            }
          })
          .then(response => {
            const text = JSON.stringify(response.data);
            const materialsToDispose = new Map<number, BABYLON.Material>();
            BABYLON.SceneLoader.ImportMesh(
              '',
              '',
              'data:' + text,
              Scene.CURRENT_SCENE,
              (_meshes, _particleSystems, _skeletons, _animationGroups) => {
                // console.log('newMeshes', _meshes)
                for (var i = 0; i < _meshes.length; i++) {
                  const m = _meshes[i];
                  if (i < 1) {
                    m.parent = model;
                    m.position = BABYLON.Vector3.Zero();
                    m.scaling = scaling;
                    m.rotation = rotation;
                  }

                  m.cullingStrategy = BABYLON.Mesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;

                  // remove duplicated materials
                  if (m.material) {
                    m.material.backFaceCulling = false;
                    for (let i = 0; i < Scene.CURRENT_SCENE.materials.length; i++) {
                      const material = Scene.CURRENT_SCENE.materials[i];
                      if (material.name === '_' + m.material.name && material.uniqueId !== m.material.uniqueId) {
                        materialsToDispose.set(m.material.uniqueId, m.material);
                        m.material = material;
                        break;
                      }
                    }
                  }
                }

                materialsToDispose.forEach((_value, _key) => {
                  _value.dispose();
                });

                const t = model.getChildTransformNodes();
                for (let i = 0; i < t.length; i++) {
                  const e = t[i];
                  e.rotation = BABYLON.Vector3.Zero();
                }

                model.parent = LoaderUtils.loadedNode;

                LoaderUtils.loaded.set(obj, model);

                const r = {
                  bounds: BasicUtils.getBounds(model),
                  model: BasicUtils.clone(model)
                };

                // console.log('Loaded ' + obj, r);

                const asyncCallbacks = LoaderUtils.loading.get(obj);
                for (let i = 0; i < asyncCallbacks.length; i++) {
                  const asyncCallback = asyncCallbacks[i];
                  if (asyncCallback.callback) {
                    const ar = {
                      bounds: r.bounds,
                      model: BasicUtils.clone(model)
                    };
                    setTimeout(() => {
                      asyncCallback.callback(ar);
                    });
                  }
                }
                LoaderUtils.loading.delete(obj);
                if (callback) {
                  setTimeout(() => {
                    callback(r);
                  });
                  resolve(null);
                } else resolve(r);
              },
              undefined,
              undefined,
              pluginExtension
            );
          })
          .catch(reason => {
            // console.log('Unable to load ' + obj, reason);
            if (error)
              setTimeout(() => {
                error();
              });
            const asyncCallbacks = LoaderUtils.loading.get(obj);
            for (let i = 0; i < asyncCallbacks.length; i++) {
              const asyncCallback = asyncCallbacks[i];
              if (asyncCallback.error)
                setTimeout(() => {
                  asyncCallback.error();
                });
            }
            LoaderUtils.loading.delete(obj);
            model.dispose();
            reject(reason);
          });
      });
    }
  }

  public static loadBase(deviceNode: DeviceNode, success?: (node: DeviceNode) => void, error?: () => void): DeviceNode {
    LoaderUtils.load(
      '/gltf/generic_corpus.gltf',
      r => {
        deviceNode.addBase(r.model);
        if (success) success(deviceNode);
      },
      () => {
        if (error) error();
      }
    );
    return deviceNode;
  }

  public static loadBaseNOL(deviceNode: DeviceNode, success?: (node: DeviceNode) => void, error?: () => void): DeviceNode {
    LoaderUtils.load(
      '/gltf/generic_corpus_nol.gltf',
      r => {
        deviceNode.addBase(r.model);
        if (success) success(deviceNode);
      },
      () => {
        if (error) error();
      }
    );
    return deviceNode;
  }

  public static loadDevice(deviceNode: DeviceNode, success?: (node: DeviceNode) => void, error?: () => void): DeviceNode {
    LoaderUtils.load(
      `${process.env.REACT_APP_API_URL}/device/get/${deviceNode.getDeviceId()}/model/object`,
      (r: LoadReturn) => {
        deviceNode.setMain(r.model);
        if (success) success(deviceNode);
      },
      () => {
        if (error) error();
      }
    );
    return deviceNode;
  }

  public static loadComponent(
    node: DeviceNode | BlockGroupNode,
    component: DeviceComponent,
    success?: (node: OptionNode) => void,
    error?: () => void,
    equipment?: Equipment
  ): DeviceNode | BlockGroupNode {
    let url = `${process.env.REACT_APP_API_URL}/component/get/${component.component.id}/object`;
    // if (component.component.id === 'VKx8K') url = '/gltf/MKN_badge_NOL.gltf';
    // if (component.component.id === 'B4GR2') url = '/gltf/knob_NOL.gltf';
    LoaderUtils.load(
      url,
      (r: LoadReturn) => {
        let optionNode = new OptionNode(r.model, component);
        if (equipment) optionNode.setEquipment(equipment);
        optionNode = node.addOption(optionNode);
        if (success) success(optionNode);
      },
      () => {
        if (error) error();
      }
    );
    return node;
  }

  public static loadInstallationWall(blockNode: BlockNode, success?: (node: BABYLON.TransformNode) => void, error?: () => void): BlockNode {
    LoaderUtils.load(
      `${process.env.REACT_APP_API_URL}/device/get/${blockNode.getBlock().getInstallationWallId()}/model/object`,
      r => {
        blockNode.addInstallationWall(r.model);
        if (success) success(r.model);
      },
      () => {
        if (error) error();
      }
    );
    return blockNode;
  }

  public static isLoading() {
    return LoaderUtils.loading.size > 0;
  }
}
