import { EditorContext } from 'context';
import { UsedMasterlineSubtypeDevices } from 'page/Editor/Menu/Menus/Masterline/Partials/Substructures';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { useContext, useEffect } from 'react';

type Args = {
  selectedOven: any;
  setDisabledSwitches: React.Dispatch<React.SetStateAction<string[]>>;
  usedDevices: UsedMasterlineSubtypeDevices;
};

export const useUpdateDisables = ({ selectedOven, setDisabledSwitches, usedDevices }: Args) => {
  const { selection } = useContext(EditorContext);

  useEffect(() => {
    const updateDisables = () => {
      const disabled = [];
      if (selection instanceof BlockItem || selection instanceof BlockGroup) {
        if (!EquipmentHelper.canHaveAngularRail(selection)) disabled.push('AngularRail');
        if (!EquipmentHelper.canHaveCooling(selection)) disabled.push('Cooling');
        if (!EquipmentHelper.canHaveEmptyingSystem(selection)) disabled.push('EmptyingSystem');
        if (!EquipmentHelper.canHaveHeatingCabinet(selection)) disabled.push('HeatingCabinet');
        if (!EquipmentHelper.canHaveHeatingDrawer(selection)) disabled.push('HeatingDrawer');
        if (!EquipmentHelper.canHaveHygiene(selection)) disabled.push('Hygiene');
        if (!EquipmentHelper.canHaveLowerDrawer(selection)) disabled.push('LowerDrawer');
        if (!EquipmentHelper.canHaveOpenSubstructure(selection)) disabled.push('OpenSubstructure');
        if (!EquipmentHelper.canHaveWasteDrawer(selection)) disabled.push('WasteDrawer');
        if (!EquipmentHelper.canHaveWingedDoor(selection)) disabled.push('WingedDoor');
        if (!EquipmentHelper.canHaveGNFrame(selection)) disabled.push('GNFrame');
        if (!EquipmentHelper.canHaveWarmingDevice(selection)) disabled.push('WarmingDevice');
        if (!EquipmentHelper.canHaveWarmingDrawer(selection)) disabled.push('WarmingDrawer');
        if (!EquipmentHelper.canHaveIntermediatePlate(selection)) disabled.push('IntermediatePlate');
      }

      setDisabledSwitches(disabled);
    };
    if (selection) {
      /* if (selectedOven === Localizations['none'][lang]) {
        setDisabledSwitches([]);
      } else { */
      updateDisables();
      /* } */
    }
  }, [selectedOven, selection, usedDevices]);
};
