import { useCallback, useContext, useEffect, useState } from 'react';
import { Device, Subtype } from 'types';
import { post } from 'utils';
import { EditorContext } from 'context';
import FreeItem from 'page/Editor/configuration/FreeItem';
import { EquipmentHelper } from '../../../../configuration/Equipment';
import { isSubtype } from '../../../../../../utils/subtype';
import BlockItem from 'page/Editor/configuration/BlockItem';

type UsedDevicesKeys = 'baseCoverLeft' | 'baseCoverRight' | 'readyXPressLeft' | 'readyXPressRight' | 'spaceClean';
export const flexiChefSubtypes = ['BaseCover', 'ReadyXpress', 'SpaceClean'];

export const useFlexichefSubtypes = () => {
  const { selection } = useContext(EditorContext);
  const [loading, setLoading] = useState(false);
  const [availableSubtypeDevices, setAvailableSubtypeDevices] = useState<Device[]>([]);

  const [usedBaseCoverDevices, setUsedBaseCoverDevices] = useState<Device[]>([]);
  const [usedReadyXpressDevices, setUsedReadyXpressDevices] = useState<Device[]>([]);
  const [usedSpaceCleanDevices, setUsedSpaceCleanDevices] = useState<Device[]>([]);

  const [usedDevices, setUsedDevices] = useState({
    baseCoverLeft: false,
    baseCoverRight: false,
    readyXPressLeft: false,
    readyXPressRight: false,
    spaceClean: false
  });

  const toggleSelection = useCallback(
    (key: UsedDevicesKeys) => {
      setUsedDevices({ ...usedDevices, [key]: !usedDevices[key] });
      if (selection instanceof FreeItem || selection instanceof BlockItem) {
        switch (key) {
          case 'baseCoverLeft':
            if (usedDevices?.baseCoverLeft) {
              EquipmentHelper.setBaseCover(selection, null);
            } else {
              EquipmentHelper.setBaseCover(selection, usedBaseCoverDevices[0]);
            }
            break;
          case 'baseCoverRight':
            if (usedDevices?.baseCoverRight) {
              EquipmentHelper.setBaseCover(selection, null, 1);
            } else {
              EquipmentHelper.setBaseCover(selection, usedBaseCoverDevices[1], 1);
            }
            break;
          case 'readyXPressLeft':
            if (usedDevices?.readyXPressLeft) {
              EquipmentHelper.setReadyXpress(selection, null);
            } else {
              EquipmentHelper.setReadyXpress(selection, usedReadyXpressDevices[0]);
            }
            break;
          case 'readyXPressRight':
            if (usedDevices?.readyXPressRight) {
              EquipmentHelper.setReadyXpress(selection, null, 1);
            } else {
              EquipmentHelper.setReadyXpress(selection, usedReadyXpressDevices[1], 1);
            }
            break;
          case 'spaceClean':
            if (usedDevices?.spaceClean) {
              EquipmentHelper.setSpaceClean(selection, null);
            } else {
              EquipmentHelper.setSpaceClean(selection, usedSpaceCleanDevices[0]);
            }
            break;
          default:
            break;
        }
      }
    },
    [selection, usedBaseCoverDevices, usedSpaceCleanDevices, usedReadyXpressDevices, usedDevices]
  );

  // Init
  useEffect(() => {
    if (selection instanceof FreeItem || selection instanceof BlockItem) {
      setUsedDevices({
        ...usedDevices,
        baseCoverLeft: !!EquipmentHelper.getBaseCover(selection),
        baseCoverRight: !!EquipmentHelper.getBaseCover(selection, 1),
        readyXPressLeft: !!EquipmentHelper.getReadyXpress(selection),
        readyXPressRight: !!EquipmentHelper.getReadyXpress(selection, 1), // selection?.getDeviceObject()?.style?.includes('-'),
        spaceClean: !!EquipmentHelper.getSpaceClean(selection)
      });
      setUsedBaseCoverDevices(getUsedBaseCoverDevices(selection, availableSubtypeDevices));
      setUsedReadyXpressDevices(getUsedReadyXpressDevices(selection, availableSubtypeDevices));
      setUsedSpaceCleanDevices(getUsedSpaceCleanDevices(selection, availableSubtypeDevices));
    }
  }, [selection, availableSubtypeDevices]);

  // Get Devices
  useEffect(() => {
    let isActive = true;
    const SESSION_KEY = 'flexichef-subtype-devices';

    const getSubtypeDevices = async () => {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'flexiChef',
          subtypes: flexiChefSubtypes
        }
      });
      if (data && isActive) {
        setAvailableSubtypeDevices(data);
        sessionStorage.setItem(SESSION_KEY, JSON.stringify(data));
        setLoading(false);
      } else if (error) {
        console.log('Error fetching subtypes: ', error);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    const cachedSubtypeDevices = sessionStorage.getItem(SESSION_KEY);
    if (cachedSubtypeDevices) {
      setAvailableSubtypeDevices(JSON.parse(cachedSubtypeDevices));
    } else {
      getSubtypeDevices();
    }

    return () => {
      isActive = false;
    };
  }, []);

  return {
    canHaveBaseCover: Array.isArray(usedBaseCoverDevices) && usedBaseCoverDevices[0],
    canHaveBaseCoverRight: Array.isArray(usedBaseCoverDevices) && usedBaseCoverDevices[1],
    canHaveReadyXPress: Array.isArray(usedReadyXpressDevices) && usedReadyXpressDevices[0],
    canHaveReadyXPressRight: Array.isArray(usedReadyXpressDevices) && usedReadyXpressDevices[1],
    canHaveSpaceClean: Array.isArray(usedSpaceCleanDevices) && usedSpaceCleanDevices[0],
    toggleSelection,
    usedDevices,
    loading
  };
};

const getUsedBaseCoverDevices = (selection: FreeItem | BlockItem, availableDevices: Device[]): Device[] | null => {
  const style = selection?.getDeviceObject()?.style;
  const filteredDevices = availableDevices.filter(d => isSubtype(d.subtype, [Subtype.BaseCover]));

  if (style) {
    // Team
    const params = new URLSearchParams(style);
    if (style.includes('-')) {
      const S = params.get('S');
      const leftIndex = S.split('-')[0];
      const rightIndex = S.split('-')[1][0];
      const leftStyle = `S=${leftIndex}TL`;
      const rightStyle = `S=${rightIndex}TR`;

      const leftDevice = filteredDevices.find(d => d.style === leftStyle);
      const rightDevice = filteredDevices.find(d => d.style === rightStyle);

      return [leftDevice, rightDevice];
    } else {
      const leftStyle = params.get('S');
      const device = filteredDevices.find(d => d.style === `S=${leftStyle[0]}`);
      if (device) return [device];
      return [];
    }
  }
  return [];
};

const getUsedReadyXpressDevices = (selection: FreeItem | BlockItem, availableDevices: Device[]): Device[] | null => {
  const style = selection?.getDeviceObject()?.style;
  const filteredDevices = availableDevices.filter(d => isSubtype(d.subtype, [Subtype.ReadyXpress]));
  if (style) {
    // Team
    const params = new URLSearchParams(style);
    if (style.includes('-')) {
      const S = params.get('S');
      const leftIndex = S.split('-')[0];
      const rightIndex = S.split('-')[1][0];
      const leftStyle = `S=${leftIndex}TL`;
      const rightStyle = `S=${rightIndex}TR`;

      const leftDevice = filteredDevices.find(d => d.style === leftStyle);
      const rightDevice = filteredDevices.find(d => d.style === rightStyle);

      return [leftDevice, rightDevice];
    } else {
      const leftStyle = params.get('S');
      const device = filteredDevices.find(d => d.style.includes(`S=${leftStyle[0]}`));
      if (device) return [device];
      return [];
    }
  }
  return [];
};

const getUsedSpaceCleanDevices = (selection: FreeItem | BlockItem, availableDevices: Device[]): Device[] | null => {
  // Team oder nicht Team
  const style = selection?.getDeviceObject()?.style;
  const filteredDevices = availableDevices.filter(d => isSubtype(d.subtype, [Subtype.SpaceClean]));

  if (style) {
    // Team
    if (style.includes('-')) {
      const device = filteredDevices.find(d => d.style.includes('Team'));
      if (device) return [device];
      return [];
    } else {
      const device = filteredDevices.find(d => !d.style.includes('Team'));
      if (device) return [device];
      return [];
    }
  }
  return [];
};
