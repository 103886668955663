import React, { FC, useContext } from 'react';

// Atoms:
import { Icon } from 'atoms';

// Context
import { EditorContext, LangContext } from 'context';

// Language:
import Localization from 'languages';

// Types
import { MenuView } from 'types';

// ==============================================================
interface Props {
  mode: MenuView;
  reset: () => void;
}

// ==============================================================
export const ModeSelector: FC<Props> = ({ mode, reset }) => {
  const { menuView, setMenuView } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  const getClassName = (mode: MenuView) => (menuView === mode ? 'Header-Navigation-ItemActive' : 'Header-Navigation-ItemInactive');

  return (
    <button
      className={getClassName(mode)}
      onClick={() => {
        setMenuView(mode);
        reset();
      }}
    >
      {mode === 'room' && (
        <>
          <Icon type={menuView === mode ? 'roomActive' : 'roomInactive'} style={{ cursor: 'pointer', height: '28px', marginBottom: '2px' }} />
          <div className="bold">{Localization['room'][lang]}</div>
        </>
      )}
      {mode === 'flexiChef' && (
        <>
          <Icon type={menuView === mode ? 'flexichefActive' : 'flexichefInactive'} style={{ cursor: 'pointer', height: '30px' }} />
          <div className="bold">{Localization['flexichef'][lang]}</div>
        </>
      )}
      {mode === 'spaceCombi' && (
        <>
          <Icon
            type={menuView === mode ? 'kombiActive' : 'kombiInactive'}
            style={{ cursor: 'pointer', height: '24px', marginBottom: '3px', marginTop: '3px' }}
          />
          <div className="bold">{Localization['spacekombi'][lang]}</div>
        </>
      )}
      {mode === 'modular' && (
        <>
          <Icon type={menuView === mode ? 'modularActive' : 'modularInactive'} style={{ cursor: 'pointer', height: '30px' }} />
          <div className="bold">{Localization['modular'][lang]}</div>
        </>
      )}
      {mode === 'modularNOL' && (
        <>
          <Icon type={menuView === mode ? 'modularActive' : 'modularInactive'} style={{ cursor: 'pointer', height: '30px' }} />
          <div className="bold">{Localization['modularNOL'][lang]}</div>
        </>
      )}
      {mode === 'masterline' && (
        <>
          <Icon type={menuView === mode ? 'masterlineActive' : 'masterlineInactive'} style={{ cursor: 'pointer', height: '30px' }} />
          <div className="bold">{Localization['masterline'][lang]}</div>
        </>
      )}
      {mode === 'marineMeister' && (
        <>
          <Icon type={menuView === mode ? 'marineMeisterActive' : 'marineMeister'} style={{ cursor: 'pointer', height: '30px' }} />
          <div className="bold">{Localization['marineMeister'][lang]}</div>
        </>
      )}
    </button>
  );
};
