import React from 'react';

import './style.scss';

interface Props {
  pose?: 'active' | 'inactive';
}

export const AConfigSearchbar: React.FC<Props> = ({ children, pose }) => {
  return <div className={pose === 'active' ? 'AConfigSearchbar-Active' : 'AConfigSearchbar'}>{children}</div>;
};
