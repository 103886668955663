import { EditorContext, LangContext } from 'context';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import { useContext, useEffect } from 'react';
import { Device } from 'types';
import { isSubtype } from 'utils';

type Args = {
  ovens: Device[];
  selectedOven: any;
  setSelectedOven: React.Dispatch<React.SetStateAction<any>>;
  somethingSelected: React.MutableRefObject<boolean>;
};

export const useUpdateOvens = ({ ovens, selectedOven, setSelectedOven, somethingSelected }: Args) => {
  const { langId } = useContext(LangContext);

  const { selection, setErrorAlert } = useContext(EditorContext);

  useEffect(() => {
    // Selection, etwas selektiert, kein Board und kein Block selektiert
    if (selection && somethingSelected.current && (selection instanceof BlockGroup || selection instanceof BlockItem)) {
      if (selectedOven && selectedOven.length > 0 && ovens && ovens.length > 0) {
        const oven = ovens.find(o => o.translations[langId].name === selectedOven);

        if (oven && isSubtype(oven.subtype, ['GOven'])) {
          EquipmentHelper.setEOven(selection, null);
          if (!EquipmentHelper.getGOven(selection)) {
            setErrorAlert(['gOven']);
          }
          EquipmentHelper.setGOven(selection, oven);
        } else if (oven && isSubtype(oven.subtype, ['EOven'])) {
          EquipmentHelper.setGOven(selection, null);
          EquipmentHelper.setEOven(selection, oven);
        } else {
          if (EquipmentHelper.getGOven(selection)) {
            EquipmentHelper.setGOven(selection, null);
          } else if (EquipmentHelper.getEOven(selection)) {
            EquipmentHelper.setEOven(selection, null);
          }
        }
      }
    } else {
      if (selection && (selection instanceof BlockGroup || selection instanceof BlockItem)) {
        somethingSelected.current = true;
        let currentOven = EquipmentHelper.getGOven(selection);
        if (currentOven) {
          setSelectedOven(currentOven.getDeviceObject().translations[langId].name);
          setErrorAlert(['gOven']);
        } else {
          currentOven = EquipmentHelper.getEOven(selection);
        }
        if (currentOven) {
          setSelectedOven(currentOven.getDeviceObject().translations[langId].name);
        }
      }
    }
    if (!selection) {
      somethingSelected.current = false;
    }
  }, [selectedOven, somethingSelected, ovens]);
};
