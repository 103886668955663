import { AEditorMenuItem } from 'atoms';
import { EditorContext, LangContext } from 'context';
import Localizations from 'languages';
import Block from 'page/Editor/configuration/Block';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import BlockItem from 'page/Editor/configuration/BlockItem';
import { MarineMeisterGlobal } from 'page/Editor/Menu/Menus/MarineMeister/Partials/Global';
import { ErrorMessage, SelectedCategory, SelectedDevice } from 'page/Editor/Menu/Shared';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Category } from 'types';
import { updateBlockIds } from 'utils';
import { MarineMeisterHome, MarineMeisterStructure, MarineMeisterUpperStructures } from './Partials';
import { MarineMeisterFunctional } from './Partials/Functional/MarineMeisterFunctional';

export const MarineMeister: React.FC = () => {
  const { lang } = useContext(LangContext);
  const {
    configuration,
    setErrorAlert,
    errorAlert,
    marineMeisterView,
    menuView,
    setCanAdvanceMarineMeister,
    setMarineMeisterView,
    selectedMarineMeister,
    setHasMarineMeister,
    setSelectedMarineMeister,
    scene,
    selection,
    setSelection
  } = useContext(EditorContext);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      console.log(event);
      if (event.code === 'Delete') {
        console.log('del ', selection);
        if (selection && selection instanceof BlockItem) {
          setErrorAlert(['delete']);
        }
      }
    },
    [selection]
  );

  useEffect(() => {
    const marineMeisters = configuration.getMarineMeister();
    if (Array.isArray(marineMeisters)) {
      setSelectedMarineMeister(marineMeisters[0]);
    }
    if (marineMeisters?.length > 0) {
      setCanAdvanceMarineMeister(true);
    } else {
      setCanAdvanceMarineMeister(false);
    }
  }, [configuration]);

  useEffect(() => {
    if (selection) {
      console.log('SEL');
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selection]);

  if (menuView !== 'marineMeister') return null;

  if (errorAlert && errorAlert[0] === 'delete') {
    return (
      <AEditorMenuItem key="MarineMeister-ErrorMessageDelete">
        <ErrorMessage
          title={Localizations['delete'][lang]}
          message={Localizations['confirmDelete'][lang]}
          confirm={() => {
            const marineMeisters = configuration.getMarineMeister();
            const selected = scene?.getSelected();
            if ((marineMeisterView === 'structure' && selectedMarineMeister) || (scene && selected instanceof Block)) {
              const index = marineMeisters.findIndex((b: Block) => b.getId() === selectedMarineMeister.getId());
              configuration.removeMarineMeister(index);
              if (!marineMeisters || marineMeisters?.length === 0) {
                setSelectedMarineMeister(null);
              } else {
                setSelectedMarineMeister(marineMeisters[0]);
              }
              setHasMarineMeister(!!(Array.isArray(marineMeisters) && marineMeisters.length > 0));
              setCanAdvanceMarineMeister(!!(Array.isArray(marineMeisters) && marineMeisters.length > 0));
              updateBlockIds(marineMeisters, 'marineMeister');
            } else if (selection instanceof BlockItem || selection instanceof BlockGroup) {
              selection.delete();
              setSelection(null);

              updateBlockIds(marineMeisters, 'marineMeister');

              window.removeEventListener('keydown', handleKeyDown);
            } else {
              console.log('can not delete');
              setSelection(null);
              /* if (scene.getSelected()) {
                scene.getSelected()?.delete();
                window.removeEventListener('keydown', handleKeyDown);
              } */
            }
            if (configuration && marineMeisterView !== 'structure') {
              if (selectedMarineMeister && selectedMarineMeister.getRowBottom() && selectedMarineMeister.getRowBottom().getItems().length === 0) {
                if (
                  !selectedMarineMeister.getRowTop() ||
                  (selectedMarineMeister.getRowTop() &&
                    selectedMarineMeister.getRowTop().getItems() &&
                    selectedMarineMeister.getRowTop().getItems().length === 0)
                ) {
                  setCanAdvanceMarineMeister(false);

                  setMarineMeisterView('home');
                }
              }
            }
            setSelection(null);
            setErrorAlert(null);
            scene.setSelected(null);
          }}
          goBack={() => setErrorAlert(null)}
        />
      </AEditorMenuItem>
    );
  }

  if (selection && selection instanceof BlockItem && marineMeisterView !== 'upperStructures') {
    return (
      <AEditorMenuItem key="Marinemeister-SelectedDevice">
        <SelectedDevice selectedDevice={[selection.getDeviceObject()]} />
      </AEditorMenuItem>
    );
  }

  if (selectedCategory && marineMeisterView !== 'upperStructures' && marineMeisterView !== 'global') {
    return (
      <AEditorMenuItem key="Marinemeister-SelectedCategory">
        <SelectedCategory noSeries selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      </AEditorMenuItem>
    );
  }

  return (
    <>
      {marineMeisterView === 'home' && (
        <AEditorMenuItem key="Marinemeister-home">
          <MarineMeisterHome />
        </AEditorMenuItem>
      )}

      {marineMeisterView === 'structure' && (
        <AEditorMenuItem key="Marinemeister-structure">
          <MarineMeisterStructure />
        </AEditorMenuItem>
      )}

      {marineMeisterView === 'functional' && (
        <AEditorMenuItem key="Marinemeister-functional">
          <MarineMeisterFunctional setSelectedCategory={setSelectedCategory} />
        </AEditorMenuItem>
      )}

      {marineMeisterView === 'upperStructures' && (
        <AEditorMenuItem key="Marinemeister-uppser">
          <MarineMeisterUpperStructures />
        </AEditorMenuItem>
      )}

      {marineMeisterView === 'global' && (
        <AEditorMenuItem key="Marinemeister-global">
          <MarineMeisterGlobal />
        </AEditorMenuItem>
      )}
    </>
  );
};
