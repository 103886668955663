import * as BABYLON from 'babylonjs';
import Room, { InnerRoom, WallClipping, WallDirection } from 'page/Editor/configuration/Room';
import Scene from 'page/Editor/Scene';
import Dimensions from 'utils/Dimensions';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import BasicUtils from '../util/BasicUtils';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import MaterialUtils from '../util/MaterialUtils';
import WallClippingNode from './helper/WallClippingNode';

export type Walls = {
  node: BABYLON.TransformNode;
  top: Wall;
  right: Wall;
  bottom: Wall;
  left: Wall;
};

export type Wall = {
  node: BABYLON.TransformNode;
  upper: BABYLON.Mesh;
  lower: BABYLON.Mesh;
  output?: Wall;
};

/**
 * All measurements in cm
 */
type Label = {
  Top?: number;
  Right?: number;
  Bottom?: number;
  Left?: number;
  Width?: number;
  Depth?: number;
};

export default class RoomNode extends BABYLON.TransformNode {
  /**
   * in CM
   */
  public static readonly UPPER_WALL_HEIGHT = 260;
  /**
   * in CM
   */
  public static readonly LOWER_WALL_HEIGHT = 40;
  /**
   * in CM
   */
  public static readonly WALL_HEIGHT = RoomNode.UPPER_WALL_HEIGHT + RoomNode.LOWER_WALL_HEIGHT;
  /**
   * in CM
   */
  public static readonly WALL_STRENGTH = 10;

  private _width: number;
  private _depth: number;

  private _walls: Walls;

  private _labels: Array<BABYLON.TransformNode> = [];

  private _room: Room;

  constructor(room: Room) {
    super('room', Scene.CURRENT_SCENE);
    this._room = room;

    const walls = new BABYLON.TransformNode('walls', Scene.CURRENT_SCENE);
    walls.parent = this;

    this._walls = {
      node: walls,
      top: this.createWall('top', walls),
      right: this.createWall('right', walls, wall => {
        wall.output.node.rotation.y = Math.PI * 0.5;
      }),
      bottom: this.createWall('bottom', walls, wall => {
        wall.output.node.rotation.y = Math.PI;
      }),
      left: this.createWall('left', walls, wall => {
        wall.output.node.rotation.y = Math.PI * 1.5;
      })
    };

    // this.setWidth(600);
    // this.setDepth(600);

    this.bake();
    Scene.CURRENT_SCENE.registerBeforeRender(this.onBeforeRender);
  }

  /**
   * Creates a new wall
   * @param name name of wall
   * @param parent parent node
   * @param callback (optional) callback function
   */
  private createWall(name: string, parent: BABYLON.TransformNode, callback?: (wall: Wall) => void): Wall {
    const node = new BABYLON.TransformNode(name, Scene.CURRENT_SCENE);
    node.parent = parent;
    const upper = BABYLON.Mesh.CreateBox('upper', 1, Scene.CURRENT_SCENE);
    upper.parent = node;
    upper.isPickable = false;
    upper.material = MaterialUtils.MATERIAL_WALL;
    upper.subMeshes = [];
    const verticesCount = upper.getTotalVertices();
    upper.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 24, upper));
    upper.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 24, 8, upper));
    const lower = BABYLON.Mesh.CreateBox('lower', 1, Scene.CURRENT_SCENE);
    lower.parent = node;
    lower.isPickable = false;
    lower.material = MaterialUtils.MATERIAL_WALL;
    lower.subMeshes = [];
    lower.subMeshes.push(new BABYLON.SubMesh(0, 0, verticesCount, 0, 24, lower));
    lower.subMeshes.push(new BABYLON.SubMesh(1, 0, verticesCount, 24, 8, lower));

    upper.scaling.z = RoomNode.WALL_STRENGTH;
    upper.scaling.y = RoomNode.UPPER_WALL_HEIGHT;
    upper.position.y = RoomNode.UPPER_WALL_HEIGHT / 2 + RoomNode.LOWER_WALL_HEIGHT;

    lower.scaling.z = RoomNode.WALL_STRENGTH;
    lower.scaling.y = RoomNode.LOWER_WALL_HEIGHT;
    lower.position.y = RoomNode.LOWER_WALL_HEIGHT / 2;

    const output = new BABYLON.TransformNode('output', Scene.CURRENT_SCENE);
    output.parent = node;

    const wall: Wall = {
      node: node,
      upper: upper,
      lower: lower,
      output: {
        node: output,
        upper: null,
        lower: null
      }
    };

    upper.setEnabled(false);
    lower.setEnabled(false);

    if (callback) callback(wall);

    return wall;
  }

  public setShowLabels(value: boolean) {
    this._labels.forEach(label => label.setEnabled(value));
  }

  public updateLabels() {
    let depth = 10;
    const labelOptions = {
      depth: depth,
      lineMaterial: LabelUtils.lineMaterial
    };
    // Clear old Labels
    this._labels.splice(0, this._labels.length).forEach(node => {
      node.dispose();
    });

    const room: Label = {
      Top: Dimensions.CM(this.getRoom().getDepth()) / 2,
      Right: Dimensions.CM(this.getRoom().getWidth()) / 2,
      Bottom: -Dimensions.CM(this.getRoom().getDepth()) / 2,
      Left: -Dimensions.CM(this.getRoom().getWidth()) / 2,
      Width: Dimensions.CM(this.getRoom().getWidth()),
      Depth: Dimensions.CM(this.getRoom().getDepth())
    };

    const top: Array<Label> = [];
    const right: Array<Label> = [];
    const bottom: Array<Label> = [];
    const left: Array<Label> = [];

    top.push(room);
    right.push(room);

    for (let i = 0; i < this.getRoom().getChildren().length; i++) {
      const child = this.getRoom().getChildren()[i];
      if (child instanceof WallClipping) {
        switch (child.getWall()) {
          case 'Top':
            top.push({
              Right: child.getPosition() + Dimensions.CM(child.getWidth()) / 2,
              Left: child.getPosition() - Dimensions.CM(child.getWidth()) / 2,
              Width: Dimensions.CM(child.getWidth())
            });
            break;
          case 'Bottom':
            bottom.push({
              Right: child.getPosition() + Dimensions.CM(child.getWidth()) / 2,
              Left: child.getPosition() - Dimensions.CM(child.getWidth()) / 2,
              Width: Dimensions.CM(child.getWidth())
            });
            break;
          case 'Right':
            right.push({
              Top: child.getPosition() + Dimensions.CM(child.getWidth()) / 2,
              Bottom: child.getPosition() - Dimensions.CM(child.getWidth()) / 2,
              Depth: Dimensions.CM(child.getWidth())
            });
            break;
          case 'Left':
            left.push({
              Top: child.getPosition() + Dimensions.CM(child.getWidth()) / 2,
              Bottom: child.getPosition() - Dimensions.CM(child.getWidth()) / 2,
              Depth: Dimensions.CM(child.getWidth())
            });
            break;
        }
      } else if (child instanceof InnerRoom) {
        if (child.getRotation() > Math.PI / 2 - 0.1 && child.getRotation() < Math.PI / 2 + 0.1) {
          // Rotated 90°
          if (child.getPosition().y >= 0) {
            top.push({
              Right: child.getPosition().x + Dimensions.CM(child.getDepth()) / 2,
              Left: child.getPosition().x - Dimensions.CM(child.getDepth()) / 2,
              Width: Dimensions.CM(child.getDepth())
            });
          } else {
            bottom.push({
              Right: child.getPosition().x + Dimensions.CM(child.getDepth()) / 2,
              Left: child.getPosition().x - Dimensions.CM(child.getDepth()) / 2,
              Width: Dimensions.CM(child.getDepth())
            });
          }
          if (child.getPosition().x >= 0) {
            right.push({
              Top: child.getPosition().y + Dimensions.CM(child.getWidth()) / 2,
              Bottom: child.getPosition().y - Dimensions.CM(child.getWidth()) / 2,
              Depth: Dimensions.CM(child.getWidth())
            });
          } else {
            left.push({
              Top: child.getPosition().y + Dimensions.CM(child.getWidth()) / 2,
              Bottom: child.getPosition().y - Dimensions.CM(child.getWidth()) / 2,
              Depth: Dimensions.CM(child.getWidth())
            });
          }
        } else {
          // No rotation
          if (child.getPosition().y >= 0) {
            top.push({
              Right: child.getPosition().x + Dimensions.CM(child.getWidth()) / 2,
              Left: child.getPosition().x - Dimensions.CM(child.getWidth()) / 2,
              Width: Dimensions.CM(child.getWidth())
            });
          } else {
            bottom.push({
              Right: child.getPosition().x + Dimensions.CM(child.getWidth()) / 2,
              Left: child.getPosition().x - Dimensions.CM(child.getWidth()) / 2,
              Width: Dimensions.CM(child.getWidth())
            });
          }
          if (child.getPosition().x >= 0) {
            right.push({
              Top: child.getPosition().y + Dimensions.CM(child.getDepth()),
              Bottom: child.getPosition().y,
              Depth: Dimensions.CM(child.getDepth())
            });
          } else {
            left.push({
              Top: child.getPosition().y + Dimensions.CM(child.getDepth()),
              Bottom: child.getPosition().y,
              Depth: Dimensions.CM(child.getDepth())
            });
          }
        }
      }
    }

    top.sort((a, b) => {
      if (a.Left < b.Left) return -1;
      if (a.Left > b.Left) return 1;
      return 0;
    });

    right.sort((a, b) => {
      if (a.Bottom < b.Bottom) return -1;
      if (a.Bottom > b.Bottom) return 1;
      return 0;
    });

    const initialDepth = 30;
    let drawDepth = initialDepth;
    let depthIncrement = depth + 10;
    // Draw Top Labels
    while (top.length > 0) {
      let l = Math.min(room.Left, top[0].Left);
      for (let i = 0; i < top.length; ) {
        const e = top[i];
        if (l <= e.Left) {
          if (l < e.Left) {
            const fillerWidth = e.Left - l;
            const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerWidth), fillerWidth, Orientation.Top, labelOptions);
            filler.position.z = this._room.getDepth() / 20 + drawDepth;
            filler.position.x = l;
            filler.setEnabled(this._room.isShowLabels());
            this._labels.push(filler);
          }
          const label = LabelUtils.drawLabel('' + Dimensions.MM(e.Width), e.Width, Orientation.Top, labelOptions);
          label.position.z = this._room.getDepth() / 20 + drawDepth;
          label.position.x = e.Left;
          label.setEnabled(this._room.isShowLabels());
          this._labels.push(label);

          l = e.Right;
          // Remove drawn label
          top.splice(i, 1);
        } else {
          i++;
        }
      }
      if (l < room.Right) {
        const fillerWidth = room.Right - l;
        const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerWidth), fillerWidth, Orientation.Top, labelOptions);
        filler.position.z = this._room.getDepth() / 20 + drawDepth;
        filler.position.x = l;
        filler.setEnabled(this._room.isShowLabels());
        this._labels.push(filler);
      }
      drawDepth += depthIncrement;
    }

    drawDepth = initialDepth;
    // Draw Bottom Labels
    while (bottom.length > 0) {
      let l = Math.min(room.Left, bottom[0].Left);
      for (let i = 0; i < bottom.length; ) {
        const e = bottom[i];
        if (l <= e.Left) {
          if (l < e.Left) {
            const fillerWidth = e.Left - l;
            const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerWidth), fillerWidth, Orientation.Bottom, labelOptions);
            filler.position.z = -(this._room.getDepth() / 20 + drawDepth);
            filler.position.x = l;
            filler.setEnabled(this._room.isShowLabels());
            this._labels.push(filler);
          }
          const label = LabelUtils.drawLabel('' + Dimensions.MM(e.Width), e.Width, Orientation.Bottom, labelOptions);
          label.position.z = -(this._room.getDepth() / 20 + drawDepth);
          label.position.x = e.Left;
          label.setEnabled(this._room.isShowLabels());
          this._labels.push(label);

          l = e.Right;
          // Remove drawn label
          bottom.splice(i, 1);
        } else {
          i++;
        }
      }
      if (l < room.Right) {
        const fillerWidth = room.Right - l;
        const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerWidth), fillerWidth, Orientation.Bottom, labelOptions);
        filler.position.z = -(this._room.getDepth() / 20 + drawDepth);
        filler.position.x = l;
        filler.setEnabled(this._room.isShowLabels());
        this._labels.push(filler);
      }
      drawDepth += depthIncrement;
    }

    drawDepth = initialDepth;
    // Draw Right Labels
    while (right.length > 0) {
      let b = Math.min(room.Bottom, right[0].Bottom);
      for (let i = 0; i < right.length; ) {
        const e = right[i];
        if (b <= e.Bottom) {
          if (b < e.Bottom) {
            const fillerDepth = e.Bottom - b;
            const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerDepth), fillerDepth, Orientation.Right, labelOptions);
            filler.position.z = b;
            filler.position.x = this._room.getWidth() / 20 + drawDepth;
            filler.setEnabled(this._room.isShowLabels());
            this._labels.push(filler);
          }
          const label = LabelUtils.drawLabel('' + Dimensions.MM(e.Depth), e.Depth, Orientation.Right, labelOptions);
          label.position.z = e.Bottom;
          label.position.x = this._room.getWidth() / 20 + drawDepth;
          label.setEnabled(this._room.isShowLabels());
          this._labels.push(label);

          b = e.Top;
          // Remove drawn label
          right.splice(i, 1);
        } else {
          i++;
        }
      }
      if (b < room.Top) {
        const fillerDepth = room.Top - b;
        const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerDepth), fillerDepth, Orientation.Right, labelOptions);
        filler.position.z = b;
        filler.position.x = this._room.getWidth() / 20 + drawDepth;
        filler.setEnabled(this._room.isShowLabels());
        this._labels.push(filler);
      }
      drawDepth += depthIncrement;
    }

    drawDepth = initialDepth;
    // Draw Left Labels
    while (left.length > 0) {
      let b = Math.min(room.Bottom, left[0].Bottom);
      for (let i = 0; i < left.length; ) {
        const e = left[i];
        if (b <= e.Bottom) {
          if (b < e.Bottom) {
            const fillerDepth = e.Bottom - b;
            const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerDepth), fillerDepth, Orientation.Left, labelOptions);
            filler.position.z = b;
            filler.position.x = -(this._room.getWidth() / 20 + drawDepth);
            filler.setEnabled(this._room.isShowLabels());
            this._labels.push(filler);
          }
          const label = LabelUtils.drawLabel('' + Dimensions.MM(e.Depth), e.Depth, Orientation.Left, labelOptions);
          label.position.z = e.Bottom;
          label.position.x = -(this._room.getWidth() / 20 + drawDepth);
          label.setEnabled(this._room.isShowLabels());
          this._labels.push(label);

          b = e.Top;
          // Remove drawn label
          left.splice(i, 1);
        } else {
          i++;
        }
      }
      if (b < room.Top) {
        const fillerDepth = room.Top - b;
        const filler = LabelUtils.drawLabel('' + Dimensions.MM(fillerDepth), fillerDepth, Orientation.Left, labelOptions);
        filler.position.z = b;
        filler.position.x = -(this._room.getWidth() / 20 + drawDepth);
        filler.setEnabled(this._room.isShowLabels());
        this._labels.push(filler);
      }
      drawDepth += depthIncrement;
    }
  }

  public bake() {
    HighPerformanceQueue.push(this.uniqueId, () => {
      this._bake(this._walls.top);
      this._bake(this._walls.right);
      this._bake(this._walls.bottom);
      this._bake(this._walls.left);
      this.updateLabels();
      return true;
    });
  }

  private _bake(wall: Wall) {
    if (wall.output.lower) wall.output.lower.dispose();
    if (wall.output.upper) wall.output.upper.dispose();
    const node = wall.output.node;
    let upperCSG = BABYLON.CSG.FromMesh(wall.upper);
    let lowerCSG = BABYLON.CSG.FromMesh(wall.lower);

    const children = wall.node.getChildTransformNodes(true);
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (child instanceof WallClippingNode) {
        const clipping = child.getClipping();
        const csg = BABYLON.CSG.FromMesh(clipping);
        upperCSG = upperCSG.subtract(csg);
        lowerCSG = lowerCSG.subtract(csg);
      }
    }

    const upper = upperCSG.toMesh('upper', MaterialUtils.MATERIAL_WALL, Scene.CURRENT_SCENE, true);
    upper.parent = node;
    upper.layerMask = 0x20000000;
    const lower = lowerCSG.toMesh('lower', MaterialUtils.MATERIAL_WALL, Scene.CURRENT_SCENE, true);
    lower.parent = node;

    wall.output = {
      node: node,
      upper: upper,
      lower: lower
    };
  }

  private onBeforeRender = () => {
    const camera = Scene.CURRENT_SCENE.activeCamera;
    if (this._walls.bottom.output.upper) {
      if (camera instanceof BABYLON.ArcRotateCamera && camera.name === 'Camera') {
        let alpha = camera.alpha % (Math.PI * 2);
        if (alpha < 0) alpha = Math.PI * 2 - Math.abs(alpha);
        let deg = BABYLON.Tools.ToDegrees(alpha);
        // console.log(deg, this);
        const inaccuracy = 7.5;
        this._walls.right.output.upper.setEnabled(!(deg > 270 + inaccuracy || deg < 90 - inaccuracy));
        this._walls.top.output.upper.setEnabled(!(deg > 0 + inaccuracy && deg < 180 - inaccuracy));
        this._walls.left.output.upper.setEnabled(!(deg > 90 + inaccuracy && deg < 270 - inaccuracy));
        this._walls.bottom.output.upper.setEnabled(!(deg > 180 + inaccuracy && deg < 360 - inaccuracy));
      } else if (camera instanceof BABYLON.ArcRotateCamera && camera.name === 'CameraDiagonal') {
        this._walls.right.output.upper.setEnabled(true);
        this._walls.top.output.upper.setEnabled(true);
        this._walls.left.output.upper.setEnabled(false);
        this._walls.bottom.output.upper.setEnabled(false);
      } else if (camera instanceof BABYLON.ArcRotateCamera && camera.name === 'CameraBack') {
        this._walls.right.output.upper.setEnabled(true);
        this._walls.top.output.upper.setEnabled(false);
        this._walls.left.output.upper.setEnabled(true);
        this._walls.bottom.output.upper.setEnabled(true);
      } else if (camera instanceof BABYLON.ArcRotateCamera && camera.name === 'CameraTop2') {
        this._walls.right.output.upper.setEnabled(true);
        this._walls.top.output.upper.setEnabled(true);
        this._walls.left.output.upper.setEnabled(true);
        this._walls.bottom.output.upper.setEnabled(true);
      } else {
        this._walls.right.output.upper.setEnabled(true);
        this._walls.top.output.upper.setEnabled(true);
        this._walls.left.output.upper.setEnabled(true);
        this._walls.bottom.output.upper.setEnabled(true);
      }
    }
  };

  /**
   * @returns width in cm
   */
  public getWidth() {
    return this._width;
  }

  /**
   * @param width width in cm
   */
  public setWidth(width: number) {
    this._width = width;
    width += 20;

    this._walls.top.upper.scaling.x = width;
    this._walls.top.lower.scaling.x = width;
    this._walls.bottom.upper.scaling.x = width;
    this._walls.bottom.lower.scaling.x = width;

    this._walls.right.node.position.x = width / 2 - RoomNode.WALL_STRENGTH / 2;
    this._walls.left.node.position.x = -width / 2 + RoomNode.WALL_STRENGTH / 2;

    this.bake();
  }

  /**
   * @returns depth in cm
   */
  public getDepth() {
    return this._depth;
  }

  /**
   * @param depth depth in cm
   */
  public setDepth(depth: number) {
    this._depth = depth;
    depth += 20;

    this._walls.right.upper.scaling.x = depth;
    this._walls.right.lower.scaling.x = depth;
    this._walls.left.upper.scaling.x = depth;
    this._walls.left.lower.scaling.x = depth;

    this._walls.top.node.position.z = depth / 2 - RoomNode.WALL_STRENGTH / 2;
    this._walls.bottom.node.position.z = -depth / 2 + RoomNode.WALL_STRENGTH / 2;

    this.bake();
  }

  /**
   * @returns Walls
   */
  public getWalls() {
    return this._walls;
  }

  public getWall(wall: WallDirection) {
    switch (wall) {
      case 'Top':
        return this._walls.top;
      case 'Right':
        return this._walls.right;
      case 'Bottom':
        return this._walls.bottom;
      case 'Left':
        return this._walls.left;
    }
  }

  /**
   * @returns Room Object
   */
  public getRoom() {
    return this._room;
  }

  /**
   * Uff...
   * @param position x = v3.x | y = v3.z
   * @param width
   * @returns
   */
  public guessWallMountedPosition(position: { x: number; y: number }, width: number): { x: number; wall: WallDirection } {
    const thw = this._width / 2;
    const thd = this._depth / 2;
    let hit: WallDirection = null;
    const top = [
      { x: 0, y: 0 },
      { x: -thw, y: thd },
      { x: thw, y: thd },
      { x: 0, y: 0 }
    ];
    const right = [
      { x: 0, y: 0 },
      { x: thw, y: thd },
      { x: thw, y: -thd },
      { x: 0, y: 0 }
    ];
    const bottom = [
      { x: 0, y: 0 },
      { x: thw, y: -thd },
      { x: -thw, y: -thd },
      { x: 0, y: 0 }
    ];
    const left = [
      { x: 0, y: 0 },
      { x: -thw, y: -thd },
      { x: -thw, y: thd },
      { x: 0, y: 0 }
    ];
    if (BasicUtils.isPointInPoly(top, position)) hit = 'Top';
    else if (BasicUtils.isPointInPoly(right, position)) hit = 'Right';
    else if (BasicUtils.isPointInPoly(bottom, position)) hit = 'Bottom';
    else if (BasicUtils.isPointInPoly(left, position)) hit = 'Left';
    else if (position.y >= thd) hit = 'Top';
    else if (position.y <= -thd) hit = 'Bottom';
    else if (position.x >= thw) hit = 'Right';
    else if (position.x <= -thw) hit = 'Left';

    if (hit) {
      const hw = width / 2;
      let x = 0;
      // let wall: Wall = null;
      switch (hit) {
        case 'Top':
        // wall = this._walls.top;
        case 'Bottom':
          // if (wall == null) wall = this._walls.bottom;
          if (position.x - hw < -thw) x = -thw + hw;
          else if (position.x + hw > thw) x = thw - hw;
          else x = position.x;
          break;
        case 'Right':
        // wall = this._walls.right;
        case 'Left':
          // if (wall == null) wall = this._walls.left;
          if (position.y - hw < -thd) x = -thd + hw;
          else if (position.y + hw > thd) x = thd - hw;
          else x = position.y;
          break;
      }
      return { x, wall: hit };
    }
    return null;
  }

  /**
   * Uff...
   * @param position x = v3.x | y = v3.z
   * @param width
   * @returns
   */
  public guessInnerRoomPosition(position: { x: number; y: number }, rotation: number, width: number, depth: number): { x: number; y: number } {
    // TODO
    return { x: position.x, y: position.y };
  }

  dispose() {
    Scene.CURRENT_SCENE.unregisterBeforeRender(this.onBeforeRender);
    if (this._labels)
      this._labels.forEach(node => {
        node.dispose();
      });
    super.dispose();
  }
}
