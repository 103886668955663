import React, { FC, useState } from 'react';

// Styles
import { colors } from 'styles/theme';

// ===================================================================
interface Props extends React.HTMLProps<HTMLButtonElement> {
  btnType?: 'first' | 'second' | 'third' | 'fourth' | 'hollow' | 'link';
  className?: string;
  margin?: string;
  disabled?: boolean;
  padding?: string;
  fontSize?: string;
  thinBorder?: boolean;
  width?: string;
  maxWidth?: number;
}

// ===================================================================
export const Button: FC<Props> = ({
  children,
  disabled,
  className,
  onClick,
  btnType = 'first',
  margin = '0',
  padding = '1rem 2rem',
  fontSize = '0.9rem',
  width = '100%',
  maxWidth = 300,
  thinBorder = false
}) => {
  const [hovered, setHover] = useState<boolean>(false);

  const borderSize = thinBorder ? '1px' : '2px';
  // Attributes:
  var background = '',
    color = '',
    border = '';
  switch (btnType) {
    case 'second':
      background = 'transparent';
      color = hovered ? colors.dark : colors.grey;
      border = `${borderSize} solid ` + (hovered ? colors.dark : colors.grey);
      break;
    case 'third':
      background = hovered ? colors.blue : colors.lightBlue;
      color = colors.white;
      border = `${borderSize} solid ` + (hovered ? colors.blue : colors.lightBlue);
      break;
    case 'fourth':
      background = hovered ? colors.light : colors.white;
      border = `${borderSize} solid ` + colors.dark;
      color = colors.dark;
      break;
    case 'hollow':
      background = hovered ? colors.white : 'transparent';
      color = hovered ? colors.dark : colors.white;
      border = `${borderSize} solid ` + colors.white;
      break;
    case 'link':
      background = 'transparent';
      color = colors.dark;
      border = 'none';
      break;
    default:
      background = hovered ? colors.light : colors.white;
      color = colors.dark;
      border = 'none';
  }

  if (disabled) {
    background = 'transparent';
    color = colors.grey;
    border = `${borderSize} solid ` + colors.grey;
  }

  // Render:
  return (
    <button
      onClick={onClick}
      className={className}
      disabled={disabled}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => {
        if (!hovered) setHover(true);
      }}
      style={{
        width: width,
        maxWidth: maxWidth,
        margin: margin,
        padding: padding,

        background: background,
        color: color,
        border: border,

        fontSize: fontSize,
        letterSpacing: '0.5pt',
        textTransform: 'uppercase',
        fontWeight: 'bold',

        cursor: disabled ? 'not-allowed' : 'pointer',
        transition: 'all 0.25s ease'
      }}
    >
      {children}
    </button>
  );
};
