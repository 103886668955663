import { useContext, useEffect, useState } from 'react';
import { EditorContext } from 'context';
import { BordSettings } from 'page/Editor/Menu/Menus/Masterline/Partials';

export const useInitShelfSettings = (
  didMount: boolean,
  boardSettings: BordSettings,
  setBoardSettings: React.Dispatch<React.SetStateAction<BordSettings>>,
  setIsMediaBoard: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFreeBoard: React.Dispatch<React.SetStateAction<boolean>>,
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { selectedMasterline } = useContext(EditorContext);
  const [shelfInitialized, setShelfInitialized] = useState(false);

  useEffect(() => {
    setShelfInitialized(false);
    setUpdating(true);
  }, [selectedMasterline]);

  useEffect(() => {
    if (!didMount && !shelfInitialized) {
      // Adapt Board Settings
      const board = selectedMasterline.getBoard();
      if (board) {
        setIsMediaBoard(!!(board?.getDeviceObject()?.style === 'Tray'));
        setIsFreeBoard(board && !board.getDeviceObject());

        const leftDevices = board.getLeft();
        const rightDevices = board.getRight();

        if (
          hasDifferences(boardSettings, {
            marginLeft: board.getMarginLeft(),
            marginRight: board.getMarginRight(),
            marginMixingFaucetLeft: Array.isArray(leftDevices) && leftDevices.length > 0 ? board.getLeft()[0].margin : 0,
            marginMixingFaucetRight: Array.isArray(rightDevices) && rightDevices.length > 0 ? board.getRight()[0].margin : 0,
            salamanderLeft: !!board.isSalamanderLeft(),
            salamanderRight: !!board.isSalamanderRight(),
            mixingFaucetLeft: Array.isArray(leftDevices) && leftDevices.length > 0 ? [board.getLeft()[0]] : [],
            mixingFaucetRight: Array.isArray(rightDevices) && rightDevices.length > 0 ? [board.getRight()[0]] : []
          })
        ) {
          setBoardSettings({
            marginLeft: board.getMarginLeft(),
            marginRight: board.getMarginRight(),
            mixingFaucetLeft: Array.isArray(leftDevices) && leftDevices.length > 0 ? [board.getLeft()[0]] : [],
            mixingFaucetRight: Array.isArray(rightDevices) && rightDevices.length > 0 ? [board.getRight()[0]] : [],
            salamanderLeft: !!board.isSalamanderLeft(),
            salamanderRight: !!board.isSalamanderRight(),
            marginMixingFaucetLeft: Array.isArray(leftDevices) && leftDevices.length > 0 ? board.getLeft()[0].margin : 0,
            marginMixingFaucetRight: Array.isArray(rightDevices) && rightDevices.length > 0 ? board.getRight()[0].margin : 0
          });
        } else {
          setBoardSettings({
            marginLeft: 0,
            marginRight: 0,
            marginMixingFaucetLeft: 0,
            marginMixingFaucetRight: 0,
            salamanderLeft: false,
            salamanderRight: false,
            mixingFaucetLeft: [],
            mixingFaucetRight: []
          });
        }

        setUpdating(false);
      }
    } else {
      setUpdating(true);
      const board = selectedMasterline.getBoard();
      if (board) {
        setIsMediaBoard(!!(board.getDeviceObject()?.style === 'Tray'));
        setIsFreeBoard(board && !board.getDeviceObject());

        const usedLeft = board.getLeft();
        const usedRight = board.getRight();

        const newSettings = {
          marginLeft: board.getMarginLeft(),
          marginRight: board.getMarginRight(),
          marginMixingFaucetLeft: Array.isArray(usedLeft) && usedLeft.length > 0 ? board.getLeft()[0].margin : 0,
          marginMixingFaucetRight: Array.isArray(usedRight) && usedRight.length > 0 ? board.getRight()[0].margin : 0,
          salamanderLeft: !!board.isSalamanderLeft(),
          salamanderRight: !!board.isSalamanderRight(),
          mixingFaucetLeft: Array.isArray(usedLeft) && usedLeft.length > 0 ? [board.getLeft()[0]] : [],
          mixingFaucetRight: Array.isArray(usedRight) && usedRight.length > 0 ? [board.getRight()[0]] : []
        };
        setBoardSettings(newSettings);
      } else {
        setBoardSettings({
          marginLeft: 0,
          marginRight: 0,
          marginMixingFaucetLeft: 0,
          marginMixingFaucetRight: 0,
          salamanderLeft: false,
          salamanderRight: false,
          mixingFaucetLeft: [],
          mixingFaucetRight: []
        });
      }
      setUpdating(false);
    }

    setShelfInitialized(true);
  }, [didMount, shelfInitialized]);

  return {
    shelfInitialized
  };
};

const hasDifferences = (a: BordSettings, b: BordSettings): boolean => {
  const keys = Object.keys(a);

  let diff = false;
  keys.forEach(k => {
    if (a[k] != b[k]) {
      if (Array.isArray(a[k]) && Array.isArray(b[k])) {
        if (a[k][0] != b[k][0]) {
          diff = true;
        }
      } else {
        diff = true;
      }
    }
  });
  return diff;
};
