import React, { FC, useContext } from 'react';

// Context
import { EditorContext, LangContext } from 'context';

// Components
import { DeviceTileFull, DeviceTileSmall } from 'components';
import { ContextMenu } from 'page/Editor/Menu/Shared';

// Lang
import Localizations from 'languages';

// Types
import { Device } from 'types/Device';

// Stlyes:
import './style.scss';

interface Props {}

export const SearchResults: FC<Props> = () => {
  const { lang } = useContext(LangContext);
  const { fullscreen, setFullscreen, searchResults, setSelection } = useContext(EditorContext);
  return (
    <>
      <ContextMenu initActive mode="search" />
      <div className="Search-Results">
        <h4 style={{ marginTop: '54px' }}>Search Results</h4>
        {searchResults && searchResults.length > 0 ? (
          searchResults.map((result: Device, index: number) => {
            return (
              <div className="Search-Results-Result" key={`Search-Result-${result.id}-${index}`}>
                {fullscreen ? (
                  <DeviceTileFull device={result} deselectCategory={() => setFullscreen(null)} />
                ) : (
                  <DeviceTileSmall device={result} selectDevice={setSelection} />
                )}
              </div>
            );
          })
        ) : (
          <span>{Localizations['noDevice'][lang]}</span>
        )}
      </div>
    </>
  );
};
