import React, { FC, useContext, Fragment, useEffect, useRef, useState } from 'react';

// Components
import { Selectbox } from 'atoms';
import { ContextMenu, EquipmentSwitch } from 'page/Editor/Menu/Shared';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import { EquipmentHelper } from 'page/Editor/configuration/Equipment';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { Device, Subtype } from 'types';

// Utils
import { post } from 'utils';
import { useFlexichefSubtypes } from 'page/Editor/Menu/Menus/Flexichef/Hooks';
import FreeItem from 'page/Editor/configuration/FreeItem';
import { LoadingSpinner } from 'components';

// ===================================================================

export const Substructures: FC = () => {
  const { lang, langId } = useContext(LangContext);

  const { scene, selection, setErrorAlert, setModularNOLView, setSelection } = useContext(EditorContext);
  const somethingSelected = useRef(false);

  const existingSubtypes = [
    'WingedDoor',
    'Hygiene',
    'AngularRail',
    'LowerDrawer',
    'HeatingCabinet',
    'HeatingDrawer',
    'EmptyingSystem',
    'EOven',
    'GOven',
    'BlindCover'
  ];

  const [availableSubtypes, setAvailableSubtypes] = useState<Subtype[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [disabledSwitches, setDisabledSwitches] = useState<String[]>([]);
  const [ovens, setOvens] = useState<Device[]>([]);
  const [selectedOven, setSelectedOven] = useState(Localizations['none'][lang]);
  const { canHaveBaseCover, canHaveBaseCoverRight, toggleSelection, usedDevices: usedFlexiDevices } = useFlexichefSubtypes();
  const isTeamDevice = (selection instanceof FreeItem || selection instanceof BlockItem) && selection.getDeviceObject()?.style.includes('-');

  const [loading, setLoading] = useState(false);

  const [usedDevices, setUsedDevices] = useState({
    WingedDoor: false,
    Hygiene: false,
    AngularRail: false,
    LowerDrawer: false,
    HeatingCabinet: false,
    HeatingDrawer: false,
    EmptyingSystem: false,
    BlindCover: false
  });

  useEffect(() => {
    if (scene) {
      scene.setSubstructureMode(true);
      if (scene.getSelected()) {
        setSelection(scene.getSelected());
        somethingSelected.current = true;
      }
    }

    return () => {
      if (scene) {
        scene.setSubstructureMode(false);
      }
      //setSelection(null);
    };
  }, []);

  // Called when SELECTION changes
  // Fetches Available Subtypes
  // Updates usedDevices
  // Updates selctedOven
  useEffect(() => {
    const width = selection instanceof BlockItem ? selection.getWidthSubstructure() : selection instanceof BlockGroup ? selection.getWidthSubstructure() : 0;
    const depth = selection instanceof BlockItem ? selection.getBlockRow().getDepth() : selection instanceof BlockGroup ? selection.getParent().getDepth() : 0;

    // Special case of 1200 width && E- or GOven available:
    const caseWideOven =
      (selection instanceof BlockItem || selection instanceof BlockGroup) &&
      width === 1200 &&
      (selection.compatibleWithEquipment(Subtype.EOven) || selection.compatibleWithEquipment(Subtype.GOven));

    const checkSubtypesSpecial = async () => {
      setLoading(true);
      // Subtypes
      const tempAvailableSubtypes: Subtype[] = [];
      const tempAvailableDevices: Device[] = [];

      const ovenRequest = async () => {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
          data: {
            type: 'modularNOL',
            width: 800,
            depth,
            subtypes: ['EOven', 'GOven']
          }
        });
        if (data) {
          data.forEach((result: Device) => {
            //@ts-ignore
            if (!tempAvailableSubtypes.includes(result.subtype) && selection.compatibleWithEquipment(Subtype[result.subtype])) {
              //@ts-ignore
              tempAvailableSubtypes.push(Subtype[result.subtype]);
              tempAvailableDevices.push(result);
            }
          });
        }
        if (error) {
          console.log(error);
        }
      };
      const otherRequest = async () => {
        const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
          data: {
            type: 'modularNOL',
            width: 1200,
            depth,
            subtypes: existingSubtypes.filter(e => e !== 'EOven' && e !== 'GOven')
          }
        });
        if (data) {
          data.forEach((result: Device) => {
            //@ts-ignore
            if (!tempAvailableSubtypes.includes(result.subtype) && selection.compatibleWithEquipment(Subtype[result.subtype])) {
              //@ts-ignore
              tempAvailableSubtypes.push(Subtype[result.subtype]);
              tempAvailableDevices.push(result);
            }
          });
        }
        if (error) {
          console.log(error);
        }
      };

      Promise.all([await otherRequest(), await ovenRequest()])
        .then(() => {
          setAvailableDevices(tempAvailableDevices);
          setAvailableSubtypes(tempAvailableSubtypes);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };

    const checkAvailableSubtypes = async () => {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/device/equipment`, {
        data: {
          type: 'modularNOL',
          width,
          depth,
          subtypes: existingSubtypes
        }
      });
      if (data) {
        console.log('DATA: ', data);
        // Subtypes
        const tempAvailableSubtypes: Subtype[] = [];
        const tempAvailableDevices: Device[] = [];
        data.forEach((result: Device) => {
          //@ts-ignore
          if (!tempAvailableSubtypes.includes(result.subtype) && selection.compatibleWithEquipment(Subtype[result.subtype])) {
            console.log('IST KOMPATIBEL');
            //@ts-ignore
            tempAvailableSubtypes.push(Subtype[result.subtype]);
            tempAvailableDevices.push(result);
          } else {
            console.log('NICHT');
          }
        });

        setLoading(false);
        setAvailableDevices(tempAvailableDevices);
        setAvailableSubtypes(tempAvailableSubtypes);
      }
      if (error) {
        setLoading(false);
        console.log(error);
      }
    };

    if (!selection) {
      /*       setMergeMode(false);
       */
    } else {
      if (selection instanceof BlockItem) {
        !caseWideOven ? checkAvailableSubtypes() : checkSubtypesSpecial();
        setUsedDevices({
          WingedDoor: EquipmentHelper.getWingedDoor(selection) ? true : false,
          Hygiene: EquipmentHelper.getHygiene(selection) ? true : false,
          AngularRail: EquipmentHelper.getAngularRail(selection) ? true : false,
          LowerDrawer: EquipmentHelper.getLowerDrawer(selection) ? true : false,
          HeatingCabinet: EquipmentHelper.getHeatingCabinet(selection) ? true : false,
          HeatingDrawer: EquipmentHelper.getHeatingDrawer(selection) ? true : false,
          EmptyingSystem: EquipmentHelper.getEmptyingSystem(selection) ? true : false,
          BlindCover: EquipmentHelper.getBlindCover(selection) ? true : false
        });
      } else if (selection instanceof BlockGroup) {
        !caseWideOven ? checkAvailableSubtypes() : checkSubtypesSpecial();
        setUsedDevices({
          WingedDoor: EquipmentHelper.getWingedDoor(selection) ? true : false,
          Hygiene: EquipmentHelper.getHygiene(selection) ? true : false,
          AngularRail: EquipmentHelper.getAngularRail(selection) ? true : false,
          LowerDrawer: EquipmentHelper.getLowerDrawer(selection) ? true : false,
          HeatingCabinet: EquipmentHelper.getHeatingCabinet(selection) ? true : false,
          HeatingDrawer: EquipmentHelper.getHeatingDrawer(selection) ? true : false,
          EmptyingSystem: EquipmentHelper.getEmptyingSystem(selection) ? true : false,
          BlindCover: EquipmentHelper.getBlindCover(selection) ? true : false
        });
      }
    }

    if (selection && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.getEOven(selection)) {
      setSelectedOven(EquipmentHelper.getEOven(selection).getDeviceObject().translations[langId].name);
    }

    if (selection && (selection instanceof BlockItem || selection instanceof BlockGroup) && EquipmentHelper.getGOven(selection)) {
      setSelectedOven(EquipmentHelper.getGOven(selection).getDeviceObject().translations[langId].name);
    }

    if (
      selection &&
      (selection instanceof BlockItem || selection instanceof BlockGroup) &&
      !EquipmentHelper.getEOven(selection) &&
      !EquipmentHelper.getGOven(selection)
    ) {
      setSelectedOven(Localizations['none'][lang]);
    }

    if (selection && !somethingSelected.current) {
      somethingSelected.current = true;
    }
  }, [selection]);

  useEffect(() => {
    const availableOvens = availableDevices.filter(
      (aD: Device) =>
        // @ts-ignore
        aD.subtype === 'EOven' || aD.subtype === 'GOven'
    );
    setOvens(availableOvens);
  }, [availableDevices]);

  useEffect(() => {
    // Selection, etwas selektiert, kein Board und kein Block selektiert
    if (selection && somethingSelected.current && (selection instanceof BlockGroup || selection instanceof BlockItem)) {
      if (selectedOven && selectedOven.length > 0 && ovens && ovens.length > 0) {
        const oven = ovens.find(o => o.translations[langId].name === selectedOven);
        //@ts-ignore
        if (oven && oven.subtype === 'GOven') {
          EquipmentHelper.setEOven(selection, null);
          if (!EquipmentHelper.getGOven(selection)) {
            setErrorAlert(['gOven']);
          }
          EquipmentHelper.setGOven(selection, oven);
        }
        //@ts-ignore
        else if (oven && oven.subtype === 'EOven') {
          EquipmentHelper.setGOven(selection, null);
          EquipmentHelper.setEOven(selection, oven);
        } else {
          if (EquipmentHelper.getGOven(selection)) {
            EquipmentHelper.setGOven(selection, null);
          } else if (EquipmentHelper.getEOven(selection)) {
            EquipmentHelper.setEOven(selection, null);
          }
        }
      }
    } else {
      if (selection && (selection instanceof BlockGroup || selection instanceof BlockItem)) {
        somethingSelected.current = true;
        let currentOven = EquipmentHelper.getGOven(selection);
        if (currentOven) {
          setSelectedOven(currentOven.getDeviceObject().translations[langId].name);
          setErrorAlert(['gOven']);
        } else {
          currentOven = EquipmentHelper.getEOven(selection);
        }
        if (currentOven) {
          setSelectedOven(currentOven.getDeviceObject().translations[langId].name);
        }
      }
    }
    if (!selection) {
      somethingSelected.current = false;
    }
  }, [selectedOven, somethingSelected, ovens]);

  useEffect(() => {
    const updateDisables = () => {
      const disabled = [];
      if (selection instanceof BlockItem || selection instanceof BlockGroup) {
        if (!EquipmentHelper.canHaveWingedDoor(selection)) disabled.push('WingedDoor');
        if (!EquipmentHelper.canHaveHygiene(selection)) disabled.push('Hygiene');
        if (!EquipmentHelper.canHaveAngularRail(selection)) disabled.push('AngularRail');
        if (!EquipmentHelper.canHaveLowerDrawer(selection)) disabled.push('LowerDrawer');
        if (!EquipmentHelper.canHaveHeatingCabinet(selection)) disabled.push('HeatingCabinet');
        if (!EquipmentHelper.canHaveEmptyingSystem(selection)) disabled.push('EmptyingSystem');
        if (!EquipmentHelper.canHaveHeatingDrawer(selection)) disabled.push('HeatingDrawer');
        if (!EquipmentHelper.canHaveBlindCover(selection)) disabled.push('BlindCover');
      }

      setDisabledSwitches(disabled);
    };
    if (selection) {
      /* if (selectedOven === Localizations['none'][lang]) {
        setDisabledSwitches([]);
      } else { */
      updateDisables();
      /* } */
    }
  }, [selectedOven, selection, usedDevices]);

  return (
    <Fragment>
      <ContextMenu
        mode="back"
        onClick={() => {
          setModularNOLView('home');
          setSelection(null);
          scene.setSelected(null);
        }}
      />

      {loading ? (
        <LoadingSpinner color="medium" />
      ) : (
        <>
          {!selection && <div className="bold mt-4 text-sm">{Localizations['pickADevice'][lang]}</div>}
          {selection && availableSubtypes && availableSubtypes.length < 1 && !canHaveBaseCover && !canHaveBaseCoverRight && (
            <div className="bold mt-4 text-sm">{Localizations['noSubtypes'][lang]}</div>
          )}
          {selection && (selection instanceof BlockItem || selection instanceof BlockGroup) && (
            <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 60px)' }}>
              {availableSubtypes.includes(Subtype.WingedDoor) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('WingedDoor')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'WingedDoor')
                      ? //@ts-ignore
                        availableDevices.find(d => d.subtype === 'WingedDoor').translations[langId].name
                      : ''
                  }
                  status={usedDevices.WingedDoor}
                  label={Localizations['wingedDoor'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.WingedDoor) {
                      EquipmentHelper.setWingedDoor(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'WingedDoor')
                      );
                    } else {
                      EquipmentHelper.setWingedDoor(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, WingedDoor: !usedDevices.WingedDoor });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.Hygiene) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('Hygiene')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'Hygiene') ? availableDevices.find(d => d.subtype === 'Hygiene').translations[langId].name : ''
                  }
                  status={EquipmentHelper.getHygiene(selection) ? true : false}
                  label={Localizations['hygiene'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.Hygiene) {
                      EquipmentHelper.setHygiene(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'Hygiene')
                      );
                    } else {
                      EquipmentHelper.setHygiene(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, Hygiene: !usedDevices.Hygiene });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.AngularRail) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('AngularRail')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'AngularRail')
                      ? //@ts-ignore
                        availableDevices.find(d => d.subtype === 'AngularRail').translations[langId].name
                      : ''
                  }
                  status={usedDevices.AngularRail}
                  label={Localizations['angularRail'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.AngularRail) {
                      EquipmentHelper.setAngularRail(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'AngularRail')
                      );
                    } else {
                      EquipmentHelper.setAngularRail(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, AngularRail: !usedDevices.AngularRail });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.LowerDrawer) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('LowerDrawer')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'LowerDrawer')
                      ? //@ts-ignore
                        availableDevices.find(d => d.subtype === 'LowerDrawer').translations[langId].name
                      : ''
                  }
                  status={usedDevices.LowerDrawer}
                  label={Localizations['lowerDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.LowerDrawer) {
                      EquipmentHelper.setLowerDrawer(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'LowerDrawer')
                      );
                    } else {
                      EquipmentHelper.setLowerDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, LowerDrawer: !usedDevices.LowerDrawer });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.HeatingCabinet) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('HeatingCabinet')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'HeatingCabinet')
                      ? availableDevices.find(
                          //@ts-ignore
                          d => d.subtype === 'HeatingCabinet'
                        ).translations[langId].name
                      : ''
                  }
                  status={usedDevices.HeatingCabinet}
                  label={Localizations['heatingCabinet'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.HeatingCabinet) {
                      EquipmentHelper.setHeatingCabinet(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'HeatingCabinet')
                      );
                    } else {
                      EquipmentHelper.setHeatingCabinet(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, HeatingCabinet: !usedDevices.HeatingCabinet });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.EmptyingSystem) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('EmptyingSystem')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'EmptyingSystem')
                      ? availableDevices.find(
                          //@ts-ignore
                          d => d.subtype === 'EmptyingSystem'
                        ).translations[langId].name
                      : ''
                  }
                  status={usedDevices.EmptyingSystem}
                  label={Localizations['emptyingSystem'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.EmptyingSystem) {
                      EquipmentHelper.setEmptyingSystem(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'EmptyingSystem')
                      );
                    } else {
                      EquipmentHelper.setEmptyingSystem(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, EmptyingSystem: !usedDevices.EmptyingSystem });
                  }}
                  type="first"
                />
              )}
              {availableSubtypes.includes(Subtype.HeatingDrawer) && (
                <EquipmentSwitch
                  status={usedDevices.HeatingDrawer}
                  disabled={disabledSwitches.includes('HeatingDrawer')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'HeatingDrawer')
                      ? availableDevices.find(
                          //@ts-ignore
                          d => d.subtype === 'HeatingDrawer'
                        ).translations[langId].name
                      : ''
                  }
                  label={Localizations['heatingDrawer'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.HeatingDrawer) {
                      EquipmentHelper.setHeatingDrawer(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'HeatingDrawer')
                      );
                    } else {
                      EquipmentHelper.setHeatingDrawer(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, HeatingDrawer: !usedDevices.HeatingDrawer });
                  }}
                  type="first"
                />
              )}
              {(availableSubtypes.includes(Subtype.EOven) || availableSubtypes.includes(Subtype.GOven)) && (
                <>
                  <h5 className="mb-1">{Localizations['oven'][lang]}</h5>
                  <Selectbox
                    disabled={!(EquipmentHelper.canHaveEOven(selection) || EquipmentHelper.canHaveGOven(selection))}
                    state={selectedOven}
                    setState={setSelectedOven}
                    options={[...ovens.map(o => o.translations[langId].name), Localizations['none'][lang]]}
                  />
                </>
              )}
              {availableSubtypes.includes(Subtype.BlindCover) && (
                <EquipmentSwitch
                  disabled={disabledSwitches.includes('BlindCover')}
                  description={
                    //@ts-ignore
                    availableDevices.find(d => d.subtype === 'BlindCover')
                      ? availableDevices.find(
                          //@ts-ignore
                          d => d.subtype === 'BlindCover'
                        ).translations[langId].name
                      : ''
                  }
                  status={usedDevices.BlindCover}
                  label={Localizations['blindCover'][lang]}
                  toggleStatus={() => {
                    if (!usedDevices.BlindCover) {
                      EquipmentHelper.setBlindCover(
                        selection,
                        //@ts-ignore
                        availableDevices.find(d => d.subtype === 'BlindCover')
                      );
                    } else {
                      EquipmentHelper.setBlindCover(selection, null);
                    }
                    setUsedDevices({ ...usedDevices, BlindCover: !usedDevices.BlindCover });
                  }}
                  type="first"
                />
              )}

              {canHaveBaseCover && (
                <EquipmentSwitch
                  status={usedFlexiDevices.baseCoverLeft}
                  toggleStatus={() => toggleSelection('baseCoverLeft')}
                  label={`${Localizations['baseCover'][lang]} ${isTeamDevice ? Localizations['left'][lang] : ''}`}
                />
              )}
              {canHaveBaseCoverRight && isTeamDevice && (
                <EquipmentSwitch
                  status={usedFlexiDevices.baseCoverRight}
                  toggleStatus={() => toggleSelection('baseCoverRight')}
                  label={`${Localizations['baseCover'][lang]} ${Localizations['right'][lang]}`}
                />
              )}
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};
