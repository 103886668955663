import React, { FC, Fragment, useContext } from 'react';

// Atoms

// Components
import { BlockSelector, LoadingSpinner } from 'components';
import { ContextMenu, CategoryMenu, SeriesFilter } from 'page/Editor/Menu/Shared';

// Context
import { LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { Category, Device, ModularView } from 'types';
import { EditorContext, useUser } from 'context';

interface Props {
  categories: Category[];
  selectedCategory: Category;
  setSelectedCategory: (category: Category) => void;
  setSelectedDevice: (device: Device) => void;
  setView: (view: ModularView) => void;
}

export const ModularFunctional: FC<Props> = ({ categories, selectedCategory, setSelectedCategory, setView }) => {
  const { lang } = useContext(LangContext);
  const { hasFlexiAccess } = useUser();
  const { searching } = useContext(EditorContext);

  const filteredCategories = hasFlexiAccess
    ? categories
    : categories.filter(c => {
        const translations = Object.values(c.translations);
        return !translations || !!(translations && translations.findIndex(({ name }: { name: string }) => name?.includes('Flexi')));
      });

  return (
    <Fragment>
      <ContextMenu>
        <div className="Functional-View-ContextMenu">
          <SeriesFilter />
        </div>
      </ContextMenu>
      <div style={{ marginTop: '3rem' }}>
        <BlockSelector />
      </div>

      <div className="pt-2">
        <ContextMenu mode="back" onClick={() => setView('home')} style={{ marginBottom: '1rem' }}>
          {selectedCategory && (
            <div className="ml-1">
              <div className="Submenu-Headline">
                <span>{selectedCategory.id}</span>
              </div>
            </div>
          )}
        </ContextMenu>
      </div>
      {searching ? (
        <div className="mt-4">
          <LoadingSpinner color="blue" />
        </div>
      ) : (
        <div className="Custom-Scroll" style={{ maxHeight: 'calc(100% - 150px)' }}>
          {filteredCategories && filteredCategories.length > 0 ? (
            filteredCategories.map((c: Category, index) => (
              <CategoryMenu category={c} isLast={index === categories.length - 1} key={`Category-Section-${c.id}`} onSelect={() => setSelectedCategory(c)} />
            ))
          ) : (
            <div>{Localizations['noDevice'][lang]}</div>
          )}
        </div>
      )}
    </Fragment>
  );
};
