import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { EditorContext } from 'context';
// Components:
import { Header, SavingErrorHint } from 'components';

// Style:
import './style.scss';

// ==============================================================
export const DefaultLayout: FC = ({ children }) => {
  const { savingError } = useContext(EditorContext);
  const { location } = useHistory();

  const isView = location?.pathname === '/view';

  if (isView) return <>{children}</>;

  return (
    <>
      <div className="DefaultLayout disable-dbl-tap-zoom" style={{ height: window.innerHeight }}>
        {location?.pathname !== '/view' && <Header />}
        <div className="DefaultLayout-Content">{children}</div>
      </div>
      {savingError && <SavingErrorHint />}
    </>
  );
};
