import { useContext } from 'react';
import { EditorContext, LangContext } from 'context';
import FreeItem from '../../../../configuration/FreeItem';
import { useFlexichefSubtypes } from '../../../Menus/Flexichef/Hooks/useFlexichefSubtypes';
import { Checkbox } from 'atoms';
import Localizations from 'languages';
import { LoadingSpinner } from 'components';
import BlockItem from 'page/Editor/configuration/BlockItem';

export const FlexiChefOptions = () => {
  const { selection } = useContext(EditorContext);
  const { lang } = useContext(LangContext);
  const { loading, canHaveReadyXPress, canHaveReadyXPressRight, canHaveSpaceClean, toggleSelection, usedDevices } = useFlexichefSubtypes();
  const isTeamDevice = (selection instanceof FreeItem || selection instanceof BlockItem) && selection.getDeviceObject()?.style.includes('-');
  return (
    <div className="mb-1">
      {loading && <LoadingSpinner />}

      {canHaveReadyXPress && (
        <Checkbox
          boxSize="1rem"
          centered={false}
          id={`readyXPress-checkbox-left`}
          label={`${Localizations['readyXPress'][lang]} ${isTeamDevice ? 'L.' : ''}`}
          labelFontSize=".85rem"
          labelColor="black"
          status={!usedDevices.readyXPressLeft}
          setStatus={() => {
            toggleSelection('readyXPressLeft');
          }}
        />
      )}
      {canHaveReadyXPressRight && isTeamDevice && (
        <div className="mt-1">
          <Checkbox
            boxSize="1rem"
            centered={false}
            id={`readyXPress-checkbox-right`}
            label={`${Localizations['readyXPress'][lang]} R.`}
            labelFontSize=".85rem"
            labelColor="black"
            status={!usedDevices.readyXPressRight}
            setStatus={() => {
              toggleSelection('readyXPressRight');
            }}
          />
        </div>
      )}

      {canHaveSpaceClean && (
        <div className="mt-1">
          <Checkbox
            boxSize="1rem"
            centered={false}
            id={`spaceclean-checkbox-`}
            label={Localizations['spaceClean'][lang]}
            labelFontSize=".85rem"
            labelColor="black"
            status={!usedDevices.spaceClean}
            setStatus={() => {
              toggleSelection('spaceClean');
            }}
          />
        </div>
      )}
    </div>
  );
};
