import RoomWindowNode from 'components/babylon/node/RoomWindowNode';
import Room, { WallClipping } from './Room';

export default class RoomWindow extends WallClipping {
  public clazz = 'RoomWindow';

  private height: number;
  private bottom: number;
  private _node: RoomWindowNode;

  constructor(room: Room) {
    super(room);
    this._node = new RoomWindowNode(this);

    this.setWall('Top');
    this.setWidth(1000);
    this.setHeight(1000);
    this.setBottom(1000);
  }

  /**
   * @returns height in mm
   */
  public getHeight() {
    return this.height;
  }

  /**
   * @param height height in mm
   */
  public setHeight(height: number) {
    this.height = height;

    this._node.updateHeight();
    this._room.getNode().bake();
  }

  /**
   * @returns bottom distance in mm
   */
  public getBottom() {
    return this.bottom;
  }

  /**
   * @param bottom bottom distance in mm
   */
  public setBottom(bottom: number) {
    this.bottom = bottom;

    this._node.updateBottom();
    this._room.getNode().bake();
  }

  public getNode() {
    return this._node;
  }
}
