import { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import { OptionNode } from 'components/babylon/node/OptionNode';
import BasicUtils from 'components/babylon/util/BasicUtils';
import LoaderUtils from 'components/babylon/util/LoaderUtils';
import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import BlockGroup from './BlockGroup';
import BlockItem from './BlockItem';
import FreeItem from './FreeItem';

export default class Equipment {
  public clazz = 'Equipment';

  private _uniqueId: string;
  private device: string;
  private _device: Device;

  private _options = new Array<OptionNode>();
  private _parent: BlockItem | BlockGroup | FreeItem;

  private index = 0;

  private count?: number;

  constructor(parent: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    this._uniqueId = Date.now() + BasicUtils.generateRandomString(16);
    this._parent = parent;
    this.device = device.id;
    this._device = device;
    this.index = index;
  }

  public getUniqueId() {
    return this._uniqueId;
  }

  public getDeviceId() {
    return this.device;
  }

  public getDeviceObject() {
    return this._device;
  }

  public getOptions() {
    return this._options;
  }

  public getParent() {
    return this._parent;
  }

  public getIndex() {
    return this.index;
  }

  public setIndex(index: number) {
    this.index = index;
  }

  public getCount() {
    return this.count;
  }

  public setCount(count: number) {
    this.count = count;
  }
}

/**
 * Static class EquipmentHelper
 */
export class EquipmentHelper {
  public static getEquipment(item: BlockItem | BlockGroup | FreeItem, subtype: Subtype | string, index = 0) {
    if (typeof subtype === 'string') subtype = Subtype[subtype];
    for (let i = 0; i < item.getEquipments().length; i++) {
      const equipment = item.getEquipments()[i];
      if (equipment.getIndex() === index) {
        let deviceSubtype: string | Subtype = equipment.getDeviceObject().subtype;
        if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
        if (deviceSubtype === subtype) return equipment;
      }
    }
    return null;
  }

  public static addEquipment(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    // console.log('addEquipment', item, device, index);
    if (EquipmentHelper.getEquipment(item, device.subtype, index)) return false;

    const equipment = new Equipment(item, device, index);
    item.getEquipments().push(equipment);
    if (device.components) {
      const toLoad = device.components.length;
      let loaded = 0;
      for (let i = 0; i < device.components.length; i++) {
        const component = device.components[i];
        if (item instanceof BlockItem && item.getDeviceObject().id === 'lnOro' && component.component.id === '32bxL') {
          if (++loaded >= toLoad) item.getNode().bake();
          continue;
        }
        LoaderUtils.loadComponent(
          item.getNode(),
          component,
          node => {
            equipment.getOptions().push(node);
            item.getEquipments().forEach(e => e.getOptions().forEach(o => o.prepareOptionChange()));
            if (++loaded >= toLoad) item.getNode().bake();
          },
          () => {
            if (++loaded >= toLoad) item.getNode().bake();
          },
          equipment
        );
      }
    }

    // Special Service
    let subtype: Subtype | string = equipment.getDeviceObject().subtype;
    if (typeof subtype === 'string') subtype = Subtype[subtype];
    switch (subtype) {
      case Subtype.OpenSubstructure:
        if (item instanceof BlockItem || item instanceof BlockGroup) item.getNode().set(DNPT.Open, true, true);
        break;
      case Subtype.DoubleSidedOperation:
        if (item instanceof BlockItem || item instanceof BlockGroup) item.getNode().set(DNPT.DoubleSidedOperation, true, true);
        break;

      default:
        break;
    }
    if (item instanceof BlockItem || item instanceof BlockGroup) item.getBlock().checkFullBlendColor();

    return true;
  }

  public static removeEquipment(item: BlockItem | BlockGroup | FreeItem, equipment: Equipment | Device | Subtype | string, bake?: boolean, index = 0) {
    let e: Equipment = null;
    if (equipment instanceof Equipment) {
      e = equipment;
    } else {
      let isDevice = false;
      try {
        if ((equipment as Device).id) {
          isDevice = true;
        }
      } catch (e) {}
      if (isDevice) {
        const device = equipment as Device;
        for (let i = 0; i < item.getEquipments().length; i++) {
          const element = item.getEquipments()[i];
          if (element.getDeviceId() === device.id && element.getIndex() === index) {
            e = element;
            break;
          }
        }
      } else {
        let subtype: Subtype = null;
        if (typeof equipment === 'string') subtype = Subtype[equipment];
        else subtype = equipment as Subtype;
        e = EquipmentHelper.getEquipment(item, subtype, index);
      }
    }
    if (e) {
      // Special Service
      let subtype: Subtype | string = e.getDeviceObject().subtype;
      if (typeof subtype === 'string') subtype = Subtype[subtype];
      switch (subtype) {
        case Subtype.OpenSubstructure:
          if (item instanceof BlockItem || item instanceof BlockGroup) item.getNode().set(DNPT.Open, false, bake);
          break;
        case Subtype.DoubleSidedOperation:
          if (item instanceof BlockItem || item instanceof BlockGroup) item.getNode().set(DNPT.DoubleSidedOperation, false, bake);
          break;
        default:
          break;
      }

      for (let i = 0; i < item.getEquipments().length; i++) {
        const element = item.getEquipments()[i];
        if (element.getUniqueId() === e.getUniqueId()) {
          item.getEquipments().splice(i, 1);
          if (element.getOptions().length > 0) {
            for (let i = 0; i < element.getOptions().length; i++) {
              const option = element.getOptions()[i];
              element.getParent().getNode().removeOption(option);
            }
            item.getEquipments().forEach(e => e.getOptions().forEach(o => o.prepareOptionChange()));
            if (bake) element.getParent().getNode().bake();
          }

          if (item instanceof BlockItem || item instanceof BlockGroup) item.getBlock().checkFullBlendColor();

          return true;
        }
      }
    }

    return false;
  }

  public static clearEquipment(item: BlockItem | BlockGroup | FreeItem, type: 'Upper' | 'Lower' = 'Lower', bake?: boolean) {
    let i = 0;
    while (i < item.getEquipments().length) {
      const equipment = item.getEquipments()[i];
      let subtype: Subtype | string = equipment.getDeviceObject().subtype;
      if (typeof subtype === 'string') subtype = Subtype[subtype];
      switch (subtype) {
        case Subtype.UpperDrawer:
        case Subtype.UpperPowerSocket:
        case Subtype.MixingFaucet:
        case Subtype.ColdWaterInlet:
          if (type === 'Upper') EquipmentHelper.removeEquipment(item, equipment, false, equipment.getIndex());
          else i++;
          break;

        case Subtype.WingedDoor:
        case Subtype.Hygiene:
        case Subtype.AngularRail:
        case Subtype.LowerDrawer:
        case Subtype.HeatingCabinet:
        case Subtype.HeatingDrawer:
        case Subtype.EmptyingSystem:
        case Subtype.EOven:
        case Subtype.GOven:
        case Subtype.Cooling:
        case Subtype.OpenSubstructure:
        case Subtype.WasteDrawer:
        case Subtype.GNFrame:
        case Subtype.WarmingDrawer:
        case Subtype.WarmingDevice:
        case Subtype.IntermediatePlate:
          if (type === 'Lower') EquipmentHelper.removeEquipment(item, equipment, false, equipment.getIndex());
          else i++;
          break;

        default:
          i++;
          break;
      }
    }
    if (bake) item.getNode().bake();

    if (item instanceof BlockItem || item instanceof BlockGroup) item.getBlock().checkFullBlendColor();
  }

  public static hasEquipment(item: BlockItem | BlockGroup, subtype: Subtype, index = 0) {
    for (let i = 0; i < item.getEquipments().length; i++) {
      const equipment = item.getEquipments()[i];
      if (equipment.getIndex() === index) {
        let st: Subtype | string = equipment.getDeviceObject().subtype;
        if (typeof st === 'string') st = Subtype[subtype];
        if (st == subtype) return true;
      }
    }
    return false;
  }

  public static canHaveWingedDoor(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.WingedDoor)) return false;
    const width = item.getWidthSubstructure();
    if (width >= 300 && width <= 1200) {
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      return true;
    }
    return false;
  }
  public static getWingedDoor(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.WingedDoor);
  }
  public static setWingedDoor(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.WingedDoor) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.WingedDoor, true);
  }

  public static canHaveHygiene(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.Hygiene)) return false;
    const width = item.getWidthSubstructure();
    if (width >= 300 && width <= 1400) {
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;

      return true;
    }
    return false;
  }
  public static getHygiene(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.Hygiene);
  }
  public static setHygiene(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.Hygiene) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.Hygiene, true);
  }

  public static canHaveAngularRail(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.AngularRail)) return false;
    const width = item.getWidthSubstructure();
    if (width === 400) {
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      // if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;

      return true;
    }
    return false;
  }
  public static getAngularRail(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.AngularRail);
  }
  public static setAngularRail(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.AngularRail) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.AngularRail, true);
  }

  public static canHaveUpperDrawer(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.UpperDrawer)) return false;
    const width = item.getWidth();
    if (width >= 400 && width <= 600) {
      if (EquipmentHelper.getUpperPowerSocket(item)) return false;
      if (EquipmentHelper.getUpperPowerSocket(item, 1)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;

      return true;
    }
    return false;
  }
  public static getUpperDrawer(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.UpperDrawer);
  }
  public static setUpperDrawer(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.UpperDrawer) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.UpperDrawer, true);
  }

  public static canHaveLowerDrawer(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.LowerDrawer)) return false;
    const width = item.getWidthSubstructure();
    if (width >= 400 && width <= 600) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getHygiene(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getLowerDrawer(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.LowerDrawer);
  }
  public static setLowerDrawer(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.LowerDrawer) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.LowerDrawer, true);
  }

  public static canHaveHeatingCabinet(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.HeatingCabinet)) return false;
    const width = item.getWidthSubstructure();
    if ((width >= 400 && width <= 600) || width === 800 || width === 1200) {
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      // if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;

      return true;
    }
    return false;
  }
  public static getHeatingCabinet(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.HeatingCabinet);
  }
  public static setHeatingCabinet(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.HeatingCabinet) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.HeatingCabinet, true);
  }

  public static canHaveUpperPowerSocket(item: BlockItem | BlockGroup, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.UpperPowerSocket)) return false;
    const width = item.getWidth();
    if (width >= (index > 0 ? 300 : 200) && width <= 1600) {
      if (EquipmentHelper.getUpperDrawer(item)) return false;
      return true;
    }
    return false;
  }
  public static getUpperPowerSocket(item: BlockItem | BlockGroup, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.UpperPowerSocket, index);
  }
  public static setUpperPowerSocket(item: BlockItem | BlockGroup, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.UpperPowerSocket) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.UpperPowerSocket, true, index);
  }

  public static canHaveMixingFaucet(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.MixingFaucet)) return false;
    const width = item.getWidth();
    if (width >= 200 && width <= 1600) {
      // if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getUpperDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;

      return true;
    }
    return false;
  }
  public static getMixingFaucet(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.MixingFaucet);
  }
  public static setMixingFaucet(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.MixingFaucet) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.MixingFaucet, true);
  }

  public static canHaveEmptyingSystem(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.EmptyingSystem)) return false;
    const width = item.getWidthSubstructure();
    if (width === 400 || width === 600 || width === 800) {
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;

      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getEmptyingSystem(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.EmptyingSystem);
  }
  public static setEmptyingSystem(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.EmptyingSystem) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.EmptyingSystem, true);
  }

  public static canHaveEOven(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.EOven)) return false;
    const width = item.getWidthSubstructure();
    if (width === 800 || (width === 1200 && item instanceof BlockItem)) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getHygiene(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getUpperDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getEOven(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.EOven);
  }
  public static setEOven(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.EOven) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.EOven, true);
  }

  public static canHaveGOven(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.GOven)) return false;
    const width = item.getWidthSubstructure();
    if (width === 800 || (width === 1200 && item instanceof BlockItem)) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getHygiene(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getUpperDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getMixingFaucet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getGOven(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.GOven);
  }
  public static setGOven(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.GOven) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.GOven, true);
  }

  public static canHaveHeatingDrawer(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.HeatingDrawer)) return false;
    const width = item.getWidthSubstructure();
    if (width === 800) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getHygiene(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getUpperDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getHeatingDrawer(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.HeatingDrawer);
  }
  public static setHeatingDrawer(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.HeatingDrawer) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.HeatingDrawer, true);
  }

  public static canHaveUnderframe850(item: FreeItem) {
    if (!item.compatibleWithEquipment(Subtype.Underframe850)) return false;
    return true;
  }
  public static getUnderframe850(item: FreeItem) {
    return EquipmentHelper.getEquipment(item, Subtype.Underframe850);
  }
  public static setUnderframe850(item: FreeItem, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.Underframe850) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.Underframe850, true);
  }

  public static canHaveUnderframe580(item: FreeItem) {
    if (!item.compatibleWithEquipment(Subtype.Underframe580)) return false;
    return true;
  }
  public static getUnderframe580(item: FreeItem) {
    return EquipmentHelper.getEquipment(item, Subtype.Underframe580);
  }
  public static setUnderframe580(item: FreeItem, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.Underframe580) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.Underframe580, true);
  }

  public static canHaveCooling(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.Cooling)) return false;
    const width = item.getWidthSubstructure();
    if (width == 800 || width == 1000 || width == 1200 || width == 1400) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getHygiene(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;
      return true;
    }
    return false;
  }
  public static getCooling(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.Cooling);
  }
  public static setCooling(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.Cooling) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.Cooling, true);
  }

  public static canHaveOpenSubstructure(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.OpenSubstructure)) return false;
    const width = item.getWidthSubstructure();
    if (width >= 300 && width <= 1400 && item.getBlock().getType() === 'Single' && item.getBlock().getSingleType() === 'Free') {
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getWasteDrawer(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      // if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;

      return true;
    }
    return false;
  }
  public static getOpenSubstructure(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.OpenSubstructure);
  }
  public static setOpenSubstructure(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.OpenSubstructure) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.OpenSubstructure, true);
  }

  public static canHaveWasteDrawer(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.WasteDrawer)) return false;
    const width = item.getWidthSubstructure();
    if (width == 600) {
      if (EquipmentHelper.getWingedDoor(item)) return false;
      if (EquipmentHelper.getOpenSubstructure(item)) return false;
      if (EquipmentHelper.getAngularRail(item)) return false;
      if (EquipmentHelper.getCooling(item)) return false;
      if (EquipmentHelper.getLowerDrawer(item)) return false;
      if (EquipmentHelper.getHeatingCabinet(item)) return false;
      if (EquipmentHelper.getHeatingDrawer(item)) return false;
      if (EquipmentHelper.getEmptyingSystem(item)) return false;
      if (EquipmentHelper.getEOven(item)) return false;
      if (EquipmentHelper.getGOven(item)) return false;
      if (EquipmentHelper.getWarmingDrawer(item)) return false;
      if (EquipmentHelper.getWarmingDevice(item)) return false;
      if (EquipmentHelper.getGNFrame(item)) return false;
      if (EquipmentHelper.getGNFrame(item, 1)) return false;
      if (EquipmentHelper.getIntermediatePlate(item)) return false;

      return true;
    }
    return false;
  }
  public static getWasteDrawer(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.WasteDrawer);
  }
  public static setWasteDrawer(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.WasteDrawer) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.WasteDrawer, true);
  }

  public static canHaveColdWaterInlet(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.ColdWaterInlet)) return false;
    const width = item.getWidthSubstructure();
    if (width >= 300 && width <= 1200) {
      // TODO

      return true;
    }
    return false;
  }
  public static getColdWaterInlet(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.ColdWaterInlet);
  }
  public static setColdWaterInlet(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.ColdWaterInlet) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.ColdWaterInlet, true);
  }

  public static canHaveDoubleSidedOperation(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.DoubleSidedOperation)) return false;
    const width = item.getWidthSubstructure();
    if (item.getBlock().getType() === 'Single' && item.getBlock().getSingleType() === 'Free') {
      return true;
    }
    return false;
  }
  public static getDoubleSidedOperation(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.DoubleSidedOperation);
  }
  public static setDoubleSidedOperation(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.DoubleSidedOperation) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.DoubleSidedOperation, true);
  }

  public static canHaveBlindCover(item: BlockItem | BlockGroup) {
    if (!item.compatibleWithEquipment(Subtype.BlindCover)) return false;
    const width = item.getWidthSubstructure();
    if (width <= 200) {
      return true;
    }
    return false;
  }
  public static getBlindCover(item: BlockItem | BlockGroup) {
    return EquipmentHelper.getEquipment(item, Subtype.BlindCover);
  }
  public static setBlindCover(item: BlockItem | BlockGroup, device: Device) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.BlindCover) {
        EquipmentHelper.addEquipment(item, device);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.BlindCover, true);
  }

  public static canHaveBaseCover(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.BaseCover)) return false;
    return true;
  }
  public static getBaseCover(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.BaseCover, index);
  }
  public static setBaseCover(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.BaseCover) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.BaseCover, true, index);
  }

  public static canHaveReadyXpress(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.ReadyXpress)) return false;
    return true;
  }
  public static getReadyXpress(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.ReadyXpress, index);
  }
  public static setReadyXpress(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.ReadyXpress) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.ReadyXpress, true, index);
  }

  public static canHaveSpaceClean(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.SpaceClean)) return false;
    return true;
  }
  public static getSpaceClean(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.SpaceClean, index);
  }
  public static setSpaceClean(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.SpaceClean) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.SpaceClean, true, index);
    item.getNode().bake();
  }

  public static canHaveMagicHood(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.MagicHood)) return false;
    return true;
  }
  public static getMagicHood(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.MagicHood, index);
  }
  public static setMagicHood(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.MagicHood) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.MagicHood, true, index);
  }

  public static canHaveFlexiCombiAir(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.FlexiCombiAir)) return false;
    return true;
  }
  public static getFlexiCombiAir(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.FlexiCombiAir, index);
  }
  public static setFlexiCombiAir(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.FlexiCombiAir) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.FlexiCombiAir, true, index);
  }

  public static canHaveGNFrame(item: BlockItem | BlockGroup, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.GNFrame)) return false;
    const width = item.getWidthSubstructure();
    if (width < 500 || width > 1400) return false;
    if (EquipmentHelper.getAngularRail(item)) return false;
    if (EquipmentHelper.getCooling(item)) return false;
    if (EquipmentHelper.getWasteDrawer(item)) return false;
    if (EquipmentHelper.getLowerDrawer(item)) return false;
    if (EquipmentHelper.getHeatingCabinet(item)) return false;
    if (EquipmentHelper.getEmptyingSystem(item)) return false;
    if (EquipmentHelper.getEOven(item)) return false;
    if (EquipmentHelper.getGOven(item)) return false;
    if (EquipmentHelper.getHeatingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDevice(item)) return false;
    if (EquipmentHelper.getIntermediatePlate(item)) return false;
    return true;
  }
  public static getGNFrame(item: BlockItem | BlockGroup, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.GNFrame, index);
  }
  public static setGNFrame(item: BlockItem | BlockGroup, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.GNFrame) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.GNFrame, true, index);
  }

  public static canHaveWarmingDrawer(item: BlockItem | BlockGroup, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.WarmingDrawer)) return false;
    const width = item.getWidthSubstructure();
    if (width !== 800) return false;
    if (EquipmentHelper.getWingedDoor(item)) return false;
    if (EquipmentHelper.getHygiene(item)) return false;
    if (EquipmentHelper.getOpenSubstructure(item)) return false;
    if (EquipmentHelper.getAngularRail(item)) return false;
    if (EquipmentHelper.getCooling(item)) return false;
    if (EquipmentHelper.getWasteDrawer(item)) return false;
    if (EquipmentHelper.getLowerDrawer(item)) return false;
    if (EquipmentHelper.getHeatingCabinet(item)) return false;
    if (EquipmentHelper.getEmptyingSystem(item)) return false;
    if (EquipmentHelper.getEOven(item)) return false;
    if (EquipmentHelper.getGOven(item)) return false;
    if (EquipmentHelper.getHeatingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDevice(item)) return false;
    if (EquipmentHelper.getGNFrame(item)) return false;
    if (EquipmentHelper.getGNFrame(item, 1)) return false;
    if (EquipmentHelper.getIntermediatePlate(item)) return false;
    return true;
  }
  public static getWarmingDrawer(item: BlockItem | BlockGroup, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.WarmingDrawer, index);
  }
  public static setWarmingDrawer(item: BlockItem | BlockGroup, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.WarmingDrawer) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.WarmingDrawer, true, index);
  }

  public static canHaveWarmingDevice(item: BlockItem | BlockGroup, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.WarmingDevice)) return false;
    const width = item.getWidthSubstructure();
    if (width !== 500) return false;
    if (EquipmentHelper.getWingedDoor(item)) return false;
    if (EquipmentHelper.getHygiene(item)) return false;
    if (EquipmentHelper.getOpenSubstructure(item)) return false;
    if (EquipmentHelper.getAngularRail(item)) return false;
    if (EquipmentHelper.getCooling(item)) return false;
    if (EquipmentHelper.getWasteDrawer(item)) return false;
    if (EquipmentHelper.getLowerDrawer(item)) return false;
    if (EquipmentHelper.getHeatingCabinet(item)) return false;
    if (EquipmentHelper.getEmptyingSystem(item)) return false;
    if (EquipmentHelper.getEOven(item)) return false;
    if (EquipmentHelper.getGOven(item)) return false;
    if (EquipmentHelper.getHeatingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDrawer(item)) return false;
    if (EquipmentHelper.getGNFrame(item)) return false;
    if (EquipmentHelper.getGNFrame(item, 1)) return false;
    if (EquipmentHelper.getIntermediatePlate(item)) return false;
    return true;
  }
  public static getWarmingDevice(item: BlockItem | BlockGroup, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.WarmingDevice, index);
  }
  public static setWarmingDevice(item: BlockItem | BlockGroup, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.WarmingDevice) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.WarmingDevice, true, index);
  }

  public static canHaveIntermediatePlate(item: BlockItem | BlockGroup, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.IntermediatePlate)) return false;
    const width = item.getWidthSubstructure();
    if (width < 300 || width > 1400) return false;
    if (EquipmentHelper.getAngularRail(item)) return false;
    if (EquipmentHelper.getCooling(item)) return false;
    if (EquipmentHelper.getWasteDrawer(item)) return false;
    if (EquipmentHelper.getLowerDrawer(item)) return false;
    if (EquipmentHelper.getEmptyingSystem(item)) return false;
    if (EquipmentHelper.getEOven(item)) return false;
    if (EquipmentHelper.getGOven(item)) return false;
    if (EquipmentHelper.getHeatingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDrawer(item)) return false;
    if (EquipmentHelper.getWarmingDevice(item)) return false;
    if (EquipmentHelper.getGNFrame(item)) return false;
    if (EquipmentHelper.getGNFrame(item, 1)) return false;
    return true;
  }

  public static canHaveFlexiCombiDoor(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    if (!item.compatibleWithEquipment(Subtype.FlexiCombiDoor)) return false;
    return true;
  }

  public static getFlexiCombiDoor(item: BlockItem | BlockGroup | FreeItem, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.FlexiCombiDoor, index);
  }
  public static setFlexiCombiDoor(item: BlockItem | BlockGroup | FreeItem, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.FlexiCombiDoor) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.FlexiCombiDoor, true, index);
    item.getNode().bake();
  }

  public static getIntermediatePlate(item: BlockItem | BlockGroup, index = 0) {
    return EquipmentHelper.getEquipment(item, Subtype.IntermediatePlate, index);
  }
  public static setIntermediatePlate(item: BlockItem | BlockGroup, device: Device, index = 0) {
    if (device) {
      let deviceSubtype: Subtype | string = device.subtype;
      if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
      if (deviceSubtype === Subtype.IntermediatePlate) {
        EquipmentHelper.addEquipment(item, device, index);
      }
    } else EquipmentHelper.removeEquipment(item, Subtype.IntermediatePlate, true, index);
  }
}
