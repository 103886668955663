import React, { useContext } from 'react';
import { LangContext } from 'context';
import Localizations from 'languages';

interface Props {
  header: string;
  text: string;
}

export const TextWithHeader: React.FC<Props> = ({ header, text }) => {
  const { lang } = useContext(LangContext);

  return (
    <>
      <h4>{Localizations[header][lang]}</h4>
      <p>{Localizations[text][lang]}</p>
    </>
  );
};
