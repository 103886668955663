import BlockNode from 'components/babylon/node/BlockNode';
import BlockItem from 'page/Editor/configuration/BlockItem';
import BlockGroup from 'page/Editor/configuration/BlockGroup';
import Block from 'page/Editor/configuration/Block';

import { Device, DeviceSeries, ErrorAlert } from 'types';
import { isFryer } from 'utils';

export const addDevice = (
  scene: any,
  device: Device,
  setDialog: (dialog: 'battery' | 'socket' | 'oven' | null) => void,
  setErrorAlert: (err: ErrorAlert[]) => void,
  setCoverEnlargementSize: (sizeMessage: string) => void,
  filter?: DeviceSeries,
  block?: Block
) => {
  let addBlock = block;
  if (!block) {
    const blocks = scene._scene.getTransformNodesByID('block');
    for (let i = 0; i < blocks.length; i++) {
      const b = blocks[i];
      if (b instanceof BlockNode) {
        addBlock = b.getBlock();
      }
    }
  }

  const topRow = addBlock.getRowTop();
  const bottomRow = addBlock.getRowBottom();

  const shouldAddToTop = topRow && topRow.getDepth().toString() === filter && addBlock.canItemBeDropped(device, topRow);
  const shouldAddToBottom = bottomRow && bottomRow.getDepth().toString() === filter && addBlock.canItemBeDropped(device, bottomRow);

  if (device?.dependency?.coverEnlargementSize > 0) {
    setCoverEnlargementSize(`${device.dependency.coverEnlargementSize}mm.`);
  }

  if (addBlock) {
    let addedDevice: BlockItem | BlockGroup;
    const errorAlerts: ErrorAlert[] = [];

    if ((shouldAddToTop || filter === 'all') && addBlock.canItemBeDropped(device, topRow)) {
      addedDevice = topRow.addItem(device);
      //scene.setSelected(addedDevice);
      if (device.dependency.coverEnlargement && device.model.masterline) {
        errorAlerts.push('coverEnlargement');
      }

      if (isFryer(device.category)) {
        errorAlerts.push('fryer');
      }
    } else if (shouldAddToBottom || (filter === 'all' && addBlock.canItemBeDropped(device, bottomRow))) {
      addedDevice = bottomRow.addItem(device);
      //scene.setSelected(addedDevice);
      if (device.dependency.coverEnlargement && device.model.masterline) {
        errorAlerts.push('coverEnlargement');
      }
      if (isFryer(device.category)) {
        errorAlerts.push('fryer');
      }
    } else {
      errorAlerts.push('size');
    }

    if (errorAlerts.length > 0) {
      setErrorAlert(errorAlerts);
    }
  }
};
