import { Icon } from 'atoms';
import { DeleteConfirm } from 'components';
import { LangContext } from 'context';
import { useOrganizationContext } from 'page/Organization/context/OrganizationDataProvider';
import { useContext, useState } from 'react';
import { Member } from 'types';
import { getLabelForRight } from 'utils';
import { useUpdateOrganization } from '../../../hooks/useUpdateOrganization';

export const TableRow = ({ member }: { member: Member }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { lang } = useContext(LangContext);

  const { setOrganizationData, organizationData, setSelectedUser } = useOrganizationContext();
  const { handleSaveOrg } = useUpdateOrganization();

  const { active, email, supervisor, rights } = member;

  const handleDelete = async () => {
    const updatedMembers = organizationData?.members?.filter(m => m.id !== member.id);
    const { data } = await handleSaveOrg({ members: updatedMembers });
    if (data) {
      setOrganizationData(data);

      setDialogOpen(false);
    }
  };

  const handleSelect = () => setSelectedUser(member);

  return (
    <>
      <tr>
        <td className="first-col" onClick={handleSelect}>
          {email}
        </td>
        <td className="second-col" onClick={handleSelect}>
          {rights.map((r, i) => `${getLabelForRight({ right: r, langId: lang })}${i === rights.length - 1 ? '' : ', '}`)}
        </td>
        <td className="third-col" onClick={handleSelect}>
          {supervisor && <Icon size="1rem" type="check" />}
        </td>
        <td className="fourth-col">{active && <Icon size="1rem" type="check" />}</td>
      </tr>
      {dialogOpen && <DeleteConfirm abort={() => setDialogOpen(false)} confirm={handleDelete} isUser />}
    </>
  );
};
