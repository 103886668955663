import React, { Fragment, FC, useContext } from 'react';

// Components:
import { Paragraph } from 'atoms';

// Context:
import { EditorContext } from 'context';

// ===================================================================
export const Sharing: FC = () => {
  const { savedConfiguration } = useContext(EditorContext);

  return (
    <Fragment>
      <Paragraph underlined width="100%" textAlign="center" padding="0 0 0.5rem" margin="2rem 0 4rem">
        {savedConfiguration && savedConfiguration.id}
      </Paragraph>
    </Fragment>
  );
};
