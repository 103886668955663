import React, { FC, useContext, useState } from 'react';

import { Portal } from 'react-portal';

// Atoms:
import { ASimple, ADialog, Button, Heading, Icon } from 'atoms';

// Components:
import { LoadingSpinner } from 'components';

// Context:
import { LangContext } from 'context';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';
import { colors } from 'styles/theme';

// ===================================================================
interface Props {
  confirm: () => void;
  abort: () => void;
  isUser?: boolean;
}

// ===================================================================
export const DeleteConfirm: FC<Props> = ({ confirm, abort, isUser }) => {
  const { lang } = useContext(LangContext);
  const [loading] = useState<boolean>(false);

  /* Render */
  return (
    <Portal>
      {/* Background */}
      <ASimple key="DelteConfirm-Background" className="DelteConfirm-Background" style={{ height: window.innerHeight }} onClick={abort} />

      {/* Content */}
      <ADialog key="DelteConfirm-Content" className="DelteConfirm-Content" topExit="0" topEnter="50%">
        {/* Close Icon */}
        <div className="DelteConfirm-Content-Icon">
          <Icon type="close" size="1.25rem" color="grey" onClick={abort} />
        </div>

        {/* Main */}
        <div className="DelteConfirm-Content-Main">
          <Heading level="1" color="dark">
            {Localizations['delete'][lang]}
          </Heading>

          <p style={{ color: colors.medium, flex: 1, fontSize: '.9rem', letterSpacing: '0.3px', lineHeight: 1.5 }}>
            {isUser ? Localizations['confirmDelete'][lang] : Localizations['confirmConfigDelete'][lang]}
          </p>

          <Button btnType="third" maxWidth={400} onClick={confirm} margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
            {loading ? <LoadingSpinner size="1rem" /> : Localizations['delete'][lang]}
          </Button>
          <Button btnType="fourth" maxWidth={400} onClick={abort} margin="0rem 0 1rem 0" padding=".65rem 2rem" fontSize=".8rem">
            {loading ? <LoadingSpinner size="1rem" /> : Localizations['cancel'][lang]}
          </Button>
        </div>
      </ADialog>
    </Portal>
  );
};
