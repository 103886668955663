import * as BABYLON from 'babylonjs';
import BlockRow from 'page/Editor/configuration/BlockRow';
import Scene from 'page/Editor/Scene';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import BlockGroupNode from './BlockGroupNode';
import BlockNode from './BlockNode';
import DeviceNode, { PropertyType as DNPT } from './DeviceNode';

type GroundConfig = {
  node?: BABYLON.TransformNode;
  plane?: BABYLON.Mesh;
  label?: BABYLON.TransformNode;
};

export default class BlockRowNode extends BABYLON.TransformNode {
  private ground: GroundConfig = {};
  private devices: BABYLON.TransformNode;
  private unusedSpaceLabel: BABYLON.TransformNode;
  private preDepth = 0;
  private preWidth = 0;
  private row: BlockRow = null;

  constructor(name: 'top' | 'bottom', parent: BABYLON.TransformNode) {
    super('row.' + name, Scene.CURRENT_SCENE, undefined);
    this.parent = parent;

    this.ground.node = new BABYLON.TransformNode('ground');
    this.ground.node.parent = this;
    this.ground.node.position = new BABYLON.Vector3(0, 0.2, 0);
    this.ground.plane = BABYLON.Mesh.CreateGround('ground.plane', 1, 1, 1, Scene.CURRENT_SCENE);
    this.ground.plane.parent = this.ground.node;
    this.ground.plane.material = BlockNode.defaultSettings.materials.ground;
    this.devices = new BABYLON.TransformNode('devices');
    this.devices.parent = this;
  }

  public updateGround(width: number) {
    if (width <= 0) width = this.preWidth;

    const depth = this.getRow().getDepth();
    const depthExtended = this.getRow().getDepthExtended();
    const extension = (depthExtended - depth) / 10;
    const extensionSingleFree =
      this.getRow().getType() === 'Bottom' && this.getBlock().getType() === 'Single' && this.getBlock().getSingleType() === 'Free' ? 40 : 0;
    const totalDepth = depthExtended + extensionSingleFree;

    const w = width / 10;
    const d = depthExtended / 10;

    this.ground.plane.scaling = new BABYLON.Vector3(w, 1, d);
    this.ground.plane.position.x = w / 2;
    this.ground.plane.position.z = d / 2;

    if (totalDepth !== this.preDepth) {
      this.preDepth = totalDepth;
      if (this.ground.label) this.ground.label.dispose();
      this.ground.label = LabelUtils.drawLabel('' + totalDepth, totalDepth / 10, Orientation.Right);
      this.ground.label.parent = this.ground.node;
      this.ground.label.setEnabled(this.row.getParent().isShowLabels());
    }
    this.ground.label.position.x = w;
    this.ground.label.position.z = 0; //this.getRow().getType() === 'Bottom' ? -extension : 0;

    this.devices.position.z = d;

    this.preWidth = width;
    this.updateUnusedSpaceLabel();
  }

  public updateUnusedSpaceLabel() {
    if (this.row) {
      const deviceWidth = this.getRealWidth();
      const width = this.preWidth - deviceWidth;
      if (this.unusedSpaceLabel) this.unusedSpaceLabel.dispose();
      if (width > 0) {
        const bottom = this.row.getType() === 'Bottom';
        this.unusedSpaceLabel = LabelUtils.drawLabel('' + width, width / 10, bottom ? Orientation.Bottom : Orientation.Top);
        this.unusedSpaceLabel.parent = this;
        this.unusedSpaceLabel.position.x = deviceWidth / 10;
        if (!bottom) this.unusedSpaceLabel.position.z = this.getRow().getDepthExtended() / 10;
        this.unusedSpaceLabel.setEnabled(this.row.getParent().isShowLabels());
      } else this.unusedSpaceLabel = null;
      // Update Block
      this.row.getParent().getNode().updateLabels();
    }
  }

  public getLabel() {
    return this.ground.label;
  }

  public getUnusedSpaceLabel() {
    return this.unusedSpaceLabel;
  }

  public getRealWidth() {
    let w = 0;
    this.foreachDeviceNode(node => {
      w += node.get(DNPT.Width) as number;
    });
    return w;
  }

  public getDeviceNode() {
    return this.devices;
  }

  public foreachDeviceNode(fn: (node: DeviceNode | BlockGroupNode) => void) {
    const nodes = this.devices.getChildTransformNodes(true);
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (!node.isDisposed() && (node instanceof DeviceNode || node instanceof BlockGroupNode)) fn(node);
    }
  }

  public getRow() {
    return this.row;
  }

  public setRow(row: BlockRow) {
    this.row = row;
  }

  public getBlock() {
    return this.row.getBlock();
  }
}
