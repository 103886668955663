import DeviceNode, { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import BasicUtils from 'components/babylon/util/BasicUtils';
import { Device } from 'types/Device';
import { Subtype } from 'types/DeviceEnum';
import { getDevicesForCodeMaybe } from 'utils';
import Block from './Block';
import BlockGroup from './BlockGroup';
import BlockRow from './BlockRow';
import Equipment, { EquipmentHelper } from './Equipment';
import { isDeviceCompatibleWithDepth } from './Utils';
import Vector3 from './Vector3';

export default class BlockItem {
  public clazz = 'Item';
  private _uniqueId: string;
  private device: string;
  public _device: Device;

  private equipments: Array<Equipment> = new Array<Equipment>();

  private position: Vector3 = new Vector3();
  private rotation: Vector3 = new Vector3();
  private dimensions: Vector3 = null;

  private _node: DeviceNode;
  private _parent: BlockRow | BlockGroup;

  private _error: boolean;

  constructor(parent: BlockRow, device: Device) {
    this._uniqueId = Date.now() + BasicUtils.generateRandomString(16);
    this._parent = parent;
    this._device = device;
    this.device = device.id;
    if (device.style === 'Dummy') this.dimensions = new Vector3(100, 850, parent.getDepth());
    else if (device.style.startsWith('LoweredWorktable') || device.style.startsWith('CoverPlateOverhang') || device.style.startsWith('ShowWidthSelection')) {
      let minWidth = 100;
      device.style.split(';').forEach(s => {
        if (s.startsWith('widthMin=')) minWidth = Number(s.substring(9));
      });
      this.dimensions = new Vector3(minWidth, device.model.height, parent.getDepth());
    }
    this.initNode();
  }

  private initNode() {
    if (this._node) {
      this._node.dispose();
    }
    // Created 3D Node
    this._node = new DeviceNode(this);
    if (this._device.style.startsWith('LoweredWorktable')) {
      this._node.set(DNPT.Special, 'Lowered');
    }
    if (this._device.style.startsWith('CoverPlateOverhang')) {
      this._node.set(DNPT.Special, 'CoverOnly');
    }
  }

  public onDepthChange() {
    if (isDeviceCompatibleWithDepth(this._device, this.getBlockRow().getDepth())) {
      this._node.set(DNPT.Depth, this.getBlockRow().getDepth(), true);
      return;
    }
    const devices = getDevicesForCodeMaybe(this._device.code, 'code', this.getBlock().getBlockType());
    if (devices != null) {
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.id !== this._device.id) {
          if (isDeviceCompatibleWithDepth(device, this.getBlockRow().getDepth())) {
            this.device = device.id;
            this._device = device;
            this._node.updateDevice(device);
            //this._node.set(DNPT.Depth, this.getBlockRow().getDepth());
            return;
          }
        }
      }
    }
  }

  public compatibleWithDepth(depth: number) {
    for (let i = 0; i < this.equipments.length; i++) {
      const equipment = this.equipments[i];
      if (!isDeviceCompatibleWithDepth(equipment.getDeviceObject(), depth)) return false;
    }
    if (isDeviceCompatibleWithDepth(this._device, depth)) return true;
    const devices = getDevicesForCodeMaybe(this._device.code, 'code', this.getBlock().getBlockType());
    if (devices != null) {
      for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.id !== this._device.id) {
          if (isDeviceCompatibleWithDepth(device, depth)) return true;
        }
      }
    }
    return false;
  }

  public compatibleWithEquipment(value: Subtype) {
    for (let i = 0; i < this._device.dependency.equipmentCombinations.length; i++) {
      let comb: string | Subtype = this._device.dependency.equipmentCombinations[i];
      if (typeof comb === 'string') comb = Subtype[comb];
      if (comb === value) {
        return true;
      }
    }
    return false;
  }

  public mergeWith(item: BlockItem) {
    const parent = this.getParent();
    if (parent instanceof BlockGroup) {
      parent.mergeWith(item);
    } else if (parent instanceof BlockRow) {
      const isBottom = parent.getType() === 'Bottom';
      // Same Item? wtf!
      if (this.getUniqueId() === item.getUniqueId()) return;
      // Check Width
      if (this.getWidth() + item.getWidth() > BlockGroup._maxGroupSize) return;

      const arr = [];
      let selfIndex = -1;
      for (let i = 0; i < parent.getItems().length; i++) {
        const e = parent.getItems()[i];
        // Find this item in row
        if (this.getUniqueId() === e.getUniqueId()) {
          selfIndex = i;
          // Is merge Item right of self
          if (
            i <= parent.getItems().length - 2 &&
            parent.getItems()[i + 1].getUniqueId() === item.getUniqueId() &&
            ((isBottom && this.getDeviceObject().dependency.expandableRight) || (!isBottom && this.getDeviceObject().dependency.expandableLeft)) &&
            ((isBottom && item.getDeviceObject().dependency.expandableLeft) || (!isBottom && item.getDeviceObject().dependency.expandableRight))
          ) {
            arr.push(this, item);
          }
          // Is merge Item left of self
          else if (
            i > 0 &&
            parent.getItems()[i - 1].getUniqueId() === item.getUniqueId() &&
            ((isBottom && this.getDeviceObject().dependency.expandableLeft) || (!isBottom && this.getDeviceObject().dependency.expandableRight)) &&
            ((isBottom && item.getDeviceObject().dependency.expandableRight) || (!isBottom && item.getDeviceObject().dependency.expandableLeft))
          ) {
            arr.push(item, this);
          }
          break;
        }
      }
      if (arr.length === 2 && selfIndex >= 0) {
        const group = new BlockGroup(parent, arr);
        parent.addItem(group, selfIndex);
        group.getNode().set(DNPT.Mark, true);
      }
    }
  }

  public getUniqueId() {
    return this._uniqueId;
  }

  public getWidth(): number {
    if (this.dimensions) return Math.max(50, this.dimensions.x);
    return this._device.model.width;
  }

  public setWidth(value: number, bake: boolean = true, force = false) {
    if (this.dimensions) {
      if (force || this.getBlockRow().getWidth() + (value - this.dimensions.x) <= this.getBlock().getWidth()) {
        EquipmentHelper.clearEquipment(this);
        this.dimensions.x = Math.max(50, value);
        this.getNode().set(DNPT.Width, this.dimensions.x);
        this.getBlockRow().repositionItems();
        this.getBlockRow().getNode().updateUnusedSpaceLabel();
      }
    }
    if (bake) this.getNode().bake();
  }

  public getHeight(): number {
    if (this.dimensions) return Math.max(100, this.dimensions.y);
    return this._device.model.height;
  }

  public setHeight(value: number, bake: boolean = true) {
    if (this.dimensions) this.dimensions.y = Math.max(100, value);
    if (bake) this.getNode().bake();
  }

  public getDepth(): number {
    if (this.dimensions) return Math.max(100, this.dimensions.z);
    return this.getBlockRow().getDepth();
  }

  public setDepth(value: number, bake: boolean = true) {
    if (this.dimensions) Math.max(100, (this.dimensions.z = value));
    if (bake) this.getNode().bake();
  }

  public getWidthSubstructure() {
    if (this._device.model.widthSubstructure > 0) return this._device.model.widthSubstructure;
    if (this.dimensions) return this.dimensions.x;
    return this._device.model.width;
  }

  public getDeviceId() {
    return this.device;
  }

  public getDeviceObject() {
    return this._device;
  }

  public setDeviceObject(device: Device) {
    this.device = device.id;
    this._device = device;
    this._node.updateDevice(device);
    this._node.bake();
  }

  public getEquipments() {
    return this.equipments;
  }

  public getPosition() {
    return this.position;
  }

  public getRotation() {
    return this.rotation;
  }

  public getNode() {
    return this._node;
  }

  public getParent() {
    return this._parent;
  }

  public setParent(parent: BlockRow | BlockGroup) {
    this._parent = parent;
    this._node.parent = parent.getNode().getDeviceNode();
  }

  public getBlockRow(): BlockRow {
    if (this._parent instanceof BlockRow) return this._parent;
    else return this._parent.getParent();
  }

  public getBlock(): Block {
    return this._parent.getBlock();
  }

  public isError() {
    return this._error;
  }

  public setError(value: boolean) {
    this._error = value;
    this._node
      .getOutputNode()
      .getChildMeshes()
      .forEach(m => (m.renderOverlay = value));
  }

  public canItemBeMerged(): boolean {
    if (this.getParent() instanceof BlockGroup) return false;
    if (!this.getDeviceObject().dependency.expandableLeft && !this.getDeviceObject().dependency.expandableRight) return false;
    const bottom = this.getBlockRow().getType() === 'Bottom';
    const prev = this.prev();
    if (prev) {
      if (prev instanceof BlockGroup) return false;
      else if (
        ((bottom && prev.getDeviceObject().dependency.expandableRight) || (!bottom && prev.getDeviceObject().dependency.expandableLeft)) &&
        this.getDeviceObject().model.width + prev.getDeviceObject().model.width <= BlockGroup._maxGroupSize
      )
        return true;
    }
    const next = this.next();
    if (next) {
      if (next instanceof BlockGroup) return false;
      else if (
        ((bottom && next.getDeviceObject().dependency.expandableLeft) || (!bottom && next.getDeviceObject().dependency.expandableRight)) &&
        this.getDeviceObject().model.width + next.getDeviceObject().model.width <= BlockGroup._maxGroupSize
      )
        return true;
    }
    return false;
  }

  public prev(): BlockItem {
    if (this._parent instanceof BlockRow) {
      const isBottom = this._parent.getType() === 'Bottom';
      for (let i = 0; i < this._parent.getItems().length; i++) {
        const item = this._parent.getItems()[i];
        if (this.getUniqueId() === item.getUniqueId()) {
          if (isBottom ? i > 0 : i <= this._parent.getItems().length - 2) {
            const prev = this._parent.getItems()[isBottom ? i - 1 : i + 1];
            if (prev instanceof BlockItem) return prev;
            else return prev.getItems()[prev.getItems().length - 1];
          } else return null;
        }
      }
    } else {
      const isBottom = this._parent.getParent().getType() === 'Bottom';
      for (let i = 0; i < this._parent.getItems().length; i++) {
        const item = this._parent.getItems()[i];
        if (this.getUniqueId() === item.getUniqueId()) {
          if (i > 0) {
            return this._parent.getItems()[i - 1];
          } else {
            for (let j = 0; j < item.getParent().getItems().length; j++) {
              const parentItem = item.getParent().getItems()[j];
              if (item.getUniqueId() === parentItem.getUniqueId()) {
                if (isBottom ? j > 0 : j <= this._parent.getItems().length - 2) {
                  const prevParent = item.getParent().getItems()[isBottom ? j - 1 : j + 1];
                  if (prevParent instanceof BlockItem) return prevParent;
                  else return prevParent.getItems()[prevParent.getItems().length - 1];
                } else return null;
              }
            }
          }
          break;
        }
      }
    }
    return null;
  }

  public next(): BlockItem {
    if (this._parent instanceof BlockRow) {
      const isBottom = this._parent.getType() === 'Bottom';
      for (let i = 0; i < this._parent.getItems().length; i++) {
        const item = this._parent.getItems()[i];
        if (this.getUniqueId() === item.getUniqueId()) {
          if (isBottom ? i <= this._parent.getItems().length - 2 : i > 0) {
            const next = this._parent.getItems()[isBottom ? i + 1 : i - 1];
            if (next instanceof BlockItem) return next;
            else return next.getItems()[0];
          } else return null;
        }
      }
    } else {
      const isBottom = this._parent.getParent().getType() === 'Bottom';
      for (let i = 0; i < this._parent.getItems().length; i++) {
        const item = this._parent.getItems()[i];
        if (this.getUniqueId() === item.getUniqueId()) {
          if (i <= this._parent.getItems().length - 2) {
            return this._parent.getItems()[i + 1];
          } else {
            for (let j = 0; j < item.getParent().getItems().length; j++) {
              const parentItem = item.getParent().getItems()[j];
              if (item.getUniqueId() === parentItem.getUniqueId()) {
                if (isBottom ? j <= this._parent.getItems().length - 2 : j > 0) {
                  const nextParent = item.getParent().getItems()[isBottom ? j + 1 : j - 1];
                  if (nextParent instanceof BlockItem) return nextParent;
                  else return nextParent.getItems()[0];
                } else return null;
              }
            }
          }
          break;
        }
      }
    }
    return null;
  }

  public delete() {
    if (this._parent instanceof BlockRow) {
      this._parent.removeItem(this);
      this._node.dispose();
    } else {
      this._parent.disband();
      this.delete();
    }
    this.getBlock().checkFullBlendColor();
  }
}
