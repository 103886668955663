import BlockGroupNode from 'components/babylon/node/BlockGroupNode';
import { PropertyType as DNPT } from 'components/babylon/node/DeviceNode';
import BasicUtils from 'components/babylon/util/BasicUtils';
import { Subtype } from 'types/DeviceEnum';
import BlockItem from './BlockItem';
import BlockRow from './BlockRow';
import Configuration from './Configuration';
import Equipment, { EquipmentHelper } from './Equipment';

export default class BlockGroup {
  static _maxGroupSize = 1400;

  public clazz = 'Group';
  private _uniqueId: string;

  private items: Array<BlockItem> = new Array<BlockItem>();

  private equipments: Array<Equipment> = new Array<Equipment>();

  private _node: BlockGroupNode;
  private _parent: BlockRow;

  constructor(parent: BlockRow, items: BlockItem[]) {
    this._uniqueId = Date.now() + BasicUtils.generateRandomString(16);
    this._parent = parent;
    this._node = new BlockGroupNode(this);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.getNode().set(DNPT.Mark, false);
      item.getNode().set(DNPT.MarkFull, false);
      item.getNode().set(DNPT.Label, false);
      this.items.push(item);
      item.setParent(this);
      EquipmentHelper.clearEquipment(item, 'Lower', true);
    }
    let refreshed = false;
    if (this._parent.getParent().isShowLabels()) {
      this._node.refreshVisuals();
      refreshed = true;
      this._node.set(DNPT.Label, true);
    }
    if (this._parent.getParent().isShowConnections()) {
      this._node.setConnectLabel(true);
      if (!refreshed) this._node.refreshVisuals();
    }
  }

  /**
   * Deletes this group but contins all children in parent row
   */
  public disband() {
    let groupIndex = -1;
    for (let i = 0; i < this._parent.getItems().length; i++) {
      const item = this._parent.getItems()[i];
      if (this.getUniqueId() === item.getUniqueId()) {
        groupIndex = i;
        break;
      }
    }
    if (groupIndex >= 0) {
      this.getNode().setAll(DNPT.MergeCorpusLeft, false);
      this.getNode().setAll(DNPT.MergeCorpusRight, false);
      this.getNode().setAll(DNPT.Label, this.getNode().get(DNPT.Label));
      for (let i = this.getItems().length - 1; i >= 0; i--) {
        const item = this.getItems()[i];
        this._parent.addItem(item, groupIndex);
      }
      this._parent.removeItem(this);
    }
    this._node.dispose();
  }

  public mergeWith(item: BlockItem) {
    const itemParent = item.getParent();
    if (this.numberOfFunctionItem() >= 2 && Configuration.isFunctionItem(item)) return;
    if (itemParent instanceof BlockGroup) {
      // Can't add Item
      return;
    } else {
      const isBottom = this._parent.getType() === 'Bottom';
      // Add Item?
      // Will merged item be to big?
      if (this.getWidth() + item.getWidth() > BlockGroup._maxGroupSize) return;
      for (let i = 0; i < this._parent.getItems().length; i++) {
        const e = this._parent.getItems()[i];
        // Search for self position in parent.
        if (e.getUniqueId() === this.getUniqueId()) {
          let bake = false;
          // Is new Item right of self
          if (
            i <= this._parent.getItems().length - 2 &&
            this._parent.getItems()[i + 1].getUniqueId() === item.getUniqueId() &&
            this.items[this.items.length - 1].getDeviceObject().dependency.expandableRight &&
            ((isBottom && item.getDeviceObject().dependency.expandableLeft) || (!isBottom && item.getDeviceObject().dependency.expandableRight))
          ) {
            EquipmentHelper.clearEquipment(item);
            EquipmentHelper.clearEquipment(this);
            item.setParent(this);
            item.getNode().set(DNPT.Mark, false);
            item.getNode().set(DNPT.MarkFull, false);
            item.getNode().set(DNPT.Label, false);
            this.items.push(item);
            this.repositionItems();
            itemParent.removeItem(item);
            bake = true;
          }
          // Is new Item left of self
          else if (
            i > 0 &&
            this._parent.getItems()[i - 1].getUniqueId() === item.getUniqueId() &&
            this.items[0].getDeviceObject().dependency.expandableLeft &&
            ((isBottom && item.getDeviceObject().dependency.expandableRight) || (!isBottom && item.getDeviceObject().dependency.expandableLeft))
          ) {
            EquipmentHelper.clearEquipment(item);
            EquipmentHelper.clearEquipment(this);
            item.getNode().set(DNPT.Mark, false);
            item.getNode().set(DNPT.MarkFull, false);
            item.getNode().set(DNPT.Label, false);
            item.setParent(this);
            this.items.unshift(item);
            this.repositionItems();
            itemParent.removeItem(item);
            bake = true;
          }
          if (bake) this._node.bake();
          break;
        }
      }
    }
  }

  public revertItems() {
    const newItems = [];
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      newItems.unshift(item);
    }
    this.items = newItems;
  }

  public repositionItems() {
    let newX = 0;
    const isBottom = this._parent.getType() === 'Bottom';
    for (let i = 0; i < this.items.length; i++) {
      // console.log('set', i, 'to', newX)
      const e = this.items[i];
      const width = e.getWidth() / 10;

      e.getNode().setPosition({ x: newX, y: 0, z: 0 });

      const isLeft = isBottom ? i < 1 : i >= this.items.length - 1;
      const isRight = isBottom ? i >= this.items.length - 1 : i < 1;

      if (isLeft) {
        let bake = false;
        if (!e.getNode().get(DNPT.MergeCorpusRight)) {
          e.getNode().set(DNPT.MergeCorpusRight, true);
          bake = true;
        }
        if (e.getNode().get(DNPT.MergeCorpusLeft)) {
          e.getNode().set(DNPT.MergeCorpusLeft, false);
          bake = true;
        }
        if (bake) e.getNode().bake();
      } else if (isRight) {
        let bake = false;
        if (e.getNode().get(DNPT.MergeCorpusRight)) {
          e.getNode().set(DNPT.MergeCorpusRight, false);
          bake = true;
        }
        if (!e.getNode().get(DNPT.MergeCorpusLeft)) {
          e.getNode().set(DNPT.MergeCorpusLeft, true);
          bake = true;
        }
        if (bake) e.getNode().bake();
      } else {
        let bake = false;
        if (!e.getNode().get(DNPT.MergeCorpusRight)) {
          e.getNode().set(DNPT.MergeCorpusRight, true);
          bake = true;
        }
        if (!e.getNode().get(DNPT.MergeCorpusLeft)) {
          e.getNode().set(DNPT.MergeCorpusLeft, true);
          bake = true;
        }
        if (bake) e.getNode().bake();
      }

      newX += width;
    }
  }

  public compatibleWithEquipment(value: Subtype) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (!item.compatibleWithEquipment(value)) return false;
    }
    return true;
  }

  public numberOfFunctionItem() {
    let c = 0;
    for (let i = this.getItems().length - 1; i >= 0; i--) {
      const item = this.getItems()[i];
      if (Configuration.isFunctionItem(item)) c++;
    }
    return c;
  }

  public getUniqueId() {
    return this._uniqueId;
  }

  public getWidth() {
    let w = 0;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      w += item.getWidth();
    }
    return w;
  }

  public getWidthSubstructure() {
    let w = 0;
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      w += item.getWidthSubstructure();
    }
    return w;
  }

  public getItems() {
    return this.items;
  }

  public getEquipments() {
    return this.equipments;
  }

  public onDepthChange() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      item.onDepthChange();
    }
  }

  public compatibleWithDepth(depth: number) {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (!item.compatibleWithDepth(depth)) return false;
    }
    return true;
  }

  public getNode() {
    return this._node;
  }

  public getParent() {
    return this._parent;
  }

  public setParent(parent: BlockRow) {
    this._parent = parent;
  }

  public getBlockRow() {
    return this._parent;
  }

  public getBlock() {
    return this._parent.getParent();
  }

  public prev(): BlockItem {
    const isBottom = this._parent.getType() === 'Bottom';
    for (let i = 0; i < this._parent.getItems().length; i++) {
      const item = this._parent.getItems()[i];
      if (this.getUniqueId() === item.getUniqueId()) {
        if (isBottom ? i > 0 : i <= this._parent.getItems().length - 2) {
          const prev = this._parent.getItems()[isBottom ? i - 1 : i + 1];
          if (prev instanceof BlockItem) return prev;
          else return prev.getItems()[prev.getItems().length - 1];
        } else return null;
      }
    }
    return null;
  }

  public next(): BlockItem {
    const isBottom = this._parent.getType() === 'Bottom';
    for (let i = 0; i < this._parent.getItems().length; i++) {
      const item = this._parent.getItems()[i];
      if (this.getUniqueId() === item.getUniqueId()) {
        if (isBottom ? i <= this._parent.getItems().length - 2 : i > 0) {
          const next = this._parent.getItems()[isBottom ? i + 1 : i - 1];
          if (next instanceof BlockItem) return next;
          else return next.getItems()[0];
        } else return null;
      }
    }
    return null;
  }

  public setError(value: boolean) {
    this.items.forEach(i => i.setError(value));
  }

  /**
   * Deletes this Group and all children
   */
  public delete() {
    this._parent.removeItem(this);
    this._node.dispose();
    this.getBlock().checkFullBlendColor();
  }
}
