// Components
import { Button, Icon, Selectbox } from 'atoms';
import { BlockSelector, NumericInput } from 'components';
// Context
import { EditorContext, LangContext } from 'context';
// Language
import Localizations from 'languages';
import Block from 'page/Editor/configuration/Block';
import Vector3 from 'page/Editor/configuration/Vector3';
import { useInstallationWall } from 'page/Editor/Menu/Menus/Modular/Partials/Structure/hooks';
import { ContextMenu } from 'page/Editor/Menu/Shared';
import { FC, useContext, useEffect, useReducer, useState } from 'react';
// Types
import { Device, ModularNOLMenu } from 'types';

const SINGLE_SERIES = ['700', '850'];
const DOUBLE_SERIES = ['700 / 700', '700 / 850', '850 / 700', '850 / 850'];

type Series = '700' | '850' | '740' | '890' | '700 / 700' | '700 / 850' | '850 / 700' | '850 / 850';

type ModularNOLType = {
  singleWall: string;
  double: string;
};
interface Props {
  setView: (view: ModularNOLMenu) => void;
}

interface Action {
  type: 'rowChange' | 'depthChange' | 'addBlock' | 'coverEnlargementChange' | 'changeSelectedBlock';
  index?: number;
  payload?: boolean | Series | string | Block | Device;
}

export const ModularNOLStructure: FC<Props> = ({ setView }) => {
  const { configuration, setCanAdvanceModularNOL, setErrorAlert, selectedModularNOL, setSelectedModularNOL, setSelection, setSeriesFilter, scene } =
    useContext(EditorContext);

  const { lang, langId } = useContext(LangContext);

  const ROWS: ModularNOLType = {
    singleWall: `${Localizations['singleRow'][lang]} ${Localizations['wallStanding'][lang]}`,
    double: Localizations['doubleRow'][lang]
  };

  const [selectedRow, setSelectedRow] = useState<string>('');
  const [selectedSeries, setSelectedSeries] = useState<string>('');

  // Reducer
  const initialModularNOL: Block = selectedModularNOL ? selectedModularNOL : configuration.getModularNOL()[0];
  const [initialized, setInitialized] = useState(false);

  const {
    selectedInstallationWall,
    setSelectedInstallationWall,
    selectedInstallationWallDepth,
    setSelectedInstallationWallDepth,
    installationWallDevices,
    installationWallLoading,
    canEditHeight,
    selectedInstallationWallHeight,
    setSelectedInstallationWallHeight
  } = useInstallationWall(initialized);

  const modularNOLReducer = (state: Block, action: Action) => {
    switch (action.type) {
      case 'rowChange':
        if (action.payload === ROWS.singleWall) {
          if (state.getType() === 'Double') {
            state.setType('Single');
          }
          state.setSingleType('Wall');
        } else if (action.payload === ROWS.double) {
          if (!(state.getType() === 'Double')) {
            state.setType('Double');
            //setSeriesFilter('all');
          }
        }
        return state;
      case 'depthChange':
        const topRow = state.getRowTop();
        const bottomRow = state.getRowBottom();
        if (state.getType() === 'Double') {
          if (action.payload === '700 / 700') {
            topRow.setDepth(700);
            bottomRow.setDepth(700);
            setSeriesFilter('700');
          } else if (action.payload === '850 / 700') {
            topRow.setDepth(850);
            bottomRow.setDepth(700);
            setSeriesFilter('all');
          } else if (action.payload === '700 / 850') {
            topRow.setDepth(700);
            bottomRow.setDepth(850);
            setSeriesFilter('all');
          } else if (action.payload === '850 / 850') {
            topRow.setDepth(850);
            bottomRow.setDepth(850);
            setSeriesFilter('850');
          }
        } else {
          if (action.payload === '700' || action.payload === '740') {
            bottomRow.setDepth(700);
            setSeriesFilter('700');
          } else {
            bottomRow.setDepth(850);
            setSeriesFilter('850');
          }
        }
        return state;
      case 'coverEnlargementChange':
        if (typeof action.payload === 'string') {
          state.setDepthExtension(parseInt(action.payload));
        }
        return state;
      case 'addBlock':
        //setInstallationWall({ wall: null, depth: 100 });
        if (action.payload instanceof Block) {
          return action.payload;
        }
        return state;
      case 'changeSelectedBlock':
        if (action.payload instanceof Block) {
          return action.payload;
        }
        return state;
      default:
        return state;
    }
  };

  const [modularNOLBlock, dispatch] = useReducer(modularNOLReducer, initialModularNOL);

  useEffect(() => {
    if (scene) {
      scene.setBlockMode(true);
    }
    return () => {
      if (scene) {
        scene.setBlockMode(false);
      }

      setInitialized(false);
    };
  }, [scene]);

  useEffect(() => {
    if (!initialized && selectedModularNOL) {
      const initialRow: string = selectedModularNOL?.getType() === 'Double' ? ROWS.double : ROWS.singleWall;
      let initialSeries: string = '';

      const rowTop = selectedModularNOL?.getRowTop();
      const rowBottom = selectedModularNOL?.getRowBottom();
      if (selectedModularNOL.getType() === 'Double') {
        const topDepth = rowTop.getDepth();
        const bottomDepth = rowBottom.getDepth();
        if (topDepth == 850 && bottomDepth === 850) {
          initialSeries = '850 / 850';
        } else if (topDepth === 700 && bottomDepth === 850) {
          initialSeries = '700 / 850';
        } else if (topDepth === 850 && bottomDepth === 850) {
          initialSeries = '850 / 700';
        } else if (topDepth === 700 && bottomDepth === 700) {
          initialSeries = '700 / 700';
        }
      } else {
        if (rowBottom.getDepth() === 700) {
          initialSeries = '700';
        } else {
          initialSeries = '850';
        }
      }

      // DONE:
      setSelectedRow(initialRow);
      setSelectedSeries(initialSeries);
      setInitialized(true);
      dispatch({ type: 'changeSelectedBlock', payload: selectedModularNOL });
    }
  }, [lang, initialized, selectedModularNOL]);

  useEffect(() => {
    setInitialized(false);
  }, [selectedModularNOL]);

  return (
    <div className="flex-col" style={{ maxHeight: '100%', overflow: 'hidden' }}>
      <ContextMenu
        mode="back"
        onClick={() => {
          setView('home');
          if (scene) {
            scene.setSelected(null);
          }
          setSelection(null);
        }}
      />
      {selectedModularNOL && <BlockSelector />}

      <div className="mt-2" style={{ flex: 1, overflowY: 'auto' }}>
        {/* ROWS ----> SINGLE WALL / SINGLE FREE / DOUBLE */}
        {modularNOLBlock && (
          <Selectbox
            label={Localizations['blockStructure'][lang]}
            options={Object.values(ROWS)}
            state={selectedRow}
            setState={(value: string) => {
              setSelectedRow(value);
              dispatch({ type: 'rowChange', payload: value });
              if (value === ROWS.singleWall) {
                setSelectedSeries(selectedSeries === '740' ? '700' : '850');
              } else if (value === ROWS.double) {
                setSelectedSeries(selectedSeries === '700' || selectedSeries === '740' ? '700 / 700' : '700 / 850');
              }
            }}
          />
        )}

        {/* SINGLE DEPTH OPTIONS */}
        {modularNOLBlock && selectedRow === ROWS.singleWall && (
          <div className="mb-1">
            <Selectbox
              label={Localizations['rowDepth'][lang]}
              state={selectedSeries}
              setState={value => {
                setSelectedSeries(value);
                dispatch({ type: 'depthChange', payload: value });
              }}
              options={SINGLE_SERIES}
            />
          </div>
        )}
        {/* DOUBLE DEPTH OPTIONS */}
        {modularNOLBlock && selectedRow === ROWS.double && (
          <div className="mb-1">
            <Selectbox
              label={Localizations['rowDepth'][lang]}
              state={selectedSeries}
              setState={value => {
                setSelectedSeries(value);
                dispatch({ type: 'depthChange', payload: value });
              }}
              options={DOUBLE_SERIES}
            />
          </div>
        )}

        {modularNOLBlock && (
          /* installationWall && */ <>
            <div>
              <Selectbox
                label={Localizations['installationWall'][lang]}
                disabled={installationWallLoading}
                options={[Localizations['none'][lang], ...installationWallDevices.map(w => w?.translations[langId]?.name)]}
                state={selectedInstallationWall}
                setState={(value: string) => {
                  //setInstallationWall({ ...installationWall, wall: installationWallDevices.find(w => w?.translations[langId]?.name === value) });
                  setSelectedInstallationWall(value);
                  //dispatch({ type: 'addInstallationWall', payload: value });
                }}
              />
            </div>
            {selectedInstallationWall && selectedInstallationWall !== Localizations['none'][lang] && (
              <>
                <div className="mt-1">
                  <Selectbox
                    label={Localizations['installationWallDepth'][lang]}
                    disabled={installationWallLoading || selectedInstallationWall === Localizations['none'][lang]}
                    options={installationWallDevices?.find(wall => wall?.translations[langId]?.name === selectedInstallationWall)?.model?.depths || []}
                    state={selectedInstallationWallDepth}
                    setState={(value: string) => {
                      setSelectedInstallationWallDepth(parseInt(value));
                    }}
                  />
                </div>
                {canEditHeight && (
                  <>
                    {/* <Selectbox
                      label={Localizations['installationWallHeight'][lang]}
                      disabled={installationWallLoading || selectedInstallationWall === Localizations['none'][lang]}
                      options={}
                      state={selectedInstallationWallHeight}
                      setState={(value: string) => {
                        //setSelectedInstallationWallHeight(parseInt(value));
                      }}
                    /> */}
                    <div className="Submenu-Attribute mt-2">
                      <label className="Submenu-Label">
                        <NumericInput
                          className="Submenu-Input"
                          min={790}
                          max={1250}
                          onChange={value => setSelectedInstallationWallHeight(value)}
                          step={10}
                          validateOnBlur={true}
                          value={selectedInstallationWallHeight}
                        />
                        {Localizations['installationWallHeight'][lang]}
                      </label>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {modularNOLBlock && (
          <div className="mt-2" style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Icon
              type="rotate"
              size="1.5rem"
              style={{ margin: '0 1rem' }}
              onClick={() => {
                modularNOLBlock.setRotation(new Vector3(0, selectedModularNOL.getRotation().y + Math.PI / 2, 0));
              }}
            />
            <Icon
              color="medium"
              onClick={() => {
                setErrorAlert(['delete']);
              }}
              size="1.5rem"
              type="trash"
              stroke={3}
            />
          </div>
        )}
      </div>
      <div className="mt-1">
        <Button
          btnType="third"
          fontSize=".65rem"
          onClick={() => {
            const newBlock = new Block({ blockType: 'ModularNOL' });
            newBlock.setId(`ModularNOLBlock-${configuration.getModularNOL() ? configuration.getModularNOL().length + 1 : 1}`);
            setSelectedRow(ROWS.singleWall);
            setSelectedSeries('850');

            if (configuration.isShowLabels()) {
              newBlock.setShowLabels(true);
            }

            dispatch({ type: 'addBlock', payload: newBlock });

            const modularNOLBlocks = configuration.getModularNOL();
            configuration.getModularNOL().push(newBlock);
            if (Array.isArray(modularNOLBlocks) && modularNOLBlocks.length) {
              setSelectedModularNOL(configuration.getModularNOL()[modularNOLBlocks.length - 1]);
              setCanAdvanceModularNOL(true);
            }
          }}
          padding=".5rem"
        >
          {Localizations['addModular'][lang]}
        </Button>
      </div>
    </div>
  );
};
