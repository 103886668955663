import React, { FC, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';

// Context:
import { EditorContext, LangContext } from 'context';

// Components:
import { ASimple, ADialog, Button, Heading, Icon, Input } from 'atoms';
import { LoadingSpinner } from 'components';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';

// Utility:
import { checkForUrl, post } from 'utils';

// ===================================================================
interface Props {
  open: boolean;
  close: any;
}

// ===================================================================
export const SaveDialog: FC<Props> = ({ open, close }) => {
  const { lang } = useContext(LangContext);
  const { configuration, setSavingError, savedConfiguration } = useContext(EditorContext);
  const history = useHistory();

  const [customer, setCustomer] = useState('');
  const [error, setError] = useState(false);
  const [location, setLocation] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [project, setProject] = useState('');

  useEffect(() => {
    if (savedConfiguration) {
      setCustomer(savedConfiguration.customer);
      setLocation(savedConfiguration.location);
      setProject(savedConfiguration.name);
    }
  }, [savedConfiguration]);

  /* Methods */
  const saveConfigAs = async () => {
    if (!checkForUrl(customer) && !checkForUrl(project)) {
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/configuration/save`, {
        data: {
          customer,
          location,
          name: project,
          public: savedConfiguration.public,
          json: configuration ? configuration.exportJSON() : ''
        }
      });
      if (data) {
        history.push(`/editor?id=${data.id}`);
        close();
      }
      if (error) {
        setSavingError(true);
        setError(true);
      }
      setLoading(false);
    }
  };

  /* Render */
  return (
    <Portal>
      {/* Background */}
      {open && <ASimple key="SaveDialog-Background" className="SaveDialog-Background" style={{ height: window.innerHeight }} onClick={close} />}

      {/* Content */}
      {open && (
        <ADialog key="SaveDialog-Content" className="SaveDialog-Content" topExit="0" topEnter="50%">
          {/* Close Icon */}
          <div className="SaveDialog-Content-Icon">
            <Icon type="close" size="1.25rem" color="grey" onClick={close} />
          </div>

          {/* Main */}
          <div className="SaveDialog-Content-Main">
            <Heading level="1" color="dark">
              {Localizations['saveAs'][lang]}
            </Heading>

            <form
              action=""
              onSubmit={e => {
                e.preventDefault();
                saveConfigAs();
              }}
            >
              <Input
                className="LoginPage-InputField"
                color="darkBlue"
                fontWeight={400}
                margin="2rem 0 2rem"
                onChange={event => {
                  //@ts-ignore
                  setCustomer(event.target.value);
                }}
                placeholder={'Customer'}
                placeholderSize="large"
                textAlign="center"
                value={customer}
              />
              <Input
                className="LoginPage-InputField"
                color="darkBlue"
                fontWeight={400}
                margin="2rem 0 2rem"
                onChange={event => {
                  //@ts-ignore
                  setLocation(event.target.value);
                }}
                placeholder={'Location'}
                placeholderSize="large"
                textAlign="center"
                value={location}
              />
              <Input
                className="LoginPage-InputField"
                color="darkBlue"
                fontWeight={400}
                margin="0 0 2.5rem 0"
                //@ts-ignore
                onChange={event => setProject(event.target.value)}
                placeholder={'Project'}
                placeholderSize="large"
                textAlign="center"
                value={project}
              />
              {error && <p>Error saving config</p>}
              <Button btnType="third" fontSize="0.65rem" maxWidth={500} type="submit" padding="1rem">
                {loading ? <LoadingSpinner size="1rem" /> : Localizations['save'][lang]}
              </Button>
            </form>
          </div>
        </ADialog>
      )}
    </Portal>
  );
};
