import { useState } from 'react';
import { post } from 'utils';

export const useSendUserPassword = () => {
  const [loading, setLoading] = useState(false);
  const handleSendPasswordEmail = async (id: string) => {
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/organization/password/send`, {
      data: {
        id
      }
    });

    if (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return {
    handleSendPasswordEmail,
    sendingEmail: loading
  };
};
