import React, { FC, useContext } from 'react';

// Context
import { EditorContext, LangContext } from 'context';

// Language
import Localizations from 'languages';

// Types
import { ModularNOLMenu } from 'types';

interface Props {
  setView: (view: ModularNOLMenu) => void;
}

export const ModularNOLHome: FC<Props> = ({ setView }) => {
  const { canAdvanceModularNOL, configuration } = useContext(EditorContext);
  const { lang } = useContext(LangContext);

  return (
    <div className="flex-col justify-between h-100">
      <div style={{ paddingTop: '3rem' }}>
        <div className="Submenu-Category" onClick={() => setView('structure')}>
          {`01 ${Localizations['structure'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${
            !canAdvanceModularNOL && (!configuration || (configuration && configuration.getModularNOL() && !(configuration.getModularNOL().length > 0)))
              ? '-Disabled'
              : ''
          }`}
          onClick={() => {
            if (canAdvanceModularNOL) {
              setView('functional');
            }
          }}
        >
          {`02 ${Localizations['functionalDevices'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${canAdvanceModularNOL ? '' : '-Disabled'}`}
          onClick={() => {
            if (canAdvanceModularNOL) {
              setView('upperStructures');
            }
          }}
        >
          {`03 ${Localizations['upperStructures'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${canAdvanceModularNOL ? '' : '-Disabled'}`}
          onClick={() => {
            if (canAdvanceModularNOL) {
              setView('substructures');
            }
          }}
        >
          {`04 ${Localizations['substructureOptions'][lang]}`}
        </div>
        <div
          className={`Submenu-Category${canAdvanceModularNOL ? '' : '-Disabled'}`}
          onClick={() => {
            if (canAdvanceModularNOL) {
              setView('global');
            }
          }}
        >
          {`05 ${Localizations['globalSettings'][lang]}`}
        </div>
      </div>
    </div>
  );
};
