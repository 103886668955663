import { EquipmentHelper } from 'page/Editor/configuration/Equipment';
import BlockItem from '../page/Editor/configuration/BlockItem';
import BlockGroup from '../page/Editor/configuration/BlockGroup';

export const canHaveSubstructures = (block: BlockItem | BlockGroup) => {
  if (EquipmentHelper.canHaveAngularRail(block)) return true;
  if (EquipmentHelper.canHaveBaseCover(block)) return true;
  if (EquipmentHelper.canHaveBlindCover(block)) return true;
  if (EquipmentHelper.canHaveColdWaterInlet(block)) return true;
  if (EquipmentHelper.canHaveCooling(block)) return true;
  if (EquipmentHelper.canHaveEOven(block)) return true;
  if (EquipmentHelper.canHaveEmptyingSystem(block)) return true;
  if (EquipmentHelper.canHaveGOven(block)) return true;
  if (EquipmentHelper.canHaveHeatingCabinet(block)) return true;
  if (EquipmentHelper.canHaveHeatingDrawer(block)) return true;
  if (EquipmentHelper.canHaveHygiene(block)) return true;
  if (EquipmentHelper.canHaveLowerDrawer(block)) return true;
  if (EquipmentHelper.canHaveMixingFaucet(block)) return true;
  if (EquipmentHelper.canHaveOpenSubstructure(block)) return true;
  if (EquipmentHelper.canHaveUpperDrawer(block)) return true;
  if (EquipmentHelper.canHaveUpperPowerSocket(block)) return true;
  if (EquipmentHelper.canHaveWasteDrawer(block)) return true;
  if (EquipmentHelper.canHaveWingedDoor(block)) return true;
  else return false;
};
