import { useEffect, useState } from 'react';
import FreeItem from '../../../../configuration/FreeItem';
import Vector3 from '../../../../configuration/Vector3';
import Dimensions from '../../../../../../utils/Dimensions';

export const useYEnhancement = (item: FreeItem | null) => {
  const [yEnhancement, setYEnhancement] = useState(item ? Dimensions.MM(item.getPosition().y) : 0);
  const [currentId, setCurrentId] = useState<string>(item ? item.getUniqueId() : null);

  useEffect(() => {
    if (currentId && currentId === item?.getUniqueId()) {
      const { x, y, z } = item.getPosition();

      if (y !== yEnhancement) {
        item.setPosition(new Vector3(x, Dimensions.CM(yEnhancement), z));
      }
    } else if (item) {
      if (yEnhancement !== item?.getPosition().y) {
        setYEnhancement(Dimensions.MM(item.getPosition().y));
      }
      if (currentId !== item?.getUniqueId()) {
        setCurrentId(item.getUniqueId());
      }
    }
  }, [yEnhancement, item, currentId]);

  return {
    yEnhancement,
    setYEnhancement
  };
};
