import { useEffect } from 'react';
import { Device } from 'types';
import { isSubtype } from 'utils';

type Args = {
  availableDevices: Device[];
  setOvens: React.Dispatch<React.SetStateAction<Device[]>>;
};

export const useUpdateAvailableOvens = ({ availableDevices, setOvens }: Args) => {
  useEffect(() => {
    const availableOvens = availableDevices.filter((aD: Device) => isSubtype(aD.subtype, ['EOven', 'GOven']));
    setOvens(availableOvens);
  }, [availableDevices]);
};
