import { EditorContext } from 'context';
import { useContext, useEffect, useState } from 'react';

export const useUpturn = () => {
  const { selectedMarineMeister } = useContext(EditorContext);
  const [upturn, setUpturn] = useState<number>(100);
  const [upturnL, setUpturnL] = useState<number>(100);
  const [upturnR, setUpturnR] = useState<number>(100);

  const handleUpturnInput = (newUpturn: number, side?: 'L' | 'R') => {
    if (selectedMarineMeister) {
      if (side === 'L') {
        selectedMarineMeister.setUpturnL(newUpturn);
        setUpturnL(selectedMarineMeister.getUpturnL());
      } else if (side === 'R') {
        selectedMarineMeister.setUpturnR(newUpturn);
        setUpturnR(selectedMarineMeister.getUpturnR());
      } else {
        selectedMarineMeister.setUpturn(newUpturn);
        setUpturn(selectedMarineMeister.getUpturn());
      }
    }
  };

  useEffect(() => {
    const initializeValues = () => {
      if (selectedMarineMeister) {
        setUpturn(selectedMarineMeister.getUpturn());
        setUpturnL(selectedMarineMeister.getUpturnL());
        setUpturnR(selectedMarineMeister.getUpturnR());
      }
    };
    initializeValues();
  }, [selectedMarineMeister]);

  return {
    handleUpturnInput,
    upturn,
    upturnL,
    upturnR
  };
};
