import React, { FC, useContext } from 'react';
import { Portal } from 'react-portal';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Components:
import { AMenu, AItem as AMenuItem, Icon, Logo } from 'atoms';
import { ProfileDialog } from 'components';

// Context:
import { AuthContext, EditorContext, LangContext, useUser } from 'context';

// Languages:
import Localizations from 'languages';

// Styles:
import './style.scss';

// ===================================================================
interface Props extends RouteComponentProps {
  open: boolean;
  close: any;
}

// ===================================================================
const Navigation: FC<Props> = ({ open, close, history }) => {
  const { handleLog, userRights } = useContext(AuthContext);
  const { isSuperuser } = useUser();
  const { setProfileOpen } = useContext(EditorContext);
  const { lang, setLang } = useContext(LangContext);

  const isAdmin = userRights?.includes('Admin');

  return (
    <Portal>
      {open && (
        <AMenu>
          <>
            <div className="Header">
              <div className="Header-Navigation">
                <div className="Header-Navigation-EdgeItem">
                  <Icon type="close" size="1.5rem" color="medium" onClick={close} />
                </div>

                <div className="Header-Navigation-EdgeItem">
                  <Logo />
                </div>
              </div>
            </div>

            <div className="Navigation-Content">
              <AMenuItem
                animationOrder={1}
                className="Navigation-Item"
                onClick={() => {
                  history.push('/');
                  close();
                }}
              >
                {Localizations['configurations'][lang]}
              </AMenuItem>
              {isAdmin && (
                <AMenuItem
                  animationOrder={2}
                  className="Navigation-Item"
                  onClick={() => {
                    history.push('/archive');
                    close();
                  }}
                >
                  {Localizations['archive'][lang]}
                </AMenuItem>
              )}
              <AMenuItem className="Navigation-Separation" />
              {isSuperuser && (
                <AMenuItem
                  animationOrder={isAdmin ? 3 : 2}
                  className="Navigation-Item"
                  onClick={() => {
                    history.push('/organization');
                    close();
                  }}
                >
                  {Localizations['userManagement'][lang]}
                </AMenuItem>
              )}
              <AMenuItem animationOrder={isAdmin ? 3 : 2} className="Navigation-Item" onClick={() => setProfileOpen(true)}>
                {Localizations['profile'][lang]}
              </AMenuItem>
              <AMenuItem animationOrder={isAdmin ? 4 : 3} className="Navigation-Item" onClick={() => handleLog(false)}>
                {Localizations['logout'][lang]}
              </AMenuItem>
              <AMenuItem className="Navigation-Separation-NoLine" />
              <AMenuItem className="Navigation-Item-small">
                <Icon type="world" size="1rem" color="white" />
              </AMenuItem>
              <div className="flex">
                <AMenuItem animationOrder={isAdmin ? 5 : 4} className="Navigation-Item-small mr-1" onClick={() => setLang('de')}>
                  <div>DE</div>
                </AMenuItem>
                <AMenuItem animationOrder={isAdmin ? 5 : 4} className="Navigation-Item-small ml-1" onClick={() => setLang('en')}>
                  <div>EN</div>
                </AMenuItem>
                <AMenuItem animationOrder={isAdmin ? 5 : 4} className="Navigation-Item-small ml-1" onClick={() => setLang('cz')}>
                  <div>CZ</div>
                </AMenuItem>
                <AMenuItem animationOrder={isAdmin ? 5 : 4} className="Navigation-Item-small ml-1" onClick={() => setLang('fr')}>
                  <div>FR</div>
                </AMenuItem>
              </div>

              <AMenuItem className="Navigation-Separation-NoLine" />
              <AMenuItem
                animationOrder={isAdmin ? 6 : 5}
                className="Navigation-Item-small"
                onClick={() => {
                  var win = window.open('https://www.mkn.com/datenschutzerklaerung', '_blank');
                  win.focus();
                }}
                color="white-transparent"
              >
                <strong>{Localizations['datasecurity'][lang]}</strong>
              </AMenuItem>
              <AMenuItem
                animationOrder={isAdmin ? 7 : 6}
                className="Navigation-Item-small"
                onClick={() => {
                  history.push('/imprint');
                  close();
                }}
                color="white-transparent"
              >
                <strong>{Localizations['imprint'][lang]}</strong>
              </AMenuItem>
              <AMenuItem
                animationOrder={isAdmin ? 8 : 7}
                className="Navigation-Item-small"
                onClick={() => {
                  history.push('/agb');
                  close();
                }}
                color="white-transparent"
              >
                <strong>{Localizations['agb'][lang]}</strong>
              </AMenuItem>
            </div>
          </>
        </AMenu>
      )}
      <ProfileDialog />
    </Portal>
  );
};

export default withRouter(Navigation);
