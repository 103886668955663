import Block from 'page/Editor/configuration/Block';

export const updateBlockIds = (blocks: Block[], type: 'masterline' | 'modular' | 'modularNOL' | 'marineMeister') => {
  const prefix =
    type === 'masterline'
      ? 'MasterlineBlock-'
      : type === 'marineMeister'
      ? 'MarineMeisterBlock-'
      : type === 'modularNOL'
      ? 'ModularNOLBlock-'
      : 'ModularBlock-';
  blocks.forEach((b, i) => {
    b.setId(prefix + i);
  });
};
