import React, { useEffect, useState } from 'react';
import './style.scss';

export const AMenu: React.FC = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  const [className, setClassName] = useState('AMenu');

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      setClassName('AMenu-Mounted');
    }
  }, [mounted]);
  return <div className={'Navigation ' + className}>{children}</div>;
};
