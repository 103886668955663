import React, { FC } from 'react';

// Atoms
import { Switch } from 'atoms';

// Style
import { colors } from 'styles/theme';

interface Props {
  description?: string;
  disabled?: boolean;
  noBorder?: boolean;
  label?: string;
  status: boolean;
  toggleStatus: () => void;
  type?: 'first' | 'hollow';
}

export const EquipmentSwitch: FC<Props> = ({ description, label = '', disabled = false, noBorder = false, status, toggleStatus, type }) => {
  if (description) {
    return (
      <div>
        <div
          className="flex mt-2 pb-2"
          style={{
            borderBottom: noBorder ? '' : '1px solid ' + colors['light']
          }}
        >
          <div>
            <Switch disabled={disabled} shadow status={status} toggleStatus={toggleStatus} type={type} />
          </div>
          <div className="ml-2" style={{ marginTop: '-5px' }}>
            <span className="text-sm bold" style={{ color: disabled ? colors.disabledGrey : colors.medium }}>
              {label}
            </span>
            <br />
            <span className="text-xs" style={{ color: disabled ? colors.disabledGrey : colors.medium }}>
              {description}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex mt-2 pb-2"
      style={{
        borderBottom: noBorder ? '' : '1px solid ' + colors['light']
      }}
    >
      <div>
        <Switch disabled={disabled} shadow status={status} toggleStatus={toggleStatus} type={type} />
      </div>
      <span className="text-sm bold ml-2" style={{ marginTop: '-3px', color: disabled ? colors.disabledGrey : colors.medium }}>
        {label}
      </span>
    </div>
  );
};
