import { useState } from 'react';
import { Member } from 'types';
import { post } from 'utils';

export const useEditSubuser = () => {
  const [loading, setLoading] = useState(false);
  const handleSaveUser = async ({ active, email, id, supervisor, rights }: Member) => {
    setLoading(true);
    const { data, error } = await post(`${process.env.REACT_APP_API_URL}/organization/save/member`, {
      data: {
        active,
        email,
        id,
        rights,
        supervisor
      }
    });
    if (data) {
      console.log('updated User: ', data);
    }
    if (error) {
      console.log('Error updating user - ', error);
    }
    setLoading(false);
    return { data, error };
  };
  return {
    handleSaveUser,
    loading
  };
};
