import React, { useContext } from 'react';
import { Right } from 'types/Member';

export type UserOrganization = {
  id: string;
  discountFlexiChef: number;
  discountMasterline: number;
  discountModular: number;
  discountSpaceCombi: number;
  supervisor: boolean;
  name: string;
};

type AuthContextType = {
  status: boolean;
  handleLog: React.Dispatch<React.SetStateAction<boolean>>;
  organization: UserOrganization | null;
  setOrganization: React.Dispatch<React.SetStateAction<UserOrganization>>;
  userRights: Right[];
  setUserRights: React.Dispatch<React.SetStateAction<Right[]>>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  supervisor: boolean;
  setSupervisor: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = React.createContext<AuthContextType>({
  status: false,
  handleLog: (status: boolean) => {},
  organization: null,
  setOrganization: (o: UserOrganization) => {},
  userRights: [],
  setUserRights: (rights: Right[]) => {},
  userEmail: '',
  setUserEmail: (email: string) => {},
  supervisor: false,
  setSupervisor: (supervisor: boolean) => {}
});

export const useUser = () => {
  const { organization, supervisor, userRights } = useContext(AuthContext);
  const hasSinglePriceAccess =
    Array.isArray(userRights) &&
    (userRights?.includes('ShowSinglePricesFlexi') ||
      userRights?.includes('ShowSinglePricesSpace') ||
      userRights?.includes('ShowSinglePricesMasterline') ||
      userRights?.includes('ShowSinglePricesModular') ||
      userRights?.includes('ShowSinglePricesMarineMeister'));

  const hasSomeTotalPriceAccess =
    Array.isArray(userRights) &&
    (userRights?.includes('ShowTotalPriceMasterline') || userRights?.includes('ShowTotalPriceModular') || userRights?.includes('ShowTotalPriceMarineMeister'));

  return {
    hasPriceAccess: Array.isArray(userRights) && (userRights.includes('Admin') || ((hasSinglePriceAccess || hasSomeTotalPriceAccess) && !organization)),
    hasSurchargeMasterlineAccess:
      Array.isArray(userRights) && ((organization && userRights?.includes('ShowTotalPriceMasterline')) || userRights.includes('Admin')),
    hasFlexiAccess: Array.isArray(userRights) && (userRights?.includes('AccessFlexi') || userRights.includes('Admin')),
    hasSpaceAccess: Array.isArray(userRights) && (userRights?.includes('AccessSpace') || userRights.includes('Admin')),
    hasRoomAccess: Array.isArray(userRights) && (userRights?.includes('AccessRoom') || userRights.includes('Admin')),
    hasModularAccess: Array.isArray(userRights) && (userRights?.includes('AccessModular') || userRights.includes('Admin')),
    hasMasterlineAccess: Array.isArray(userRights) && (userRights?.includes('AccessMasterline') || userRights.includes('Admin')),
    hasMarineMeisterAccess: Array.isArray(userRights) && (userRights?.includes('AccessMarineMeister') || userRights.includes('Admin')),
    hasFlexiSinglePriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowSinglePricesFlexi') || userRights.includes('Admin')),
    hasSpaceSinglePriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowSinglePricesSpace') || userRights.includes('Admin')),
    hasMasterlineSinglePriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowSinglePricesMasterline') || userRights.includes('Admin')),
    hasModularSinglePriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowSinglePricesModular') || userRights.includes('Admin')),
    hasMarineMeisterSinglePriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowSinglePricesMarineMeister') || userRights.includes('Admin')),
    hasMasterlineTotalPriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowTotalPriceMasterline') || userRights.includes('Admin')),
    hasModularTotalPriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowTotalPriceModular') || userRights.includes('Admin')),
    hasMarineMeisterTotalPriceAccess: Array.isArray(userRights) && (userRights?.includes('ShowTotalPriceMarineMeister') || userRights.includes('Admin')),
    isSuperuser: supervisor,
    hasSinglePriceAccess,
    organization
  };
};
